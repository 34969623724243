import { t } from "i18next";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { useSnackBarHook } from "@key4-front-library/core";
import { AutoCompleteParticipantOrContact, ButtonCancel, ButtonSave, DialogOld } from "@key4-front-library/core/Bo/Components";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";

import type { TypeContact, TypeParticipant } from "@key4-front-library/core/Types";
type FormValues = {
	convenedPerson?: TypeParticipant | TypeContact;
};

type PropsContainerConvocationModal = {
	isOpen: boolean;
	handleModaleClose: () => void;
	onSubmit: (data?: TypeParticipant | TypeContact) => void;
};

const ContainerConvocationModal = (props: PropsContainerConvocationModal) => {
	const { isOpen, handleModaleClose, onSubmit } = props;

	const { sendError } = useSnackBarHook();

	const [currentConvenedPerson, setCurrentConvenedPerson] = useState<TypeParticipant | TypeContact | undefined>(undefined);

	const translationNameSpace = "old.programme.sessionDetails";
	const translationBaseKey: string = [translationNameSpace, ["convocation", "dialog"].join(".")].join(".");

	const methods = useForm<FormValues>({
		defaultValues: {
			convenedPerson: undefined,
		},
		mode: "onSubmit",
	});

	const { handleSubmit, control } = methods;

	const translations = {
		dialogTitle: t([translationBaseKey, "title"].join(".")),
		formInvalid: "form invalid",
	};

	const onInvalid = () => {
		sendError(translations.formInvalid);
	};

	const dialogClose = () => {
		handleModaleClose();
	};

	const handleAutoCompleteParticipantOrContactChange = (value: TypeParticipant | TypeContact) => {
		setCurrentConvenedPerson(value);
	};

	const handleDialogClose = () => {
		dialogClose();
	};

	const handleDialogButtonSaveClick = () => {
		handleSubmit(() => {
			onSubmit(currentConvenedPerson);
		}, onInvalid)().then(() => {
			dialogClose();
		});
	};

	const handleDialogButtonCancelClick = () => {
		dialogClose();
	};

	return (
		<DialogOld isOpen={isOpen}>
			<>
				<DialogTitle
					title={
						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<Stack>
								<Typography variant="h5">{translations.dialogTitle}</Typography>
							</Stack>
						</Stack>
					}
					onCloseClick={handleDialogClose}
				/>
				<DialogContent>
					<FormProvider {...methods}>
						<Controller
							control={control}
							name={"convenedPerson"}
							render={({ field, formState: { errors } }) => (
								<AutoCompleteParticipantOrContact
									field={field}
									areContactsActivated={true}
									onChange={handleAutoCompleteParticipantOrContactChange}
									error={errors.convenedPerson?.message}
								/>
							)}
						/>
					</FormProvider>
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel onClick={handleDialogButtonCancelClick} />
						<ButtonSave onClick={handleDialogButtonSaveClick} />
					</Stack>
				</DialogActions>
			</>
		</DialogOld>
	);
};

export default ContainerConvocationModal;
