import {
	useProgrammeMailSender,
	useProgrammeMailSenderCreate,
	useProgrammeMailSenderDelete,
	useProgrammeMailSenderUpdate,
	useProgrammeMailSenders,
} from "@api";
import {
	type MailSenderRead,
	type Paginated,
	PaperTitle,
	QUERY_KEYS_DOCUMENT,
	getButton,
	paginationToQueryString,
	useContextModule,
	usePagination,
	useToggle,
} from "@key4-front-library/core";
import { CreateMailSenderDialog } from "@key4-front-library/core/Components/Dialogs/CreateMailSenderDialog/CreateMailSenderDialog";
import { DataGridMailSender } from "@key4-front-library/core/Components/Organisms/DataGridMailSenders/DataGridMailSender";
import { Stack, Typography, useTheme } from "@mui/material";
import { type UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useState } from "react";

export const TabEmailing = (): React.ReactElement => {
	const component = "emailSettings.emailing";

	const canAddMailSender = true; // TODO - enable when permission is available
	const { client, event } = useContextModule();
	const queryClient = useQueryClient();
	const theme = useTheme();

	const [selectedMailSenderId, setSelectedMailSenderId] = useState<string>();
	const { isToggle: isToggleMailSenderModal, toggle: toggleMailSenderModal } = useToggle();
	const { page, pageSize } = usePagination();

	const mailSenderQuery: UseQueryResult<Paginated<MailSenderRead>> = useProgrammeMailSenders(client.id, event.id, {
		queryStrings: [
			...paginationToQueryString({
				page: page + 1,
				pageSize,
			}),
		],
		enabled: !!client.id && !!event.id,
	});

	const mailSenderByIdQuery = useProgrammeMailSender(client.id, event.id, selectedMailSenderId ?? "", { enabled: !!selectedMailSenderId });

	const handleMailSenderSubmit = (_: string | boolean): void => {
		void queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_DOCUMENT.documentMailSenders, "Get"],
		});
	};

	const mailSenderCreate = useProgrammeMailSenderCreate({
		onSuccess: handleMailSenderSubmit,
	});

	const mailSenderEditQuery = useProgrammeMailSenderUpdate({
		onSuccess: handleMailSenderSubmit,
	});

	const deleteMailSender = useProgrammeMailSenderDelete({
		onSuccess: handleMailSenderSubmit,
	});

	const handleSelectMailSender = (id?: string): void => {
		setSelectedMailSenderId(id);
	};

	return (
		<>
			{isToggleMailSenderModal && (
				<CreateMailSenderDialog
					createMailSenderMutation={mailSenderCreate}
					isOpened={isToggleMailSenderModal}
					clientId={client.id}
					operationId={event.id}
					onClose={toggleMailSenderModal}
				/>
			)}
			<PaperTitle
				title={{
					icon: {
						name: "envelope",
						variant: "h4",
						color: theme.palette.primary.light,
					},
					children: <Typography variant="h4">{t("eventSettings.emailing.title")}</Typography>,
					buttonGroup: {
						buttons: [
							canAddMailSender && {
								...getButton({ translation: component, type: "add" }),
								onClick: toggleMailSenderModal,
								disabled: !!mailSenderQuery.data?.data?.length,
							},
						],
					},
				}}
			>
				<Stack spacing={3}>
					<DataGridMailSender
						moduleMailSenders={mailSenderQuery}
						mailSenderByIdQuery={mailSenderByIdQuery}
						selectedMailSenderId={selectedMailSenderId}
						setSelectedMailSenderId={handleSelectMailSender}
						mailSenderEditQuery={mailSenderEditQuery}
						deleteMailSenderMutation={deleteMailSender}
					/>
				</Stack>
			</PaperTitle>
		</>
	);
};
