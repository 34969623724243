import { t } from "i18next";

import { K4Button } from "@infrastructure/components/interface/button/K4Button";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Stack } from "@mui/material";

type Props = {
	saveButtonClick: () => void;
	cancelButtonClick: () => void;
	disabledSaveButton: boolean;
};

const ValidationFormButtons = (props: Props & dataCypressProp) => {
	const { saveButtonClick, cancelButtonClick, disabledSaveButton, dataCypressID } = props;

	return (
		<Stack display={"flex"} flexDirection={"row-reverse"} mx={0} mt={3}>
			<K4Button
				label={t("old.form.buttons.save")}
				variant="contained"
				color="secondary"
				handleClick={saveButtonClick}
				disabled={disabledSaveButton}
				dataCypressID={dataCypressID ? `${dataCypressID}-save_button` : undefined}
			/>
			<K4Button
				label={t("old.form.buttons.cancel")}
				variant="outlined"
				handleClick={cancelButtonClick}
				dataCypressID={dataCypressID ? `${dataCypressID}-cancel_button` : undefined}
				sx={{ mr: 1 }}
			/>
		</Stack>
	);
};

export default ValidationFormButtons;
