import { t } from "i18next";

const GetTranslationsForClashes = () => {
	return {
		filters: {
			all: t(`old.programme.sessionDetails.tabs.clashes.filters.all`),
			sessions: t(`old.programme.sessionDetails.tabs.clashes.filters.sessions`),
			invitations: t(`old.programme.sessionDetails.tabs.clashes.filters.invitations`),
			isOnlyActive: t(`old.programme.sessionDetails.tabs.clashes.filters.isOnlyActive`),
		},
		buttons: {
			details: t(`old.form.buttons.details`),
		},
		sessionClashOnTheRoom: t(`old.programme.sessionDetails.tabs.clashes.sessionClashOnTheRoom`),
		invitationClashOnTheFaculty: t(`old.programme.sessionDetails.tabs.clashes.invitationClashOnTheFaculty`),
		comments: t(`old.form.placeholders.comments`),
		returnMessages: {
			success_comments: t(`old.programme.sessionDetails.tabs.clashes.returnMessages.success_comments`),
			activated_clash: t(`old.programme.sessionDetails.tabs.clashes.returnMessages.activated_clash`),
			desactivated_clash: t(`old.programme.sessionDetails.tabs.clashes.returnMessages.desactivated_clash`),
		},
	};
};

export default GetTranslationsForClashes;
