import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { Stack, Typography } from "@mui/material";

type BulletColorWithLabelProps = {
	iconColor: string;
	label: string;
};

const BulletColorWithLabel = (props: BulletColorWithLabelProps) => {
	const { iconColor, label } = props;
	return (
		<Stack direction={"row"} alignItems={"center"}>
			<Icon color={iconColor} prefix="fas" iconName="circle" size={"1x"} />
			<Typography ml={".5rem"} variant="body2">
				{label}
			</Typography>
		</Stack>
	);
};

export default BulletColorWithLabel;
