import { t } from "i18next";
import { cloneDeep, toPairs } from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import { usePresentationCustomFormQuery, usePresentations } from "@application/hooks";
import { useSession } from "@application/hooks/SessionHook";
import {
	ButtonCancel,
	ButtonSave,
	DialogOld,
	ESieveOperator,
	FormTabs,
	filtersToQueryString,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	useContextModule,
	useSessionTemplates,
	useSnackBarHook,
} from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import { Skeleton, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import type { BaseSyntheticEvent } from "react";
type PropsModalPresentation = {
	activeTabKey?: string;
	callbackSubmit?: () => void;
	changeIsOpen: (newIsOpen: boolean) => void;
	id?: string;
	sessionId: string;
	isOpen: boolean;
};

const ModalPresentation = (props: PropsModalPresentation): JSX.Element => {
	const { activeTabKey, changeIsOpen, id, isOpen, sessionId, callbackSubmit } = props;
	const { client, event } = useContextModule();

	const { create: createSpeaker } = ControllersApp.SpeakerController.useEntity();
	const { update, create } = ControllersApp.PresentationController.useEntity();
	const { sendSuccess, sendError } = useSnackBarHook();
	const sessionQuery = useSession(sessionId, event.id, client.id);

	const tagId = sessionQuery.data?.tagTypes?.[0]?.tags?.[0]?.id;
	const sessionTemplatesQuery = useSessionTemplates(client.id, event.id, {
		queryStrings: filtersToQueryString(`primaryTagId${ESieveOperator.EQUALS}${tagId}`),
		enabled: !!tagId,
	});

	const presentationCustomForm = usePresentationCustomFormQuery(sessionId, sessionTemplatesQuery, id);
	const lastPresentationScheduleQuery = usePresentations({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryParams: [
			...queryStringSorts(["-endDate"]),
			...queryStringPagination({ page: 0, pageSize: 1 }),
			...queryFilters(`endDate${ESieveOperator.NOT_EQUALS}null`),
		],
	});
	const sessionFirstPresentationQuery = usePresentations({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryParams: [...queryStringSorts(["-endDate"]), ...queryStringPagination({ page: 0, pageSize: 1 })],
	});

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const formMethods = useForm<TypeUseFormListForms>({
		mode: "onSubmit",
		resolver: HelpersApp.PresentationHelper.getResolver(presentationCustomForm ? cloneDeep(presentationCustomForm.useFormData) : undefined),
	});

	// Use the reset function to update the form display when the presentationCustomFormQuery.data is fetched
	useEffect(() => {
		if (presentationCustomForm) {
			formMethods.reset(presentationCustomForm.useFormData);
		}
	}, [formMethods, presentationCustomForm]);

	useEffect(() => {
		if (client.id && event.id && sessionId) {
			void lastPresentationScheduleQuery.refetch();
			void sessionFirstPresentationQuery.refetch();
		}
	}, [client.id, event.id, sessionId]);

	const handleSubmit = async (useFormData: TypeUseFormListForms) => {
		if (!id) {
			await create(useFormData, sessionId)
				.then((newPresentation) => {
					addSpeakerIfSelected(useFormData, newPresentation.id);
				})
				.catch(() => sendError(t("old.programme.presentations.create_edit_modale.snackbar.error")));
		} else if (await update(sessionId, id, useFormData)) {
			closeModalAndDisplaySnackBar(t("old.programme.presentations.create_edit_modale.snackbar.updated"));
		} else {
			sendError(t("old.programme.presentations.create_edit_modale.snackbar.error"));
		}
		setIsLoadingSubmit(false);
	};

	const handleInvalid = (_error: any) => {
		setIsLoadingSubmit(false);
		sendError(t("old.programme.presentations.create_edit_modale.snackbar.invalid"));
	};

	const closeModalAndDisplaySnackBar = (successMessage?: string) => {
		changeIsOpen(false);
		formMethods.reset();
		successMessage && sendSuccess(successMessage);
		callbackSubmit?.();
	};

	const addSpeakerIfSelected = async (useFormData: TypeUseFormListForms, presentationId: string) => {
		let selectedSpeaker;

		toPairs(useFormData).forEach((formObject) => {
			toPairs(formObject[1]).forEach((sectionObject) => {
				if (sectionObject[1].participantOrContact) {
					selectedSpeaker = sectionObject[1].participantOrContact;
				}
			});
		});

		if (selectedSpeaker) {
			await createSpeaker(useFormData, sessionId, presentationId)
				.then(() => {
					closeModalAndDisplaySnackBar(t("old.programme.presentations.create_edit_modale.snackbar.created"));
				})
				.catch(() => {
					sendError(t("old.programme.presentations.create_edit_modale.snackbar.speakerNotAddedToPresentation"));
					closeModalAndDisplaySnackBar();
				});
		} else {
			closeModalAndDisplaySnackBar(t("old.programme.presentations.create_edit_modale.snackbar.created"));
		}
	};

	return (
		<DialogOld isOpen={isOpen}>
			<>
				<DialogTitle
					title={
						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<Typography variant="h5">{id ? t("old.programme.presentations.modal.edit") : t("old.programme.presentations.modal.create")}</Typography>
							{presentationCustomForm?.raw?.key && <Typography variant="h5">{`- ${presentationCustomForm.raw.key}`}</Typography>}
						</Stack>
					}
					onCloseClick={() => {
						changeIsOpen(false);
						formMethods.reset();
					}}
				/>
				<DialogContent>
					{presentationCustomForm ? (
						<FormProvider {...formMethods}>
							<FormTabs activeTabKey={activeTabKey} {...presentationCustomForm.componentData} />
						</FormProvider>
					) : (
						<Stack spacing={2}>
							<Skeleton sx={{ height: 40 }} animation="wave" variant="rectangular" />
							<Skeleton sx={{ height: 450 }} animation="wave" variant="rectangular" />
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel
							onClick={() => {
								changeIsOpen(false);
								formMethods.reset();
							}}
						/>
						<ButtonSave
							isLoading={isLoadingSubmit}
							disabled={isLoadingSubmit || !isOpen}
							onClick={(e: BaseSyntheticEvent<object, any, any> | undefined) => {
								setIsLoadingSubmit(true);
								formMethods.handleSubmit(handleSubmit, handleInvalid)(e);
							}}
						/>
					</Stack>
				</DialogActions>
			</>
		</DialogOld>
	);
};

export default ModalPresentation;
