import {
	type MailSenderRead,
	type MailSenderWrite,
	type Paginated,
	type PathEntity,
	type ServiceArgs,
	deleteResponse,
	get,
	getPaginated,
	postCreate,
	put,
} from "@key4-front-library/core";

const module = "programme";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "mail-senders", id }];

export interface GetProgrammeMailSendersProps extends ServiceArgs {
	clientId: string;
	operationId: string;
}

export const getProgrammeMailSenders = async (args: GetProgrammeMailSendersProps): Promise<Paginated<MailSenderRead>> => {
	return await getPaginated<MailSenderRead>({
		module,
		entities: rootEntities(),
		...args,
	});
};

export interface GetProgrammeMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
}

export const getProgrammeMailSender = async (args: GetProgrammeMailSenderProps): Promise<MailSenderRead> => {
	const { id, ...others } = args;
	return await get<MailSenderRead>({
		module,
		entities: rootEntities(id),
		...others,
	});
};

export interface PostProgrammeMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	body: MailSenderWrite;
}

export const postProgrammeMailSender = async (args: PostProgrammeMailSenderProps): Promise<string> => {
	const { body, ...others } = args;
	return await postCreate<MailSenderWrite>(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

export interface PutProgrammeMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
	body: MailSenderWrite;
}

export const putProgrammeMailSender = async (args: PutProgrammeMailSenderProps): Promise<boolean> => {
	const { id, body, ...others } = args;
	return await put<MailSenderWrite>(
		{
			module,
			entities: rootEntities(id),
			...others,
		},
		body,
	);
};

export interface DeleteProgrammeMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
}

export const deleteProgrammeMailSender = async (args: DeleteProgrammeMailSenderProps): Promise<boolean> => {
	const { id, ...others } = args;
	return await deleteResponse({
		module,
		entities: rootEntities(id),
		...others,
	});
};
