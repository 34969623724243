import { t } from "i18next";
import { toPairs } from "lodash";
import { DateTime, Duration } from "luxon";
import type { FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import { EnumPresentationFieldKey, EnumPresentationFormKey, EnumPresentationSectionKey } from "@application/Enums/PresentationEnum";
import { EnumSpeakerFieldKey } from "@application/Enums/SpeakerEnum";
import type { TypePresentationStaticFieldData } from "@application/Types/PresentationType";
import HelpersApp from "@application/helpers/index";
import type { PresentationSchedule } from "@application/hooks/presentations/presentationSchedule.type";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	CustomFieldHelper,
	DATETIME_PARSING_FORMAT,
	DATE_PARSING_FORMAT,
	DURATION_FORMAT,
	type DtoPresentation,
	type DtoPresentationWrite,
	type DtoSession,
	type DtoSettingsScheduler,
	type DtoSpeakerGetQuality,
	FormHelper,
	GetEntireDate,
	SMALL_TIME_PARSING_FORMAT,
	type SessionTemplatesRead,
	type TypeCustomFieldForm,
	type TypeUseFormFieldValues,
	type TypeUseFormListForms,
	URL_REGEX,
	UseFormHelper,
	getISOFromDurationString,
	padStartDigitWith0,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import type { TypeAutoCompleteItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocomplete";

import DateHelper, { getDateTimeFromPartialInformation, getHourAndDateFromIsoString } from "./date.helper";

const DATETIME_FIELD_KEYS = [
	EnumPresentationFieldKey.START_DATE,
	EnumPresentationFieldKey.END_DATE,
	EnumPresentationFieldKey.START_HOUR,
	EnumPresentationFieldKey.END_HOUR,
];

const callbackDisplay = (
	keys: Array<string>,
	name: string,
	value: any,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	trigger: UseFormTrigger<FieldValues>,
) => {
	const nameSplit = name.split(".");
	const path = [nameSplit[0], nameSplit[1]].join(".");

	DATETIME_FIELD_KEYS.forEach((key: string) => {
		getValues([path, key].join(".")) === "Invalid DateTime" && setValues([path, key].join("."), null);
	});

	trigger();

	keys.forEach((key: string) => {
		if (value) {
			setValues([path, "metadata", key, "visibility"].join("."), false);
		} else {
			setValues([path, "metadata", key, "visibility"].join("."), true);
		}
	});
};

const triggerStartDate = (
	name: string,
	value: any,
	setValues: UseFormSetValue<FieldValues>,
	getValues: UseFormGetValues<FieldValues>,
	trigger: UseFormTrigger<FieldValues>,
) => {
	const nameSplit = name.split(".");
	const path = `${nameSplit[0]}.${nameSplit[1]}`;

	if (!getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`) && value) {
		setValues(`${path}.${EnumPresentationFieldKey.END_DATE}`, value);
	}
	setDurationValues(path, setValues, getValues);
	triggerDates(path, trigger);
};

const setDurationValues = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	// If duration doesn't exist compute duration
	if (
		getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`) &&
		getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`)
	) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);
		const dateEnd: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.END_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);
		const duration: Duration = dateEnd.diff(dateStart, ["hours", "minutes"]);
		if (!Number.isNaN(duration.minutes)) {
			if (duration.minutes > 0 || duration.hours > 0) {
				setValues(
					`${path}.${EnumPresentationFieldKey.DURATION}`,
					`${padStartDigitWith0(duration.hours.toString())}:${padStartDigitWith0(duration.minutes.toString())}`,
				);
			} else {
				setValues(`${path}.${EnumPresentationFieldKey.DURATION}`, null);
			}
		}
	}
};

const changeDatesOnDuration = (path: string, setValues: UseFormSetValue<FieldValues>, getValues: UseFormGetValues<FieldValues>) => {
	const startDate: string | null = getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`);
	const startHour: DateTime | null = getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`);

	const durationValue = getValues(`${path}.${EnumPresentationFieldKey.DURATION}`);

	if (startDate && startHour && durationValue && startDate !== "Invalid DateTime" && startHour.isValid) {
		const dateStart: DateTime = DateTime.fromFormat(
			`${getValues(`${path}.${EnumPresentationFieldKey.START_DATE}`)} ${(getValues(`${path}.${EnumPresentationFieldKey.START_HOUR}`) as DateTime).toFormat(
				SMALL_TIME_PARSING_FORMAT,
			)}`,
			DATETIME_PARSING_FORMAT,
		);

		const durationValue = getValues(`${path}.${EnumPresentationFieldKey.DURATION}`);
		let duration;

		if (typeof durationValue === "string") {
			duration = Duration.fromISOTime(durationValue);
		} else {
			duration = Duration.fromISOTime(durationValue.key);
		}

		if (duration.isValid) {
			const dateEnd: DateTime = dateStart.plus(duration);
			setValues(`${path}.${EnumPresentationFieldKey.END_DATE}`, dateEnd.toFormat(DATE_PARSING_FORMAT));
			setValues(`${path}.${EnumPresentationFieldKey.END_HOUR}`, dateEnd);
		}
	}
};

const triggerDates = (path: string, trigger: UseFormTrigger<FieldValues>) => {
	DATETIME_FIELD_KEYS.forEach((key: string) => {
		trigger(`${path}.${key}`);
	});
};

const getPresentationDates = (isWithoutTimeSlot: boolean, startDate?: string | null, endDate?: string | null): string => {
	if (isWithoutTimeSlot) {
		return t("old.programme.presentations.periodNoTimeSlot");
	}
	if (!startDate || !endDate) {
		return t("old.programme.presentations.notYetScheduled");
	}
	return GetEntireDate(startDate, endDate, false);
};

const getResolver: any = (useFormData: TypeUseFormListForms | undefined) => {
	const resolver: any = {};
	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	toPairs(useFormData).forEach((form) => {
		const formId = form[0];
		if (form[1] === undefined) return;
		const formResolver: any = {};
		toPairs(form[1]).forEach((section) => {
			const sectionId = section[0];
			if (section[1] === undefined) return;
			const sectionResolver: any = {};
			toPairs(section[1]).forEach((field) => {
				const fieldId = field[0];
				const fieldValue = field[1];
				switch (fieldId) {
					case EnumPresentationFieldKey.DURATION:
						sectionResolver[fieldId] = yup
							.mixed()
							.nullable()
							.test("formatDuration", t("old.programme.presentations.validation.durationWrongFormat") ?? "", function (_) {
								const { duration }: any = this.parent;
								if (!duration) {
									return true;
								}
								if (typeof duration === "object") {
									return duration.key.match(/\b\d\d:\d\d\b/) !== null;
								}

								return duration.match(/\b\d\d:\d\d\b/) !== null;
							})
							.test("DurationSup1day", t("old.programme.presentations.validation.durationSuperior24") ?? "", function (_) {
								const { duration } = this.parent;
								if (!duration) {
									return true;
								}

								let durationToCheck = duration;

								if (typeof duration === "object") {
									durationToCheck = duration.key;
								}

								if (durationToCheck.match(/\b\d\d:\d\d\b/) !== null) {
									const d = Duration.fromObject({
										hours: Number.parseInt(durationToCheck.slice(0, 2)),
										minutes: Number.parseInt(durationToCheck.slice(3, 5)),
									});
									if (d.isValid) {
										return d.as("hours") < 24;
									}
									return true;
								}
								return true;
							});
						break;
					case EnumPresentationFieldKey.END_HOUR:
						sectionResolver[fieldId] = yup
							.object()
							.nullable()
							.when([EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT], {
								is: (isWithoutTimeSlot: boolean) => !isWithoutTimeSlot,
								then: (schema) =>
									schema
										.nullable()
										.when(
											[
												EnumPresentationFieldKey.END_HOUR,
												EnumPresentationFieldKey.END_DATE,
												EnumPresentationFieldKey.START_DATE,
												EnumPresentationFieldKey.START_HOUR,
											],
											{
												is: (startDate: string | null, endDate: string | null, startHour: DateTime | null, endHour: DateTime | null) =>
													startDate ?? endDate ?? startHour ?? endHour,
												then: (schema) =>
													schema
														.nullable()
														.test("timeValid", t("old.common.formControl.error.timePicker.invalid") ?? "", (time: any) => {
															if (!time) return true;
															return time.invalid === null;
														})
														.test("required", t("old.common.formControl.error.required"), function (endHour: any) {
															const { startHour } = this.parent;
															return endHour || !startHour;
														})
														.test("timeRangeTest", t("old.common.formControl.error.timePicker.startSuperiorToEnd") ?? "", function (_) {
															const { endDate, startDate, startHour, endHour } = this.parent;
															if (
																startDate === "Invalid DateTime" ||
																endDate === "Invalid DateTime" ||
																!startDate ||
																!endDate ||
																!startHour ||
																!endHour ||
																startHour.invalid !== null ||
																endHour.invalid !== null
															) {
																return true;
															}

															return (
																DateTime.fromFormat(`${startDate} ${startHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT) <
																DateTime.fromFormat(`${endDate} ${endHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT)
															);
														}),
											},
										),
							});
						break;
					case EnumPresentationFieldKey.START_HOUR:
						sectionResolver[fieldId] = yup
							.object()
							.nullable()
							.when([EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT], {
								is: (isWithoutTimeSlot: boolean) => !isWithoutTimeSlot,
								then: (schema) =>
									schema
										.nullable()
										.when(
											[
												EnumPresentationFieldKey.END_HOUR,
												EnumPresentationFieldKey.END_DATE,
												EnumPresentationFieldKey.START_DATE,
												EnumPresentationFieldKey.START_HOUR,
											],
											{
												is: (startDate: string | null, endDate: string | null, startHour: DateTime | null, endHour: DateTime | null) =>
													startDate ?? endDate ?? startHour ?? endHour,
												then: (schema) =>
													schema
														.nullable()
														.test("timeValid", t("old.common.formControl.error.timePicker.invalid") ?? "", (time: any) => {
															if (!time) return true;
															return time.invalid === null;
														})
														.test("required", t("old.common.formControl.error.required"), function (startHour: any) {
															const { endHour } = this.parent;
															return startHour || !endHour;
														})
														.test("timeRangeTest", t("old.common.formControl.error.timePicker.startSuperiorToEnd") ?? "", function (_) {
															const { endDate, startDate, startHour, endHour } = this.parent;
															if (
																startDate === "Invalid DateTime" ||
																endDate === "Invalid DateTime" ||
																!startDate ||
																!endDate ||
																!startHour ||
																!endHour ||
																startHour.invalid !== null ||
																endHour.invalid !== null
															) {
																return true;
															}

															return (
																DateTime.fromFormat(`${startDate} ${startHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT) <
																DateTime.fromFormat(`${endDate} ${endHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT)
															);
														}),
											},
										),
							});
						break;
					case EnumPresentationFieldKey.START_DATE:
					case EnumPresentationFieldKey.END_DATE:
						sectionResolver[fieldId] = yup
							.string()
							.nullable()
							.when([EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT], {
								is: (isWithoutTimeSlot: boolean) => !isWithoutTimeSlot,
								then: (schema) =>
									schema
										.nullable()
										.when(
											[
												EnumPresentationFieldKey.END_HOUR,
												EnumPresentationFieldKey.END_DATE,
												EnumPresentationFieldKey.START_DATE,
												EnumPresentationFieldKey.START_HOUR,
											],
											{
												is: (startDate: string | null, endDate: string | null, startHour: DateTime | null, endHour: DateTime | null) =>
													startDate ?? endDate ?? startHour ?? endHour,
												then: (schema) =>
													schema
														.nullable()
														.not(["Invalid DateTime"], t("old.common.formControl.error.datePicker.invalid") ?? "")
														.required(t("old.common.formControl.error.required") ?? "")
														.test("timeRangeTest", t("old.common.formControl.error.timePicker.startSuperiorToEnd") ?? "", function (this: any, _: any) {
															const { endDate, startDate, startHour, endHour } = this.parent;
															if (
																startDate === "Invalid DateTime" ||
																endDate === "Invalid DateTime" ||
																!startDate ||
																!endDate ||
																!startHour ||
																!endHour ||
																startHour.invalid !== null ||
																endHour.invalid !== null
															) {
																return true;
															}

															return (
																DateTime.fromFormat(`${startDate} ${startHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT) <
																DateTime.fromFormat(`${endDate} ${endHour.toFormat(SMALL_TIME_PARSING_FORMAT)}`, DATETIME_PARSING_FORMAT)
															);
														}),
											},
										),
							});
						break;
					case EnumPresentationFieldKey.QUALITY:
						sectionResolver[fieldId] = yup.mixed().nullable();
						break;
					case EnumPresentationFieldKey.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT:
						sectionResolver[fieldId] = yup
							.mixed()
							.nullable()
							.test("required", t("old.common.formControl.error.required"), function (participantOrContact) {
								const { quality } = this.parent;
								return !(quality && !participantOrContact);
							});

						break;
					case EnumPresentationFieldKey.ABSTRACT_URL:
						sectionResolver[fieldId] = yup
							.string()
							.nullable()
							.trim()
							.matches(URL_REGEX, t("old.common.formControl.error.invalidFormat") ?? "");
						break;
				}
				CustomFieldHelper.setupResolver(t, sectionResolver, section[1], fieldId, fieldValue);
			});

			if (Object.keys(sectionResolver).length > 0) {
				formResolver[sectionId] = yup.object(sectionResolver);
			}
		});
		if (Object.keys(formResolver).length > 0) {
			resolver[formId] = yup.object(formResolver);
		}
	});

	return yupResolver(yup.object(resolver));
};

const mapFormTabsEditUseFormDefaultValue = (useFormData: TypeUseFormListForms, presentation: DtoPresentation) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId) {
					case EnumPresentationFieldKey.CODE:
						useFormData[formId][sectionId][fieldId] = presentation.code ?? null;
						break;
					case EnumPresentationFieldKey.DESCRIPTION:
						useFormData[formId][sectionId][fieldId] = presentation.description ?? null;
						break;
					case EnumPresentationFieldKey.DURATION:
						useFormData[formId][sectionId][fieldId] = presentation.duration ? Duration.fromISO(presentation.duration).toFormat(DURATION_FORMAT) : null;
						break;
					case EnumPresentationFieldKey.END_DATE:
						if (presentation.endDate) {
							useFormData[formId][sectionId][fieldId] = DateTime.fromISO(presentation.endDate, { zone: "UTC" }).toFormat(DATE_PARSING_FORMAT);
							useFormData[formId][sectionId][EnumPresentationFieldKey.END_HOUR] = DateTime.fromISO(presentation.endDate, {
								zone: "UTC",
							});
						}
						break;
					case EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT:
						useFormData[formId][sectionId][fieldId] = presentation.isWithoutTimeSlot;

						if (presentation.isWithoutTimeSlot) {
							const fieldsToHide = ["endHour", "endDate", "startDate", "startHour", "duration", "startHour"];

							fieldsToHide.forEach((fieldname) => {
								useFormData[formId][sectionId].metadata![fieldname].visibility = false;
							});
						}

						break;
					case EnumPresentationFieldKey.START_DATE:
						if (presentation.startDate) {
							useFormData[formId][sectionId][fieldId] = DateTime.fromISO(presentation.startDate, { zone: "UTC" }).toFormat(DATE_PARSING_FORMAT);
							useFormData[formId][sectionId][EnumPresentationFieldKey.START_HOUR] = DateTime.fromISO(presentation.startDate, {
								zone: "UTC",
							});
						}
						break;
					case EnumPresentationFieldKey.TITLE:
						useFormData[formId][sectionId][fieldId] = presentation.title ?? null;
						break;
					case EnumPresentationFieldKey.ABSTRACT_URL:
						useFormData[formId][sectionId][fieldId] = presentation.abstractUrl ?? null;
						break;
					case EnumPresentationFieldKey.ABSTRACT_EMBARGO_DATE:
						if (presentation.abstractEmbargoDate) {
							useFormData[formId][sectionId][fieldId] = DateTime.fromISO(presentation.abstractEmbargoDate).toFormat(DATETIME_PARSING_FORMAT);
						}
						break;
				}
			});
		});
	});
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	session: DtoSession,
	settingsScheduler: DtoSettingsScheduler | null,
	qualities: Array<DtoSpeakerGetQuality>,
	id?: string,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumPresentationFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumPresentationSectionKey) as Array<string>).includes(section.data.key)
			) {
				toPairs(
					ConfigurationsApp.PresentationConfiguration.staticListFormControlsObject[form.data.key as EnumPresentationFormKey][
						section.data.key as EnumPresentationSectionKey
					],
				).forEach((val: [string, TypePresentationStaticFieldData]) => {
					let formControl = val[1].component;
					const name = [form.data.id, section.data.id, formControl.id].join(".");
					const formControlId = formControl.id;
					formControl.propsComponent.label = t(formControl.propsComponent.label);

					const minDate = session.startDate ? DateTime.fromFormat(session.startDate, DATE_PARSING_FORMAT) : undefined;
					const maxDate = session.endDate ? DateTime.fromFormat(session.endDate, DATE_PARSING_FORMAT) : undefined;
					const minTime = settingsScheduler?.schedulerStartHour
						? DateTime.fromFormat(settingsScheduler.schedulerStartHour, SMALL_TIME_PARSING_FORMAT)
						: undefined;
					const maxTime = settingsScheduler?.schedulerEndHour ? DateTime.fromFormat(settingsScheduler.schedulerEndHour, SMALL_TIME_PARSING_FORMAT) : undefined;

					switch (formControlId) {
						case EnumPresentationFieldKey.END_DATE:
						case EnumPresentationFieldKey.START_DATE:
							formControl.propsComponent.minDate = minDate;
							formControl.propsComponent.maxDate = maxDate;
							formControl.propsComponent.defaultCalendarMonth = minDate;
							formControl.propsComponent.disabled = session.startDate && session.startDate === session.endDate;
							break;
						case EnumPresentationFieldKey.END_HOUR:
						case EnumPresentationFieldKey.START_HOUR:
							formControl.propsComponent.minTime = minTime;
							formControl.propsComponent.maxTime = maxTime;
							break;
						case EnumPresentationFieldKey.DURATION:
							formControl.propsComponent.items = DateHelper.durationSuggestionOptionsHours(2, 5).map((d) => {
								return { key: d, label: d };
							});
							break;
						case EnumPresentationFieldKey.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT:
							id && (formControl = undefined!);
							break;
						case EnumSpeakerFieldKey.QUALITY:
							if (id) {
								formControl = undefined!;
							} else {
								formControl.propsComponent.items = qualities.map((quality) => {
									return {
										key: quality.id,
										label: quality.label ?? "",
									};
								});
							}
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				});
			}
		}
	}
};

/**
 * Map the default values of the form
 * @param formsData Custom fields form data structure
 * @param useFormData UseForm data structure
 * @param sendSuccess Send success message method
 * @param session DtoSession
 * @param speakerQualities
 * @param sessionTemplate SessionTemplatesRead
 * @param presentationId
 */
const mapFormTabsUseFormDefaultValue = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	sendSuccess: (message: string) => any,
	session: DtoSession,
	speakerQualities: DtoSpeakerGetQuality[],
	sessionTemplate?: SessionTemplatesRead,
	presentationId?: string,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			if (
				(Object.values(EnumPresentationFormKey) as Array<string>).includes(form.data.key) &&
				(Object.values(EnumPresentationSectionKey) as Array<string>).includes(section.data.key)
			) {
				toPairs(
					ConfigurationsApp.PresentationConfiguration.staticListFormControlsObject[form.data.key as EnumPresentationFormKey][
						section.data.key as EnumPresentationSectionKey
					],
				).forEach((val: [string, TypePresentationStaticFieldData]) => {
					const fieldKey = val[0];
					const field = val[1];
					useFormData[form.data.id][section.data.id][fieldKey] = field.value;

					switch (fieldKey as EnumPresentationFieldKey) {
						case EnumPresentationFieldKey.START_DATE:
							if (session.startDate) {
								useFormData[form.data.id][section.data.id][fieldKey] = session.startDate;
							}
							break;
						case EnumPresentationFieldKey.END_DATE:
							if (session.endDate) {
								useFormData[form.data.id][section.data.id][fieldKey] = session.endDate;
							}
							break;
						case EnumPresentationFieldKey.DURATION:
							if (sessionTemplate?.presentationDuration) {
								useFormData[form.data.id][section.data.id][fieldKey] = Duration.fromISO(sessionTemplate.presentationDuration).toFormat("hh:mm");
							}

							break;
						case EnumPresentationFieldKey.ABSTRACT_URL: {
							const abstractUrlComponentProps =
								ConfigurationsApp.PresentationConfiguration.staticListFormControlsObject.abstract.main!.abstractUrl!.component.propsComponent;

							if (!abstractUrlComponentProps.endIcon.onClick) {
								abstractUrlComponentProps.endIcon.onClick = (value: string) => {
									navigator.clipboard.writeText(value ?? "");
									sendSuccess(t("old.link_copy"));
								};
							}
							break;
						}
						case EnumPresentationFieldKey.QUALITY:
							const haveSpeakerQuality = speakerQualities.length > 0;
							useFormData[form.data.id][section.data.id][fieldKey] = !presentationId && haveSpeakerQuality ? speakerQualities[0].id : null;
							useFormData[form.data.id][section.data.id].metadata![fieldKey as EnumPresentationFieldKey] = {
								visibility: haveSpeakerQuality,
							};
							break;

						default:
							break;
					}

					useFormData[form.data.id][section.data.id].metadata![fieldKey as EnumPresentationFieldKey] = {
						visibility: useFormData[form.data.id][section.data.id].metadata?.[fieldKey as EnumPresentationFieldKey]?.visibility ?? true,
					};
				});
			}
		}
	}
};

const mapUseFormToDtoPresentationWrite = (useFormData: TypeUseFormListForms, presentationWrite: DtoPresentationWrite) => {
	toPairs(useFormData).forEach((formObject) => {
		if (formObject[1] === undefined) return;
		toPairs(formObject[1]).forEach((sectionObject) => {
			if (sectionObject[1] === undefined) return;
			toPairs(sectionObject[1]).forEach((fieldObject) => {
				const fieldId = fieldObject[0];
				const fieldValue = fieldObject[1];
				// STATIC FIELDS
				switch (fieldId as EnumPresentationFieldKey) {
					case EnumPresentationFieldKey.CODE:
						presentationWrite.code = fieldValue as string | null;
						break;
					case EnumPresentationFieldKey.DESCRIPTION:
						presentationWrite.description = fieldValue as string | null;
						break;
					case EnumPresentationFieldKey.END_DATE: {
						const endHour = sectionObject[1][EnumPresentationFieldKey.END_HOUR];
						if (fieldValue && endHour) {
							// merge endDate and endHour and transform it in iso string
							presentationWrite.endDate = DateTime.fromFormat(
								`${fieldValue as string | null} ${(endHour as DateTime).toFormat(SMALL_TIME_PARSING_FORMAT)}`,
								DATETIME_PARSING_FORMAT,
								{ zone: "UTC" },
							)
								.toUTC()
								.toISO();
						} else {
							presentationWrite.endDate = null;
						}
						break;
					}
					case EnumPresentationFieldKey.IS_WITHOUT_TIME_SLOT:
						presentationWrite.isWithoutTimeSlot = (fieldValue ?? false) as boolean;
						break;
					case EnumPresentationFieldKey.START_DATE: {
						const startHour = sectionObject[1][EnumPresentationFieldKey.START_HOUR];
						if (fieldValue && startHour) {
							// merge endDate and endHour and transform it in iso string
							presentationWrite.startDate = DateTime.fromFormat(
								`${fieldValue as string | null} ${(startHour as DateTime).toFormat(SMALL_TIME_PARSING_FORMAT)}`,
								DATETIME_PARSING_FORMAT,
								{ zone: "UTC" },
							)
								.toUTC()
								.toISO();
						} else {
							presentationWrite.startDate = null;
						}
						break;
					}
					case EnumPresentationFieldKey.TITLE:
						presentationWrite.title = fieldValue as string | null;
						break;
					case EnumPresentationFieldKey.ABSTRACT_URL:
						presentationWrite.abstractUrl = fieldValue as string | null;
						break;
					case EnumPresentationFieldKey.ABSTRACT_EMBARGO_DATE: {
						const abstractEmbargoDate = fieldValue as DateTime | string | null;
						let embargo: string | null = null;
						if (typeof abstractEmbargoDate === "object" && abstractEmbargoDate) {
							embargo = abstractEmbargoDate.toUTC().toISO();
						} else if (abstractEmbargoDate) {
							embargo = DateTime.fromFormat(abstractEmbargoDate, DATETIME_PARSING_FORMAT, { zone: "UTC" }).toUTC().toISO();
						}
						presentationWrite.abstractEmbargoDate = embargo;
						break;
					}
					case EnumPresentationFieldKey.DURATION: {
						const duration = fieldValue as TypeAutoCompleteItem | string | null;
						let d: string | undefined;
						if (typeof duration === "object") {
							d = duration?.key;
						} else {
							d = duration;
						}
						presentationWrite.duration = d ? getISOFromDurationString(d) : null;
						break;
					}
					default:
						break;
				}
				// CUSTOM FIELDS
				if (fieldId === "customFieldValues") {
					if (!fieldValue) return;
					presentationWrite.customFieldValues = {
						...presentationWrite.customFieldValues,
						...CustomFieldHelper.mapUseFormToCustomfieldValues(fieldValue as TypeUseFormFieldValues, sectionObject),
					};
				}
			});
		});
	});
};

function getScheduleFromExistingInformation(
	session: DtoSession,
	sessionFirstPresentation: Array<DtoPresentation>,
	presentationId?: string,
	lastPresentations?: Array<DtoPresentation>,
): {
	startDate: string | undefined | null;
	startHour: string | undefined | null;
} {
	let startDate: string | undefined | null = null;
	let startHour: string | undefined | null = null;

	if (sessionFirstPresentation.length > 0 && lastPresentations && lastPresentations.length > 0) {
		if (presentationId === undefined) {
			const endDate = lastPresentations[0].endDate;
			const lastPresentationSchedule = endDate ? getHourAndDateFromIsoString(endDate) : null;
			if (lastPresentationSchedule) {
				startDate = lastPresentationSchedule.date;
				startHour = lastPresentationSchedule.hour;
			}
		}
	} else {
		startDate = session.startDate;
		startHour = session.startHour;
	}
	return { startDate, startHour };
}

/**
 * Get the schedule information for the presentation to edit or create
 * extract date, hour and the information on what to display
 * date only, hour only, both or none
 */
export function getPresentationSchedule(
	id?: string,
	session?: DtoSession,
	sessionFirstPresentation?: Array<DtoPresentation>,
	lastPresentations?: Array<DtoPresentation>,
): PresentationSchedule | undefined {
	if (!session || !sessionFirstPresentation) {
		return undefined;
	}

	const duration = Duration.fromISOTime(ConfigurationsApp.PresentationConfiguration.DEFAULT_DURATION).as("minutes");
	const { startDate, startHour } = getScheduleFromExistingInformation(session, sessionFirstPresentation, id, lastPresentations);

	const { date: presentationStartDate, dateType } = getDateTimeFromPartialInformation(startDate, startHour);
	const presentationEndDate = presentationStartDate ? presentationStartDate.plus({ minutes: duration }) : null;

	return {
		startDate: presentationStartDate,
		endDate: presentationEndDate,
		dateType,
	};
}

export function mapDefaultValues(
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
	session: DtoSession,
	sendSuccess: (message: string) => string | number,
	speakerQualities: DtoSpeakerGetQuality[],
	sessionTemplate?: SessionTemplatesRead,
	presentationId?: string,
): void {
	// Add Forms / Sections useFormData
	UseFormHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);

	HelpersApp.PresentationHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData, sendSuccess, session, speakerQualities, sessionTemplate, presentationId);

	CustomFieldHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);
}

export function mapComponentData(
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	session: DtoSession,
	settingsScheduler: DtoSettingsScheduler | null,
	qualities: Array<DtoSpeakerGetQuality>,
	presentationId?: string,
): void {
	// Add tabs and sections props
	FormHelper.mapFormTabsProps(formsData, componentData);

	HelpersApp.PresentationHelper.mapFormTabsPropsStaticFields(formsData, componentData, session, settingsScheduler, qualities, presentationId);

	CustomFieldHelper.mapFormTabsPropsFields(formsData, componentData);
}

export function mapExistingData(useFormData: TypeUseFormListForms, presentation: DtoPresentation): void {
	// Add Static fields useFormData
	HelpersApp.PresentationHelper.mapFormTabsEditUseFormDefaultValue(useFormData, presentation);

	// Add Custom fields useFormData
	CustomFieldHelper.mapFormTabsEditUseFormDefaultValue(useFormData, presentation.customFieldValues);
}

const PresentationHelper = {
	callbackDisplay,
	changeDatesOnDuration,
	getPresentationDates,
	getResolver,
	mapFormTabsEditUseFormDefaultValue,
	mapFormTabsPropsStaticFields,
	mapFormTabsUseFormDefaultValue,
	mapUseFormToDtoPresentationWrite,
	setValuesDuration: setDurationValues,
	triggerDates,
	triggerStartDate,
};

export default PresentationHelper;
