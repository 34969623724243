import { IconItemReference } from "@application/enum/IconItemReference.enum";
import type { IIconsItems } from "@infrastructure/model/interfaces/navigation/iconsItems.interface";

const GetIconsItems = (): IIconsItems => {
	return [
		{
			reference: IconItemReference.TAG,
			icon: "tag",
		},
		{
			reference: IconItemReference.ANOMALIES,
			icon: "calendar-circle-plus",
		},

		{
			reference: IconItemReference.PROGRAMME,
			icon: "calendar-pen",
		},
		{
			reference: IconItemReference.DASHBOARD,
			icon: "chart-line-up",
		},
		{
			reference: IconItemReference.SCHEDULER,
			icon: "calendar-circle-plus",
		},
		{
			reference: IconItemReference.SESSION,
			icon: "clipboard-list",
		},
		{
			reference: IconItemReference.PRESENTATION,
			icon: "podium",
		},
		{
			reference: IconItemReference.ROOM,
			icon: "screen-users",
		},
		{
			reference: IconItemReference.FACULTY,
			icon: "user-group",
		},
		{
			reference: IconItemReference.CHAIR,
			icon: "user-crown",
		},
		{
			reference: IconItemReference.SPEAKER,
			icon: "chalkboard-user",
		},
		{
			reference: IconItemReference.NOTIFICATION,
			icon: "bell",
		},
		{
			reference: IconItemReference.CLASH,
			icon: "calendar-circle-exclamation",
		},
		{
			reference: IconItemReference.ANOMALY,
			icon: "triangle-exclamation",
		},
		{
			reference: IconItemReference.ACTIONS,
			icon: "list-check",
		},
		{
			reference: IconItemReference.INVITATION,
			icon: "envelopes-bulk",
		},
		{
			reference: IconItemReference.NUMBERING,
			icon: "list-ol",
		},
		{
			reference: IconItemReference.PUBLICATION,
			icon: "earth-europe",
		},
		{
			reference: IconItemReference.SETTINGS,
			icon: "gear",
		},
		{
			reference: IconItemReference.SESSION_TEMPLATES,
			icon: "paste",
		},
	];
};

const IconsApplicationConfiguration = {
	GetIconsItems,
};
export default IconsApplicationConfiguration;
