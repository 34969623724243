import { useState } from "react";

import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { K4Button } from "@infrastructure/components/interface/button/K4Button";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IUiButton } from "@infrastructure/model/interfaces/components/UiButton.interface";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import { Box, Card, CardContent, CardHeader, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";

type Props = {
	title: string;
	subTitle?: string;
	icon?: IconName;
	menu?: IUiMenu;
	button?: IUiButton;
	backgroundColor?: string;
	children?: JSX.Element;
};
const IconCardWithButtonAndMenu = (props: Props) => {
	const theme = useTheme();

	const { title, subTitle, icon, button: IconCardButton, backgroundColor = theme.palette.background.default, menu: IconCardMenu, children } = props;

	const actionsConfigurations = GetMenuActionsConfigurations();

	const menuConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.MENU);

	const [anchorElementMenu, setAnchorEl] = useState<null | HTMLElement>(null);
	const menuOpenState = Boolean(anchorElementMenu);
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	type RenderButtonPorps = {
		button: IUiButton;
	};
	const RenderButton = (buttonProps: RenderButtonPorps) => {
		const { button } = buttonProps;
		return (
			<K4Button
				label={button.label}
				color={"secondary"}
				size={"small"}
				startIcon={<Icon prefix="far" iconName={button.icon} size={"1x"} />}
				handleClick={button.onClick}
			></K4Button>
		);
	};

	type RenderMenuProps = {
		menu: IUiMenu;
	};
	const RenderMenu = (menuProps: RenderMenuProps) => {
		const { menu } = menuProps;
		return (
			<Menu
				anchorEl={anchorElementMenu}
				open={menuOpenState}
				onClose={handleCloseMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{menu.items.map((item, index) => {
					return (
						<Box key={`menu-item-${index}`}>
							{typeof item !== "string" && (
								<MenuItem
									key={item.reference}
									onClick={() => {
										item.onClick();
										handleCloseMenu();
									}}
								>
									<ListItemIcon>
										<Icon iconName={item.icon} color={theme.palette.secondary.main} />
									</ListItemIcon>
									{item.label}
								</MenuItem>
							)}
							{typeof item === "string" && <Divider />}
						</Box>
					);
				})}
			</Menu>
		);
	};

	return (
		<Card
			sx={{
				p: 0.5,
				my: 1,
				backgroundColor: backgroundColor,
			}}
		>
			<CardHeader
				avatar={
					icon && (
						<IconButton sx={{ width: 24, height: 24 }}>
							<Icon prefix="far" iconName={icon} size={"1x"} />
						</IconButton>
					)
				}
				title={
					<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mr: 2 }}>
						<Typography variant={"h6"} fontWeight={"300"} color={"primary"}>
							{title}
						</Typography>
						<Typography variant={"body2"} fontWeight={"600"}>
							{subTitle}
						</Typography>
					</Stack>
				}
				action={
					<Stack direction={"row"} alignItems={"center"} spacing={1}>
						{IconCardButton && <RenderButton button={IconCardButton} />}

						<IconButton onClick={handleOpenMenu} sx={{ width: 36, height: 36 }}>
							<Icon prefix="fal" iconName={menuConfig?.icon as IconName} size={"1x"} />
						</IconButton>
						{IconCardMenu && <RenderMenu menu={IconCardMenu} />}
					</Stack>
				}
			/>
			{children && (
				<CardContent>
					<Stack spacing={3}>{children}</Stack>
				</CardContent>
			)}
		</Card>
	);
};

export default IconCardWithButtonAndMenu;
