import { t } from "i18next";

import { EPublishSession } from "@application/Configurations/publish.configuration";
import type { IPublishView } from "@domain/interfaces/session.interface";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { ApplicationColors } from "@key4-front-library/core";
import { Button } from "@mui/material";

type Props = {
	data: IPublishView;
	handleClick: () => void;
};
const PublishView = (props: Props & dataCypressProp) => {
	// #region 'Definition'
	const { data, handleClick, dataCypressID } = props;
	const { activePublish, shortDateTime } = data;

	const styles = {
		greyButton: {
			backgroundColor: activePublish.bgColor,
			borderColor: ApplicationColors.darkGrey.light,
			"&:hover": {
				backgroundColor: ApplicationColors.darkGrey.main,
				borderColor: ApplicationColors.darkGrey.light,
			},
		},
	};
	// #endregion 'Definition'

	// #region 'Rendering'
	return (
		<Button
			variant="contained"
			fullWidth
			onClick={handleClick}
			data-cy={dataCypressID ? `${dataCypressID}-publish_button` : undefined}
			// color button could be from the MUI theme or a custom color
			// if custom color, define the background color and on hover background color
			color={activePublish.value !== EPublishSession.NULL ? (activePublish.bgColor as any) : undefined}
			sx={{
				...(activePublish.value === EPublishSession.NULL && {
					...styles.greyButton,
				}),
			}}
		>
			{`${t(activePublish.label)} ${shortDateTime ?? ""}`}
		</Button>
	);
	// #endregion 'Rendering'
};

export default PublishView;
