import { t } from "i18next";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import DateHelper from "@application/helpers/date.helper";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	DURATION_FORMAT,
	DURATION_FORMAT_REGEX,
	EnumParticipantStatusInvitation,
	EnumSessionStatus,
	FormControlTextField,
	type SessionTemplatesRead,
	statusInvitationArray,
	statusInvitationParticipantObject,
	statusSessionArray,
	statusSessionObject,
	TimePicker,
	type TypeUseFormCreateOrUpdateSessionTemplateValue,
	ValidationFormButtons,
} from "@key4-front-library/core";
import FormControlAutocomplete from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocomplete";
import FormControlCheckbox from "@key4-front-library/core/Bo/Components/FormControl/FormControlCheckbox";
import FormControlSelectBulletColor, { type TypeSelectBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectBulletColor";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Stack,
	Typography,
} from "@mui/material";

interface ModalSettingsSessionTemplatesProps {
	primaryTagId: string;
	templateToEdit?: SessionTemplatesRead;
	isOpenModal: boolean;
	handleModalClose: (reason?: string) => void;
	handleSubmit: (form: TypeUseFormCreateOrUpdateSessionTemplateValue) => void;
	isLoading: boolean;
}

const formatDurationFromIso = (duration: string | undefined): string | undefined => {
	return duration ? Duration.fromISO(duration).toFormat(DURATION_FORMAT) : undefined;
};

/**
 * TODO: Remove when passing to MUI >= 6
 * MUI TimePicker default picker set the tz to the local timezone.
 * This function cancel the offset to get the correct time as if it was UTC.
 * @param value
 */
const cancelOffset = (value: DateTime | null): DateTime | null => (value && value.offset !== 0 ? value.toUTC().plus({ minutes: value.offset }) : value);

const ModalSettingsSessionTemplates = (props: ModalSettingsSessionTemplatesProps): React.ReactElement => {
	const { templateToEdit, primaryTagId, isOpenModal, handleModalClose, handleSubmit, isLoading } = props;

	const component = "old.programme.settings.sessionTemplates.modal";

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [selectedHoursOrDuration, setSelectedHoursOrDuration] = useState<"hours" | "duration" | null>(null);

	const defaultFormValues: any = {
		primaryTagId,
		startHour: templateToEdit?.startHour ? DateTime.fromISO(templateToEdit.startHour) : null,
		endHour: templateToEdit?.endHour ? DateTime.fromISO(templateToEdit.endHour) : null,
		duration: formatDurationFromIso(templateToEdit?.duration),
		presentationCount: templateToEdit?.presentationCount,
		presentationDuration: formatDurationFromIso(templateToEdit?.presentationDuration),
		status: templateToEdit?.status ?? EnumSessionStatus.DRAFT,
		chairInvitationStatus: templateToEdit?.chairInvitationStatus ?? EnumParticipantStatusInvitation.UNSENT,
		speakerInvitationStatus: templateToEdit?.speakerInvitationStatus ?? EnumParticipantStatusInvitation.UNSENT,
		isPrivate: templateToEdit?.isPrivate ?? false,
	};

	const schema = yup.object({
		startHour: yup.string().nullable(),
		endHour: yup
			.string()
			.nullable()
			.when("startHour", {
				is: (startHour: string | undefined) => !!startHour?.length,
				then: (schema) =>
					schema
						.nullable()
						.required(t(`${component}.errors.endTimeMissing`))
						.test("timeRangeTest", t("old.common.formControl.error.timePicker.endSuperiorToStart"), function () {
							const { startHour, endHour }: { startHour: string; endHour: string } = this.parent;
							return DateTime.fromISO(startHour) < DateTime.fromISO(endHour);
						}),
			}),
		duration: yup
			.mixed()
			.nullable()
			.test("DurationSup1day", t("old.programme.presentations.validation.durationSuperior24"), function () {
				const { duration }: { duration: string | undefined } = this.parent;
				if (!duration) {
					return true;
				}
				if (duration.length < DURATION_FORMAT.length) {
					return true;
				}
				const durationObject = Duration.fromObject({
					hours: Number.parseInt(duration.split(":")[0]),
					minutes: Number.parseInt(duration.split(":")[1]),
				});

				if (RegExp(DURATION_FORMAT_REGEX).exec(duration) !== null) {
					return durationObject <= Duration.fromISO("P0DT24H00M");
				}
				return true;
			}),
		presentationCount: yup
			.number()
			.min(0, t("old.form.fields.error.notBelow", { minValue: 0 }))
			.integer(t("old.form.fields.error.integer", { fieldName: "presentationCount" }))
			.nullable(),
		presentationDuration: yup
			.mixed()
			.nullable()
			.test("DurationSup1day", t("old.programme.presentations.validation.durationSuperior24"), function () {
				const { presentationDuration }: { presentationDuration: string | undefined } = this.parent;
				if (!presentationDuration) {
					return true;
				}
				if (presentationDuration.length < DURATION_FORMAT.length) {
					return true;
				}
				const durationObject = Duration.fromObject({
					hours: Number.parseInt(presentationDuration.split(":")[0]),
					minutes: Number.parseInt(presentationDuration.split(":")[1]),
				});

				if (RegExp(DURATION_FORMAT_REGEX).exec(presentationDuration) !== null) {
					return durationObject <= Duration.fromISO("P0DT24H00M");
				}
				return true;
			})
			.when(["startHour", "endHour", "presentationCount"], {
				is: (startHour: string, endHour: string, presentationCount: number) => !!startHour && !!endHour && !!presentationCount,
				then: (schema) =>
					schema.test("TotalDurationFitInSession", t(`${component}.errors.totalDurationExceedsSessionSchedule`), function () {
						const { startHour, endHour, presentationCount, presentationDuration } = this.parent;

						if (!startHour || !endHour || !presentationCount || !presentationDuration) {
							return true;
						}

						const start = DateTime.fromISO(startHour);
						const end = DateTime.fromISO(endHour);
						const startEndDiff = end.diff(start, "minutes");
						const parsedDuration = Duration.fromObject({
							hours: Number.parseInt(presentationDuration.split(":")[0]),
							minutes: Number.parseInt(presentationDuration.split(":")[1]),
						});
						const totalDuration = parsedDuration.as("minutes") * presentationCount;
						return startEndDiff.as("minutes") >= totalDuration;
					}),
			}),
	});

	const form = useForm({
		mode: "all",
		defaultValues: defaultFormValues,
		resolver: yupResolver(schema),
	});

	const getInvitationStatus = (): Array<TypeSelectBulletColorItem> => {
		return statusInvitationArray.map((statusEnum: EnumParticipantStatusInvitation) => {
			return {
				key: statusInvitationParticipantObject[statusEnum].value,
				label: t(statusInvitationParticipantObject[statusEnum].label),
				color: statusInvitationParticipantObject[statusEnum].bgColor,
			};
		});
	};

	useEffect(() => {
		form.reset(defaultFormValues);
		switch (true) {
			case !!templateToEdit?.startHour && !!templateToEdit.endHour:
				setSelectedHoursOrDuration("hours");
				break;
			case !!templateToEdit?.duration:
				setSelectedHoursOrDuration("duration");
				break;
			default:
				setSelectedHoursOrDuration(null);
				break;
		}
	}, [form, templateToEdit]);

	const handleSaveButtonClicked = useCallback((): void => {
		setIsSubmitting(true);
		void form
			.handleSubmit(handleSubmit)()
			.then(() => {
				setIsSubmitting(false);
			});
	}, [form, handleSubmit]);

	const handleModalCloseClick = useCallback(
		(reason?: string) => {
			handleModalClose(reason);
		},
		[handleModalClose],
	);

	const onDialogClose = useCallback(
		(_: object, reason: "backdropClick" | "escapeKeyDown") => {
			handleModalCloseClick(reason);
		},
		[handleModalCloseClick],
	);

	return (
		<Dialog open={isOpenModal} onClose={onDialogClose} maxWidth={"lg"} disableEnforceFocus={true}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{templateToEdit ? t(`${component}.editTemplate.title`) : t(`${component}.addTemplate.title`)}
				<IconButton
					onClick={() => {
						handleModalClose();
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Box component="form">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="subtitle2">{t(`${component}.sections.sessionInformations`)}</Typography>
							<Divider />
						</Grid>
						<Grid item md={6} xs={12} alignSelf={"center"}>
							<Controller
								name="status"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlSelectBulletColor
										label={t(`${component}.fields.sessionStatus.title`)}
										value={value}
										onChange={onChange}
										error={error?.message}
										disabled={isLoading}
										isNullable={false}
										items={statusSessionArray.map((status) => {
											return {
												key: statusSessionObject[status].value,
												label: t(statusSessionObject[status].label),
												color: statusSessionObject[status].bgColor,
											};
										})}
									/>
								)}
							/>
						</Grid>
						<Grid item md={6} xs={12} alignSelf={"center"}>
							<Controller
								name="isPrivate"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlCheckbox
										label={t(`${component}.fields.isPrivate.title`)}
										value={value}
										onChange={onChange}
										error={error?.message}
										disabled={isLoading}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<RadioGroup defaultValue={"hours"} row>
								<Grid item md={6} xs={12} paddingRight={1}>
									<Stack direction={"column"} width={"100%"}>
										<FormControlLabel
											control={<Radio />}
											label={t(`${component}.fields.timeOrDuration.time`)}
											checked={selectedHoursOrDuration === "hours"}
											value="hours"
											name="hoursOrDuration"
											onChange={() => {
												setSelectedHoursOrDuration("hours");
												form.setValue("duration", null);
											}}
										/>
										<Stack direction={"column"}>
											<Controller
												name="startHour"
												control={form.control}
												render={({ field: { value, onChange }, fieldState: { error } }) => (
													<TimePicker
														onChange={(value) => {
															onChange(cancelOffset(value));
															setSelectedHoursOrDuration(value ? "hours" : null);
														}}
														label={t(`${component}.fields.startTime.title`)}
														error={error?.message}
														value={value}
														disabled={isLoading || (selectedHoursOrDuration !== null && selectedHoursOrDuration !== "hours")}
													/>
												)}
											/>
											<Controller
												name="endHour"
												control={form.control}
												render={({ field: { value, onChange }, fieldState: { error } }) => (
													<TimePicker
														onChange={(value) => {
															onChange(cancelOffset(value));
															setSelectedHoursOrDuration(value ? "hours" : null);
														}}
														label={t(`${component}.fields.endTime.title`)}
														error={error?.message}
														value={value}
														disabled={isLoading || (selectedHoursOrDuration !== null && selectedHoursOrDuration !== "hours")}
													/>
												)}
											/>
										</Stack>
									</Stack>
								</Grid>
								<Grid item md={6} xs={12}>
									<Stack direction={"column"} width={"100%"}>
										<FormControlLabel
											control={<Radio />}
											label={t(`${component}.fields.timeOrDuration.duration`)}
											checked={selectedHoursOrDuration === "duration"}
											value="duration"
											name="hoursOrDuration"
											onChange={() => {
												setSelectedHoursOrDuration("duration");
												form.setValue("startHour", null);
												form.setValue("endHour", null);
											}}
										/>
										<Stack width={"100%"}>
											<Controller
												name="duration"
												control={form.control}
												render={({ field: { value, onChange }, fieldState: { error } }) => (
													<FormControlAutocomplete
														value={value}
														freeSolo={true}
														onChange={(value) => {
															onChange(value);
															setSelectedHoursOrDuration(value ? "duration" : null);
														}}
														error={error?.message}
														label={t(`${component}.fields.duration.title`)}
														items={DateHelper.durationSuggestionOptionsHours(8, 15)}
														disabled={isLoading || (selectedHoursOrDuration !== null && selectedHoursOrDuration !== "duration")}
													/>
												)}
											/>
										</Stack>
									</Stack>
								</Grid>
							</RadioGroup>
						</Grid>
						<Grid item xs={12} mt={2}>
							<Typography variant="subtitle2">{t(`${component}.sections.presentation`)}</Typography>
							<Divider />
						</Grid>
						<Grid item md={6} xs={12}>
							<Controller
								name="presentationCount"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlTextField
										label={t(`${component}.fields.numberOfPresentations.title`)}
										value={value?.toString()}
										onChange={onChange}
										error={error?.message}
										disabled={isLoading}
										type="number"
										min={0}
									/>
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Controller
								name="presentationDuration"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlAutocomplete
										value={value}
										freeSolo={true}
										onChange={(val) => {
											onChange(val);
										}}
										error={error?.message}
										label={t(`${component}.fields.presentationDuration.title`)}
										items={DateHelper.durationSuggestionOptionsHours(2, 5)}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} mt={2}>
							<Typography variant="subtitle2">{t(`${component}.sections.invitationsStatus`)}</Typography>
							<Divider />
						</Grid>

						<Grid item md={6} xs={12}>
							<Controller
								name="chairInvitationStatus"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlSelectBulletColor
										label={t(`${component}.fields.chairInvitationStatus.title`)}
										value={value}
										onChange={onChange}
										error={error?.message}
										isNullable={false}
										disabled={isLoading}
										items={getInvitationStatus()}
									/>
								)}
							/>
						</Grid>

						<Grid item md={6} xs={12}>
							<Controller
								name="speakerInvitationStatus"
								control={form.control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<FormControlSelectBulletColor
										label={t(`${component}.fields.speakerInvitationStatus.title`)}
										value={value}
										onChange={onChange}
										error={error?.message}
										disabled={isLoading}
										items={getInvitationStatus()}
										isNullable={false}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions sx={{ p: 2 }}>
				<ValidationFormButtons
					saveButtonClick={handleSaveButtonClicked}
					cancelButtonClick={handleModalCloseClick}
					disabledCancelButton={isLoading}
					disabledSaveButton={isSubmitting || isLoading}
					isLoading={isLoading}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default ModalSettingsSessionTemplates;
