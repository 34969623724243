import { t } from "i18next";

const GetTranslationsForFacultyDetails = () => {
	return {
		locale: t("old.common.current_locale"),
		eventInvitation: t(`old.programme.facultyDetails.eventInvitation`),
		profile: t(`old.programme.facultyDetails.profile`),
		email: t(`old.programme.facultyDetails.email`),
		buttons: {
			participant: t(`old.programme.facultyDetails.buttons.participant`),
			sendMail: t(`old.programme.facultyDetails.buttons.sendMail`),
		},
		invitations: t(`old.programme.facultyDetails.invitations`),
		status: t(`old.programme.facultyDetails.status`),
		participant: t("old.common.unities.participant.single"),
	};
};

export default GetTranslationsForFacultyDetails;
