import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import type { ISettingsSchedulerEditType } from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import SettingsSchedulerSkeleton from "@application/components/settings/scheduler/scheduler/SettingsSchedulerSkeleton";
import SettingsSchedulerView from "@application/components/settings/scheduler/scheduler/SettingsSchedulerView";
import SettingsController from "@application/Controllers/SettingsController";
import { IconItemReference } from "@application/enum/IconItemReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import GetTranslationsForSettingsScheduler from "@application/helpers/translations/settings/GetTranslationsForSettingsScheduler";
import type { ISettingsSchedulerObject } from "@domain/interfaces/settings.scheduler.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { useSettingsContext } from "@infrastructure/contexts/settingsContext";
import { Cultures, Services, useAuthenticationContext, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import type { DtoEvent } from "@key4-front-library/core/Dto";
import { Grid } from "@mui/material";

const SettingsScheduler = () => {
	const { client, event } = useContextModule();
	const { settingsContext } = useSettingsContext();

	const { sendSuccess, sendError } = useSnackBarHook();

	const translations = GetTranslationsForSettingsScheduler();

	const { user } = useAuthenticationContext();
	const culture = Cultures[user.locale].locale;

	const [isLoadingSettingsScheduler, setIsLoadingSettingsScheduler] = useState<boolean>(true);

	const [settingsSchedulerData, setSettingsSchedulerData] = useState<ISettingsSchedulerObject>();

	const schedulerIconName = settingsContext?.icons.find((module) => module.reference === IconItemReference.SCHEDULER)?.icon;

	const fetchSchedulerSettings = useCallback(
		async (event: DtoEvent) => {
			await Services.Events.Programme.SettingsService.getScheduler(client.id, event.id)
				.then((_data: any) => {
					const settingsSchedulerObject = SettingsFactory.TransformSettingsSchedulerDataForReading({
						settings: _data,
						culture,
					});
					setSettingsSchedulerData(settingsSchedulerObject);
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				})
				.finally(() => {
					setIsLoadingSettingsScheduler(false);
				});
		},
		[event, culture],
	);

	useEffect(() => {
		setIsLoadingSettingsScheduler(true);
		fetchSchedulerSettings(event);
	}, [event]);

	const handleSaveSchedulerSettings = async (form: ISettingsSchedulerEditType) => {
		try {
			if (await SettingsController.updateSettingScheduler(client.id, event.id, form)) {
				await fetchSchedulerSettings(event);
				sendSuccess(t("old.programme.settings.scheduler.success"));
			} else {
				sendError(t("old.common.errors.generic"));
			}
		} catch {
			sendError(t("old.common.errors.generic"));
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{!isLoadingSettingsScheduler ? (
					settingsSchedulerData && (
						<SettingsSchedulerView
							icon={schedulerIconName as IconName}
							title={translations.scheduler.title}
							culture={culture}
							translations={translations}
							settings={settingsSchedulerData}
							saveSettings={handleSaveSchedulerSettings}
						/>
					)
				) : (
					<SettingsSchedulerSkeleton />
				)}
			</Grid>
		</Grid>
	);
};

export default SettingsScheduler;
