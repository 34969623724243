import { Grid, Skeleton } from "@mui/material";

const BlocdSkeleton = () => {
	return (
		<Grid item xs={12} md={12} lg={12}>
			<Skeleton variant="rounded" height={200} />
		</Grid>
	);
};
const SettingsClashesSkeleton = () => {
	return (
		<Grid container spacing={2} mt={5}>
			<BlocdSkeleton />
		</Grid>
	);
};

export default SettingsClashesSkeleton;
