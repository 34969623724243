export enum ESessionFacultyClashes {
	SESSION_SAME_ROOM_SAME_TIME = "sessionSameRoomSameTime",
	FACULTY_OVER_LAPPING_SCHEDULES = "facultyOverlappingSchedules",
	FACULTY_ALLOW_TIME_CHANGE_ROOMS = "facultyAllowTimeChangeRooms",
}

export enum EnumSessionFacultyClashesEntityType {
	ALL = "all",
	SESSION = "session",
	INVITATION = "invitation",
}
