import type { EnumBulkActionNumberingType } from "@key4-front-library/core/Enums";

export type TypeApiQueryString = {
	key: string;
	value: string;
};

export type TPagination = {
	pageSize: number;
	page: number;
};

export const PATCH_PREFIX_CUSTOM_FIELD = "/cf:";

export enum EnumApiPatchOperation {
	REPLACE = "replace",
	ADD = "add",
	REMOVE = "remove",
	CLEAR = "clear",
}

export type TJSONPatch = {
	op: EnumApiPatchOperation;
	path: string;
	value: any;
};

export type TBodyBulk = {
	filters: string;
	excludedIds: string[];
};

export type TPatchBulk = {
	operations: TJSONPatch[];
} & TBodyBulk;

export type TDeleteBulk = TBodyBulk;

export type TNumberingBulk = {
	type: EnumBulkActionNumberingType;
} & TBodyBulk;
