import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ClashesFilters from "@application/components/_common/Clashes/ClashesFilters";
import ClashesRender from "@application/components/_common/Clashes/ClashesRender";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import ClashesFactory from "@application/factory/clashes/ClashesFactory";
import GetTranslationsForClashes from "@application/helpers/translations/clashes/GetTranslationsForClashes";
import { useInitBadgesCountersTabs } from "@application/hooks/tabs/useInitBadgesCountersTabs";
import type { ISessionFacultyClashesModel } from "@domain/model/clashes.model";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import { EMenuPath, ESieveOperator, type IK4Card, NoData, queryFilters, queryStringPagination, Services } from "@key4-front-library/core";
import { useContextModule, useNotificationContext } from "@key4-front-library/core/Contexts";
import { Stack } from "@mui/material";

const TabFacultyClashes = () => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { client, event } = useContextModule();
	const { fetchCounterActiveClashes } = useNotificationContext();
	const { participantId } = useParams();
	const translations = GetTranslationsForClashes();

	const { initBadgesCountersTabs } = useInitBadgesCountersTabs();

	const [clashes, setClashes] = useState<Array<ISessionFacultyClashesModel>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const actionsConfigurations = GetMenuActionsConfigurations();
	const getConfig = (type: MenuActionsReference) => actionsConfigurations.find((x) => x.reference === type);
	const detailsConfig = getConfig(MenuActionsReference.DETAILS);

	const menuItems = [
		{
			label: translations.buttons.details,
			icon: detailsConfig?.icon!,
			handleClick: (data: IK4Card) => {
				handleSessionClick(data);
			},
		},
	];

	const fetchClashes = useCallback(
		(clientId: string, eventId: string, _participantId: string, isOnlyActive: boolean) => {
			setIsLoading(true);
			Services.Events.Programme.FacultiesService.getFacultyClashes(clientId, eventId, _participantId, [
				...queryFilters(`ignoredDate${isOnlyActive ? ESieveOperator.EQUALS : ESieveOperator.NOT_EQUALS}null`),
				...queryStringPagination({
					page: 0,
					pageSize: 100,
				}),
			])
				.then((_response: any) => {
					const { data } = _response;
					setClashes(data);
					// we don't have a mechanism of websocket to maintain the counter of active clashes
					// in navbar up to date when we activate/desactivate a clash.
					// So we manually set this counter on each activate/desactivate action.
					fetchCounterActiveClashes();
					// set also the active clashes counter related to the faculty inside the tabs
					initBadgesCountersTabs();
				})
				.catch((_error: IErrorMessageReturn) => {
					enqueueSnackbar(_error.message, {
						variant: "error",
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[enqueueSnackbar, fetchCounterActiveClashes],
	);
	useEffect(() => {
		if (participantId) {
			fetchClashes(client.id, event.id, participantId, isOnlyActive);
		}
	}, [event, enqueueSnackbar, participantId, isOnlyActive, fetchClashes]);

	const handleIsOnlyActiveChange = () => {
		setIsOnlyActive((prev) => !prev);
	};

	const handleSessionClick = (session: IK4Card) => {
		navigate(["", client.key, event.key, EMenuPath.SESSIONS, session.id].join("/"));
	};

	const handleClashesUpdate = (clientId: string, eventId: string, clashId: string, comments: string, isIgnored: boolean, snackbarMessage?: string) => {
		Services.Events.Programme.ClashesService.patch(clientId, eventId, clashId, {
			comments,
			isIgnored,
		})
			.then(() => {
				if (participantId) {
					fetchClashes(client.id, event.id, participantId, isOnlyActive);
					enqueueSnackbar(snackbarMessage, {
						variant: "success",
					});
				}
			})
			.catch((_error: IErrorMessageReturn) => {
				enqueueSnackbar(_error.message, {
					variant: "error",
				});
			});
	};

	return (
		<AppBox>
			<Stack spacing={2}>
				<ClashesFilters isOnlyActive={isOnlyActive} OnIsOnlyActiveChange={handleIsOnlyActiveChange} isGroupButtons={false} />
				{isLoading ? (
					<ClashesSkeleton quantity={4} />
				) : (
					<Stack spacing={2}>
						{clashes && clashes.length > 0 ? (
							clashes.map((clash) => {
								return (
									<ClashesRender
										key={clash.id}
										clashes={ClashesFactory.TransformSessionClashesDataForReading({
											clash,
											client: client.key,
											event,
											translations,
										})}
										translations={translations}
										handleClashesUpdate={handleClashesUpdate}
										menuItems={menuItems}
										handleCardClick={handleSessionClick}
									/>
								);
							})
						) : (
							<NoData />
						)}
					</Stack>
				)}
			</Stack>
		</AppBox>
	);
};

export default TabFacultyClashes;
