import { CFEditorCustomField, CustomFieldDefinition, UsersPage } from "@key4-front-library/core";
import { Brands, LegalNotice, TermsOfSale } from "@key4-front-library/core/Bo/Components";
import AdvancedSettings from "@presentation/pages/advancedSettings/advancedSettings";
import AdvancedSettingsChairsTypes from "@presentation/pages/advancedSettings/chairsTypes/AdvancedSettingsChairsTypes";
import AdvancedSettingsClashes from "@presentation/pages/advancedSettings/clashes/AdvancedSetingsClashes";
import { AdvancedSettingsSpeakersTypes } from "@presentation/pages/advancedSettings/speakersTypes/AdvancedSettingsSpeakersTypes";
import { AdvancedSettingsTags } from "@presentation/pages/advancedSettings/tags/AdvancedSettingsTags";
import { Alerts } from "@presentation/pages/alerts/Alerts";
import AlertsAnomalies from "@presentation/pages/alerts/anomalies/AlertsAnomalies";
import AlertsClashes from "@presentation/pages/alerts/clashes/AlertsClashes";
import Dashboard from "@presentation/pages/dashboard/Dashboard";
import BulkActionFaculty from "@presentation/pages/faculties/BulkAction/BulkActionFaculty";
import { Faculties } from "@presentation/pages/faculties/Faculties";
import { FacultyCalendar } from "@presentation/pages/faculties/FacultiesCalendar";
import FacultyDetails from "@presentation/pages/faculties/FacultyDetails";
import TabFacultyClashes from "@presentation/pages/faculties/facultyDetails/clashes/TabFacultyClashes";
import { TabFacultyMails } from "@presentation/pages/faculties/facultyDetails/mails/TabFacultyMails";
import RoomDetails from "@presentation/pages/rooms/RoomDetails";
import { Rooms } from "@presentation/pages/rooms/Rooms";
import RoomAnomalies from "@presentation/pages/rooms/roomDetails/anomalies/RoomAnomalies";
import RoomClashes from "@presentation/pages/rooms/roomDetails/clashes/RoomClashes";
import { Scheduler } from "@presentation/pages/scheduler/Scheduler";
import BulkActionSession from "@presentation/pages/sessions/BulkAction/BulkActionSession";
import PageListSessions from "@presentation/pages/sessions/PageListSessions";
import { PageSessionDetails } from "@presentation/pages/sessions/SessionDetails/PageSessionDetails";
import SessionAnomalies from "@presentation/pages/sessions/SessionDetails/anomalies/SessionAnomalies";
import SessionClashes from "@presentation/pages/sessions/SessionDetails/clashes/SessionClashes";
import SessionComments from "@presentation/pages/sessions/SessionDetails/comments/SessionComments";
import SessionHistory from "@presentation/pages/sessions/SessionDetails/history/SessionHistory";
import { Settings } from "@presentation/pages/settings/Settings";
import SettingsAnomalies from "@presentation/pages/settings/anomalies/SettingsAnomalies";
import SettingsDocumentsManager from "@presentation/pages/settings/documentsManager/SettingsDocumentsManager";
import { TabEmailing } from "@presentation/pages/settings/emailing/TabEmailing";
import SettingsKeywordsManager from "@presentation/pages/settings/keywordsManager/SettingsKeywordsManager";
import SettingsNumbering from "@presentation/pages/settings/numbering/SettingsNumbering";
import TabSettingsProgrammeExport from "@presentation/pages/settings/programmeExport/TabSettingsProgrammeExport";
import SettingsScheduler from "@presentation/pages/settings/scheduler/SettingsScheduler";
import TabSettingsSessionTemplates from "@presentation/pages/settings/sessionTemplates/TabSettingsSessionTemplates";
import TabSessionsViewTemplateEditor from "@presentation/pages/settings/sessionsViewTemplateEditor/TabSessionsViewTemplateEditor";

const Pages = {
	Dashboard,
	Scheduler,
	Sessions: {
		_: PageListSessions,
		Anomalies: SessionAnomalies,
		Clashes: SessionClashes,
		Comments: SessionComments,
		Details: PageSessionDetails,
		History: SessionHistory,
	},
	Faculties: {
		_: Faculties,
		Details: FacultyDetails,
		Clashes: TabFacultyClashes,
		Mails: TabFacultyMails,
		Calendar: FacultyCalendar,
	},
	Rooms: {
		_: Rooms,
		Details: RoomDetails,
		Anomalies: RoomAnomalies,
		Clashes: RoomClashes,
	},
	Alerts: {
		_: Alerts,
		Anomalies: AlertsAnomalies,
		Clashes: AlertsClashes,
	},
	Settings: {
		_: Settings,
		Anomalies: SettingsAnomalies,
		Numbering: SettingsNumbering,
		Scheduler: SettingsScheduler,
		SessionTemplates: TabSettingsSessionTemplates,
		ProgrammeExport: TabSettingsProgrammeExport,
		SessionsViewTemplateEditor: TabSessionsViewTemplateEditor,
		KeywordsManager: SettingsKeywordsManager,
		DocumentsManager: SettingsDocumentsManager,
	},
	AdvancedSettings: {
		_: AdvancedSettings,
		Emailing: TabEmailing,
		Tags: AdvancedSettingsTags,
		Chairs: AdvancedSettingsChairsTypes,
		Speakers: AdvancedSettingsSpeakersTypes,
		Clashes: AdvancedSettingsClashes,
	},
	Users: { _: UsersPage },
	BulkActions: {
		Session: BulkActionSession,
		Faculty: BulkActionFaculty,
	},
	Mentions: {
		Brands,
		LegalNotice,
		TermsOfSale,
	},
	CustomFieldEditor: CFEditorCustomField,
};

export default Pages;

export const Providers = {
	CustomFields: {
		Definition: CustomFieldDefinition,
	},
};
