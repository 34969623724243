import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import type { TRoomDetails } from "@domain/interfaces/room.interface";
import type { IRoomForm } from "@domain/model/room.model";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Locales } from "@key4-front-library/core";
import { Checkbox, DialogActions, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";

type Props = {
	room: IRoomForm | TRoomDetails | undefined;
	handleSaveRoom: (room: IRoomForm, isCreateAnotherRoom: boolean) => void;
	handleModaleClose: () => void;
	translations: any;
};

const RoomEditionForm = (props: Props & dataCypressProp) => {
	const { room, handleSaveRoom, handleModaleClose, translations, dataCypressID } = props;
	const [isCreateAnotherRoom, setIsCreateAnotherRoom] = useState(false);

	const formLabels = {
		name: translations.editionModal.name,
		capacity: translations.capacity,
	};

	const Schema: any = yup.object({
		name: yup.string().required(Locales.Parsers.FormFieldRule.Required(formLabels.name)).max(125, Locales.Parsers.FormFieldRule.Max(125, formLabels.name)),
		capacity: yup
			.number()
			.min(1, Locales.Parsers.FormFieldRule.MinNumber(1, formLabels.capacity))
			.nullable()
			.transform((_, val) => (val === Number(val) ? val : null))
			.label(formLabels.capacity),
	});

	const formMethods = useForm<any>({
		resolver: yupResolver(Schema),
		defaultValues: room,
		mode: "onChange",
	});
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isValid, errors, isSubmitSuccessful },
	} = formMethods;

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset({ name: "", capacity: null });
		}
	}, [isSubmitSuccessful, reset, room]);

	const handleCheckboxClick = () => {
		setIsCreateAnotherRoom((prev) => !prev);
	};
	const onSubmit = (_room: IRoomForm) => handleSaveRoom(_room, isCreateAnotherRoom);

	return (
		<Stack spacing={3} mt={1}>
			<FormProvider {...formMethods}>
				<TextField
					id="name"
					{...register("name")}
					label={`${formLabels.name}*`}
					placeholder={formLabels.name}
					helperText={errors.name?.message?.toString()}
					error={!!errors.name}
					fullWidth
					inputProps={
						{
							"data-cy": dataCypressID ? `${dataCypressID}-text_field_name` : undefined,
						} as any
					}
				/>
				<TextField
					id="capacity"
					{...register("capacity", {
						valueAsNumber: true,
					})}
					type="number"
					label={formLabels.capacity}
					placeholder={formLabels.capacity}
					helperText={errors.capacity?.message?.toString()}
					error={!!errors.capacity}
					fullWidth
					inputProps={
						{
							"data-cy": dataCypressID ? `${dataCypressID}-text_field_capacity` : undefined,
						} as any
					}
				/>
			</FormProvider>
			{!room && (
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={isCreateAnotherRoom}
								onChange={handleCheckboxClick}
								inputProps={
									{
										"data-cy": dataCypressID ? `${dataCypressID}-checkbox` : undefined,
									} as any
								}
							/>
						}
						label={translations.editionModal.createAnother}
						data-cy={dataCypressID ? `${dataCypressID}-checkbox_and_label` : undefined}
					/>
				</FormGroup>
			)}

			<DialogActions>
				<ValidationFormButtons
					cancelButtonClick={handleModaleClose}
					saveButtonClick={handleSubmit(onSubmit)}
					disabledSaveButton={isSubmitting || !isValid}
					dataCypressID={dataCypressID}
				/>
			</DialogActions>
		</Stack>
	);
};

export default RoomEditionForm;
