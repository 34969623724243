import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import type { ISettingsSessionChairQuality } from "@domain/interfaces/settings.session-chair-quality.interface";
import type { IChairQualityModel } from "@domain/model/chairQuality.model";
import { yupResolver } from "@hookform/resolvers/yup";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { Locales } from "@key4-front-library/core";
import { Box, Grid, TextField } from "@mui/material";

type Props = {
	chairQuality: IChairQualityModel | null;
	editModaleClose: () => void;
	updateChairQuality: (data: ISettingsSessionChairQuality) => void;
};

const AdvancedSettingsChairQualityEdit = (props: Props) => {
	const { chairQuality, updateChairQuality: handleUpdateChairQuality, editModaleClose: handleEditModaleClose } = props;

	const formLabels = {
		label: t(`old.programme.advancedSettings.chairs.fields.label`),
	};

	const Schema: any = yup.object({
		label: yup.string().label(formLabels.label).required(Locales.Parsers.FormFieldRule.Required(formLabels.label)),
	});

	const {
		register,
		handleSubmit: onSubmit,
		formState: { isSubmitting, isValid, errors },
	} = useForm<any>({
		mode: "all",
		defaultValues: {
			label: chairQuality?.label,
		},
		resolver: yupResolver(Schema),
	});

	const handleSubmit = (_values: ISettingsSessionChairQuality) => {
		handleUpdateChairQuality(_values);
		handleEditModaleClose();
	};

	return (
		<Box component="form">
			<Grid container>
				<Grid item xs={12} sm={12}>
					<TextField
						id="label"
						aria-label="label"
						{...register("label")}
						label={formLabels.label}
						placeholder={formLabels.label}
						helperText={<>{errors.label?.message}</>}
						error={!!errors.label}
						margin="dense"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<ValidationFormButtons
						cancelButtonClick={handleEditModaleClose}
						saveButtonClick={onSubmit(handleSubmit)}
						disabledSaveButton={isSubmitting || !isValid}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AdvancedSettingsChairQualityEdit;
