import { useEffect, useState } from "react";

import RoomController from "@application/Controllers/RoomController";
import type { IRoom } from "@domain/model/room.model";
import { ErrorAPI, useContextModule } from "@key4-front-library/core";
import type { DtoEvent } from "@key4-front-library/core/Dto";

const useRooms = () => {
	const { client, event } = useContextModule();

	const [data, setData] = useState<Array<IRoom>>([]);
	const [error, setError] = useState<ErrorAPI | string | undefined>("");
	const [loading, setLoading] = useState<boolean | null>(null);

	useEffect(() => {
		const loadingData = async (_event: DtoEvent) => {
			setLoading(true);
			await RoomController.getListRooms({
				clientId: client.id,
				eventId: _event.id,
				sorts: ["order"],
			})
				.then((response: Array<IRoom> | ErrorAPI) => {
					if (response instanceof ErrorAPI) {
						setError(response);
					} else {
						setData(response);
					}
					setLoading(false);
				})
				.catch((_error) => {
					setError(_error);
					setLoading(false);
				});
		};

		loadingData(event);
	}, [event]);

	return { data, error, loading };
};

export default useRooms;
