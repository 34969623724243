import "@infrastructure/components/calendar/full-calendar.css";

import { t } from "i18next";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";

import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import { ESchedulerView, type ICalendarSession, type ICalendarSettings, type TRoomView } from "@domain/interfaces/calendar.interface";
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import K4CalendarEvent from "@infrastructure/components/calendar/K4CalendarEvent";
import { DEFAULT_TIMEZONE, Licence } from "@key4-front-library/core";
import { Stack } from "@mui/material";

import HeaderScheduler from "./HeaderScheduler";

type Props = {
	calendarSettings: ICalendarSettings;
	views: any;
	view: ESchedulerView;
	changeRoom?: (roomId: string) => void;
	changeRooms?: (roomId: Array<TRoomView>) => void;
	events: Array<ICalendarSession>;
	locale: string;
	roomId?: string | null;
	rooms?: Array<TRoomView> | null;
	isReadOnly?: boolean;
	handleSessionDrop?: (session: any) => void;
	handleResizeSession?: (session: any) => void;
	handleDragStop?: (session: any) => void;
	changeView?: (view: ESchedulerView) => void;
	getTitle?: (title: string) => void;
	handleEventClick?: (event: any) => void;
	select?: (arg: any) => void;
	expandScheduler?: () => void;
	isExpanded?: boolean;
};

const K4Calendar = ({
	calendarSettings,
	views,
	view,
	events,
	locale,
	roomId = null,
	rooms = null,
	isReadOnly = false,
	changeRoom = (_roomId: string) => {},
	changeRooms = (_newRooms: Array<TRoomView>) => {},
	handleSessionDrop = (_session: any) => {},
	handleResizeSession = (_session: any) => {},
	handleDragStop = (_session: any) => {},
	changeView = (_view: ESchedulerView) => {},
	getTitle = (_view: string) => {},
	handleEventClick = (_event: any) => {},
	select,
	expandScheduler = () => {},
	isExpanded = false,
}: Props) => {
	const calendarRef = useRef<any>(undefined);
	const [currentView, setCurrentView] = useState<ESchedulerView>(view);

	const hourStart = calendarSettings.hour.start.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
	const hourEnd = calendarSettings.hour.end.toUTC().toLocaleString(DateTime.TIME_24_WITH_SECONDS);

	const unplannedSessionsSection = document.getElementById("unplanned-sessions-section");
	const unplannedSessionsSectionDrop = document.getElementById("unplanned-sessions-section-drop");

	useEffect(() => {
		setCurrentView(view);
	}, [view]);

	useEffect(() => {
		if (calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			getTitle(calendarApi.view.title);
		}
	}, [calendarRef, getTitle, locale]);

	const handleRoomChange = (newRoomId: any) => {
		changeRoom(newRoomId);
	};

	const handleRoomsChange = (newRooms: Array<TRoomView>) => {
		changeRooms(newRooms);
	};

	const handleChangeViewClick = (view: ESchedulerView) => {
		changeView(view);
		const calendarApi = calendarRef.current.getApi();
		calendarApi.gotoDate(calendarSettings.date.start.toFormat("yyyy-MM-dd"));
		calendarApi.dispatch({
			type: "SET_OPTION",
			optionName: "duration",
			rawOptionValue: {
				days: calendarSettings.dateDurationDays,
			},
		});
	};

	return (
		<Stack id="full-calendar">
			{roomId && (
				<HeaderScheduler
					type={currentView}
					changeView={handleChangeViewClick}
					calendarSettings={calendarSettings}
					calendarRef={calendarRef}
					locale={locale}
					multiRoomProps={{
						rooms,
						changeRooms: handleRoomsChange,
					}}
					roomProps={{
						roomId,
						changeRoom: handleRoomChange,
					}}
					expandScheduler={expandScheduler}
					isExpanded={isExpanded}
				/>
			)}
			{/**
			 * (Second condition) If multi rooms without any room selected hide calendar
			 */}

			<Stack
				sx={{
					visibility: !(view === ESchedulerView.MULTIROOM && (rooms?.length === 0 || !rooms)) ? "visible" : "hidden",
				}}
			>
				<FullCalendar
					timeZone={DEFAULT_TIMEZONE}
					schedulerLicenseKey={Licence.full_calendar}
					/**
					 * Dynamic value
					 */
					ref={calendarRef}
					slotMinTime={hourStart}
					slotMaxTime={hourEnd === "00:00:00" ? "24:00:00" : hourEnd}
					locale={locale}
					initialView={currentView}
					events={events}
					contentHeight={"auto"}
					dayMinWidth={150}
					/**
					 * Set up start and end date
					 * initialDate = start date
					 * duration = diff days between start date and end date + 1
					 * (no end date settable you have to use duration)
					 */
					initialDate={calendarSettings.date.start.toJSDate()}
					duration={{
						days: calendarSettings.dateDurationDays,
					}}
					/**
					 * Application static values
					 */
					slotDuration={SchedulerConfiguration.schedulerConfiguration.slotDuration}
					slotLabelInterval={SchedulerConfiguration.schedulerConfiguration.slotLabelInterval}
					views={views}
					/**
					 * Boiler plate value
					 */
					plugins={[timeGridPlugin, luxon3Plugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
					headerToolbar={false}
					eventContent={(e) => {
						if (e.event._def.allDay && currentView === ESchedulerView.MULTIROOM) {
							e.isEndResizable = false;
						}

						return <K4CalendarEvent event={e.event} />;
					}}
					/**
					 * Drag & Drop
					 */
					// action on dragging session inside the calendar
					eventDrop={handleSessionDrop}
					// action on dragging unplanned session into the calendar
					eventReceive={handleSessionDrop}
					droppable={!isReadOnly}
					editable={!isReadOnly}
					eventResize={handleResizeSession}
					// eventDragStart & eventDragStop : add animation on unplanned section
					eventDragStart={() => {
						if (unplannedSessionsSection && unplannedSessionsSectionDrop) {
							unplannedSessionsSection.style.display = "none";
							unplannedSessionsSectionDrop.style.display = "flex";
						}
					}}
					eventDragStop={(session) => {
						if (unplannedSessionsSection && unplannedSessionsSectionDrop) {
							unplannedSessionsSection.style.display = "flex";
							unplannedSessionsSectionDrop.style.display = "none";
						}
						handleDragStop(session);
					}}
					// @ts-ignore
					resources={
						rooms && rooms.length > 0
							? rooms.map((room) => {
									return {
										id: room.id,
										title: room.title ?? "N/A",
									};
								})
							: undefined
					}
					// by default, resources are ordered by id and then by name
					resourceOrder={""}
					eventDidMount={(eventInfo) => {
						// Set the dbclick event
						eventInfo.el.addEventListener(
							"dblclick",
							() => {
								handleEventClick(eventInfo.event.id);
							},
							false,
						);
					}}
					allDayContent={t("old.programme.scheduler.full_day")}
					/* Select timeslot */
					selectable={select !== undefined}
					select={select}
				/>
			</Stack>
		</Stack>
	);
};

export default K4Calendar;
