import { t } from "i18next";
import { uniqueId } from "lodash";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import type { TypeProgrammeExportSection, TypeProgrammeExportTabsDefinition } from "@application/Types/ProgrammeExportType";
import { FormControlOld, IconOld } from "@key4-front-library/core";
import { Box, Grid, Stack, Tab, Tabs, Tooltip, Typography } from "@mui/material";

import Styles from "./ProgrammeExportTabsStyles";

type PropsProgrammeExportTabs = {
	tabsDefinitions: TypeProgrammeExportTabsDefinition;
	activeTabKey: string | null;
	onChangeTab: (key: string | null) => void;
	onAddSectionClick: () => void;
	onCloneSectionClick: (id: string) => void;
	onMoveLeftSectionClick: (id: string) => void;
	onMoveRightSectionClick: (id: string) => void;
	onRemoveSectionClick: (id: string) => void;
};

const ProgrammeExportTabs = (props: PropsProgrammeExportTabs) => {
	const {
		tabsDefinitions,
		onChangeTab,
		onAddSectionClick,
		onCloneSectionClick,
		onMoveLeftSectionClick,
		onMoveRightSectionClick,
		onRemoveSectionClick,
		activeTabKey,
	} = props;

	const sections: Array<TypeProgrammeExportSection> = [];
	for (const tab of tabsDefinitions.staticsTabs) {
		for (const section of tab.content.sections) {
			sections.push(section);
		}
	}
	for (const tab of tabsDefinitions.dynamicsTabs) {
		for (const section of tab.content.sections) {
			sections.push(section);
		}
	}

	const { control } = useFormContext();

	const translationNameSpace = "old.programme.programmeExport";
	const translationBaseKey: string = [translationNameSpace, "tabs"].join(".");
	const translations = {
		moveLeft: t([translationBaseKey, "actions", "moveLeft"].join(".")),
		moveRight: t([translationBaseKey, "actions", "moveRight"].join(".")),
		cloneSection: t([translationBaseKey, "actions", "cloneSection"].join(".")),
		deleteSection: t([translationBaseKey, "actions", "deleteSection"].join(".")),
	};

	const handleTabSectionChangeClick = (_: React.SyntheticEvent, key: string) => {
		onChangeTab(key);
	};

	const handleButtonSectionAddClick = () => {
		onAddSectionClick();
	};

	const handleButtonSectionCloneClick = (id: string) => {
		onCloneSectionClick(id);
	};

	const handleButtonSectionMoveLeftClick = (id: string) => {
		onMoveLeftSectionClick(id);
	};

	const handleButtonSectionMoveRightClick = (id: string) => {
		onMoveRightSectionClick(id);
	};

	const handleButtonSectionRemoveClick = (id: string) => {
		onRemoveSectionClick(id);
	};

	const renderSectionAsTab = useCallback((section: TypeProgrammeExportSection, index: number) => {
		const { id, formId, label, canEdit } = section;

		const limit = sections.length - 1;

		return (
			<Tab
				key={uniqueId(id)}
				value={id}
				sx={Styles.tab}
				label={
					<Controller
						control={control}
						name={formId}
						render={({ fieldState: { error } }) => {
							return (
								<Stack direction={"row"} alignItems={"center"} spacing={1}>
									{canEdit && index > 1 && (
										<Tooltip title={translations.moveLeft}>
											<Box
												onClick={(_event: React.SyntheticEvent) => {
													_event.stopPropagation();
													handleButtonSectionMoveLeftClick(id);
												}}
												sx={Styles.boxButton}
											>
												<IconOld iconPrefix={"fas"} iconName="chevron-left" iconSize={"xs"} styles={Styles.icon} />
											</Box>
										</Tooltip>
									)}

									<Typography sx={Styles.tabLabel} color={error ? "error" : undefined} variant="body1">
										{label}
									</Typography>

									{error && <IconOld color={"red"} prefix="fas" iconName="triangle-exclamation" size={"1x"} />}

									{canEdit && index < limit && (
										<Tooltip title={translations.moveRight}>
											<Box
												onClick={(_event: React.SyntheticEvent) => {
													_event.stopPropagation();
													handleButtonSectionMoveRightClick(id);
												}}
												sx={Styles.boxButton}
											>
												<IconOld iconPrefix={"fas"} iconName="chevron-right" iconSize={"xs"} styles={Styles.icon} />
											</Box>
										</Tooltip>
									)}

									{canEdit && (
										<Tooltip title={translations.cloneSection}>
											<Box
												onClick={(_event: React.SyntheticEvent) => {
													_event.stopPropagation();
													handleButtonSectionCloneClick(id);
												}}
												sx={Styles.boxButton}
											>
												<IconOld iconPrefix={"fas"} iconName="clone" iconSize={"xs"} styles={Styles.icon} />
											</Box>
										</Tooltip>
									)}

									{canEdit && (
										<Tooltip title={translations.deleteSection}>
											<Box
												onClick={(_event: React.SyntheticEvent) => {
													_event.stopPropagation();
													handleButtonSectionRemoveClick(id);
												}}
												sx={Styles.boxButton}
											>
												<IconOld iconPrefix={"fas"} iconName="xmark" iconSize={"xs"} styles={Styles.icon} />
											</Box>
										</Tooltip>
									)}
								</Stack>
							);
						}}
					/>
				}
			/>
		);
	}, []);

	const renderSectionAsTabFormControls = useCallback(
		(section: TypeProgrammeExportSection) => {
			const { id, formControls } = section;
			return (
				<Stack key={uniqueId(id)} sx={{ display: id === activeTabKey ? "flex" : "none" }}>
					<Grid container spacing={4} display={"flex"} alignItems={"flex-start"}>
						{formControls.map((formControl) => (
							<FormControlOld key={uniqueId("formControl")} {...formControl} />
						))}
					</Grid>
				</Stack>
			);
		},
		[activeTabKey],
	);

	return (
		<Stack>
			<Stack direction={"row"} alignItems={"center"}>
				<Tabs value={activeTabKey} onChange={handleTabSectionChangeClick} variant="scrollable" scrollButtons="auto" sx={Styles.tabs}>
					{sections.map(renderSectionAsTab)}
				</Tabs>
				<Box onClick={handleButtonSectionAddClick} sx={Styles.boxButtonAdd}>
					<IconOld iconPrefix={"far"} iconName="plus" iconSize={"xs"} styles={Styles.icon} />
				</Box>
			</Stack>
			<Stack py={3} direction={"column"}>
				{sections.map(renderSectionAsTabFormControls)}
			</Stack>
		</Stack>
	);
};

export default ProgrammeExportTabs;
