/**
 * K4Button est un composant destiné à remplacer le composant Button de MUI au sein du projet.
 *
 * Il permet de créer des boutons déjà stylisés pour correspondre à ceux qui sont les plus
 * récurrents dans les maquettes.
 *
 * Pour chaque style de bouton, il faut renseigner à minima le "label".
 */

// #region 'Imports'

import type { FontAwesomeIconString } from "@infrastructure/model/@types/icons/icon";
import { ApplicationColors, ThemeColors, useIdentity } from "@key4-front-library/core";
import { alpha, Button, type SxProps, type Theme, useTheme } from "@mui/material";

// #endregion 'Imports'

export enum EButtonColor {
	primary = "primary",
	secondary = "secondary",
	error = "error",
}

export enum EButtonVariant {
	outLinedGrey = "outlined-grey",
	containedAlt = "contained-alt",
	contained = "contained",
	outlined = "outlined",
	text = "text",
}

export enum EButtonSize {
	small = "small",
	medium = "medium",
	large = "large",
}

// #region 'Type declaration'
export interface IK4ButtonProps {
	label: string;
	color?: EButtonColor;
	disabled?: boolean;
	endIcon?: FontAwesomeIconString;
	startIcon?: FontAwesomeIconString;
	size?: EButtonSize;
	variant?: EButtonVariant.contained | EButtonVariant.outlined | EButtonVariant.text | EButtonVariant.containedAlt | EButtonVariant.outLinedGrey;
	sx?: SxProps<Theme>;
	dataCypressID?: string;
	handleClick?: () => void;
}

export interface IButtonBaseProps {
	label: string;
	dataCypressID: string;
	disabled?: boolean;
	onClick: () => void;
	size?: EButtonSize;
	sx?: SxProps<Theme>;
}

const K4Button = (props: IK4ButtonProps) => {
	const {
		label,
		color = EButtonColor.primary,
		disabled = false,
		endIcon,
		handleClick,
		size = EButtonSize.medium,
		startIcon,
		variant = EButtonVariant.contained,
		sx = { height: "auto" },
		dataCypressID,
	} = props;

	const theme: Theme = useTheme();
	const palette: any = theme.palette;
	const identity = useIdentity();

	const isPrimary = color === EButtonColor.secondary;
	const whiteColor = palette.common.white;
	const mainColor = getThemeColor().main;
	const primaryColor = palette.text.primary;
	const black = palette.common.black;

	function getThemeColor() {
		switch (color) {
			case EButtonColor.primary:
				return ThemeColors.primary;
			case EButtonColor.secondary:
				return ThemeColors.secondary;
			case EButtonColor.error:
				return ThemeColors.error;
			default:
				return ThemeColors.primary;
		}
	}

	function getStyle() {
		switch (variant) {
			case EButtonVariant.outlined:
				return isPrimary && identity.darkmode
					? {
							borderColor: whiteColor,
							color: whiteColor,
							"&:hover": {
								borderColor: whiteColor,
								color: whiteColor,
								bgcolor: alpha(whiteColor, 0.06),
							},
							"&:disabled": {
								borderColor: alpha(whiteColor, 0.1),
							},
							...sx,
						}
					: {
							borderColor: mainColor,
							color: mainColor,
							"&:hover": {
								borderColor: mainColor,
								color: mainColor,
								bgcolor: alpha(mainColor, 0.06),
							},
							"&:disabled": {
								borderColor: alpha(black, 0.1),
							},
							...sx,
						};
			case EButtonVariant.text:
				return isPrimary && identity.darkmode
					? {
							color: whiteColor,
							"&:hover": {
								color: whiteColor,
								bgcolor: alpha(whiteColor, 0.06),
							},
							...sx,
						}
					: {
							color: mainColor,
							"&:hover": {
								color: mainColor,
								bgcolor: alpha(mainColor, 0.06),
							},
							...sx,
						};
			case EButtonVariant.containedAlt:
				return {
					bgcolor: identity.darkmode ? ApplicationColors.darkGrey.main : whiteColor,
					color: alpha(primaryColor, identity.darkmode ? 1 : 0.87),
					"&:hover": {
						bgcolor: identity.darkmode ? ApplicationColors.darkGrey.light : whiteColor,
						color: identity.darkmode ? whiteColor : mainColor,
					},
					"&:disabled": {
						bgcolor: identity.darkmode ? ApplicationColors.darkGrey.dark : whiteColor,
						color: alpha(identity.darkmode ? whiteColor : black, 0.26),
						boxShadow: `0px 4px 14px ${alpha(ApplicationColors.veryDarkBlue.main, 0.12)}`,
					},
					...sx,
				};
			case EButtonVariant.outLinedGrey: {
				const border = alpha(primaryColor, identity.darkmode ? 0.5 : 0.25);
				const color = alpha(primaryColor, identity.darkmode ? 1 : 0.5);
				return {
					borderColor: border,
					color,
					"&:hover": {
						borderColor: border,
						color,
						bgcolor: alpha(primaryColor, 0.06),
					},
					...sx,
				};
			}
			case EButtonVariant.contained: {
				return { ...sx };
			}
			default:
				return {};
		}
	}

	return (
		<Button
			color={color}
			disabled={disabled}
			endIcon={endIcon}
			onClick={handleClick}
			size={size}
			startIcon={startIcon && <i className={`fal ${startIcon}`}></i>}
			variant={variant === EButtonVariant.outLinedGrey ? EButtonVariant.outlined : variant === EButtonVariant.containedAlt ? EButtonVariant.contained : variant}
			data-cy={dataCypressID}
			sx={getStyle()}
		>
			{label}
		</Button>
	);
};

export default K4Button;

export const ButtonSave = (props: IButtonBaseProps) => {
	return (
		<K4Button
			label={props.label}
			dataCypressID={props.dataCypressID}
			disabled={props.disabled}
			handleClick={props.onClick}
			size={props.size}
			sx={props.sx}
			// forced
			variant={EButtonVariant.contained}
			startIcon={"fa-save"}
			color={EButtonColor.secondary}
		/>
	);
};

export const ButtonCancel = (props: IButtonBaseProps) => {
	return (
		<K4Button
			label={props.label}
			dataCypressID={props.dataCypressID}
			disabled={props.disabled}
			handleClick={props.onClick}
			size={props.size}
			sx={props.sx}
			// forced
			variant={EButtonVariant.outLinedGrey}
			startIcon={"fa-xmark"}
		/>
	);
};

export const ButtonAdd = (props: IButtonBaseProps) => {
	return (
		<K4Button
			label={props.label}
			dataCypressID={props.dataCypressID}
			disabled={props.disabled}
			handleClick={props.onClick}
			size={props.size}
			sx={props.sx}
			// forced
			variant={EButtonVariant.contained}
			startIcon={"fa-plus"}
			color={EButtonColor.primary}
		/>
	);
};

export const ButtonEdit = (props: IButtonBaseProps) => {
	return (
		<K4Button
			label={props.label}
			dataCypressID={props.dataCypressID}
			disabled={props.disabled}
			handleClick={props.onClick}
			size={props.size}
			sx={props.sx}
			// forced
			variant={EButtonVariant.contained}
			startIcon={"fa-pencil"}
			color={EButtonColor.primary}
		/>
	);
};

export const ButtonDelete = (props: IButtonBaseProps) => {
	return (
		<K4Button
			label={props.label}
			dataCypressID={props.dataCypressID}
			disabled={props.disabled}
			handleClick={props.onClick}
			size={props.size}
			sx={props.sx}
			// forced
			variant={EButtonVariant.contained}
			startIcon={"fa-trash"}
			color={EButtonColor.error}
		/>
	);
};
