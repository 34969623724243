import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { ISessionView } from "@domain/interfaces/session.interface";
import { ErrorAPI, useContextModule } from "@key4-front-library/core";
import { Stack } from "@mui/material";

import { useSessionView } from "../../../Hooks/Session";
import RoomView from "./RoomView";
import ScheduleView from "./ScheduleView";

export const RoomAndScheduleView = (): React.ReactNode => {
	const [sessionView, setSessionView] = useState<ISessionView>();
	const { sessionId = "" } = useParams();
	const { event, client } = useContextModule();
	const sessionViewQuery = useSessionView(client.id, event.id, sessionId);

	useEffect(() => {
		if (sessionViewQuery.data && !(sessionViewQuery.data instanceof ErrorAPI)) {
			setSessionView(sessionViewQuery.data);
		}
	}, [sessionViewQuery]);

	if (!sessionView) return;

	return (
		<Stack spacing={2}>
			<RoomView session={sessionView.sessionDisplay} />
			<ScheduleView schedule={sessionView.schedule} session={sessionView.sessionDisplay} />
		</Stack>
	);
};
