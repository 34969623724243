import {
	faArrowLeftToLine,
	faBell,
	faBook,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarDays,
	faChalkboardUser,
	faChevronLeft,
	faChevronRight,
	faClipboardList,
	faEllipsisVertical,
	faEye,
	faEyeSlash,
	faFileLines,
	faGrid,
	faGripDotsVertical,
	faListOl,
	faPlus,
	faPodium,
	faRectangleHistoryCirclePlus,
	faSave,
	faScreenUsers,
	faSort,
	faTag,
	faTriangleExclamation,
	faUserCrown,
	faXmark,
} from "@fortawesome/pro-light-svg-icons";

const LightSet = {
	faArrowLeftToLine,
	faBell,
	faBook,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarDays,
	faChalkboardUser,
	faChevronLeft,
	faChevronRight,
	faClipboardList,
	faEllipsisVertical,
	faEye,
	faEyeSlash,
	faFileLines,
	faGrid,
	faGripDotsVertical,
	faListOl,
	faPlus,
	faPodium,
	faRectangleHistoryCirclePlus,
	faSave,
	faScreenUsers,
	faSort,
	faTag,
	faTriangleExclamation,
	faUserCrown,
	faXmark,
};

export default LightSet;
