import FormHelper from "@infrastructure/helpers/form.helper";
import { Chip, Stack, Typography } from "@mui/material";

export interface K4ChipColorProps {
	label: string;
	backgroundColor?: string;
	borderColor?: string;
	fontColor?: string;
	tagPropsAutocomplete?: any;
}

const K4ChipColor = (props: K4ChipColorProps) => {
	const {
		backgroundColor = FormHelper.DEFAULT_CHIP_BACKGROUND_COLOR,
		borderColor = FormHelper.DEFAULT_CHIP_BORDER_COLOR,
		fontColor = FormHelper.DEFAULT_CHIP_FONT_COLOR,
		label,
		tagPropsAutocomplete = {},
	} = props;
	return (
		<Chip
			sx={{
				backgroundColor,
				border: `2px solid ${borderColor}`,
			}}
			variant="filled"
			label={
				<Stack direction={"row"} alignItems={"center"}>
					<Typography sx={{ color: fontColor }} mx={".5rem"} variant="body2">
						{label}
					</Typography>
				</Stack>
			}
			{...tagPropsAutocomplete}
		/>
	);
};

export default K4ChipColor;
