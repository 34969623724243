import { useState } from "react";
import { HexColorPicker } from "react-colorful";

import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { Grid } from "@mui/material";

interface ColorPickerModalProps {
	color: string;
	onChangeColor: (color: string) => void;
	onCloseModal: () => void;
}

/**
 * A modal component for picking colors.
 */
const ColorPickerModal = (props: ColorPickerModalProps) => {
	const [selectedColor, setSelectedColor] = useState(props.color);

	const handleSave = () => {
		props.onChangeColor(selectedColor);
		props.onCloseModal();
	};

	return (
		<Grid container direction="column" alignItems="center">
			<Grid item xs={12} sm={12}>
				<HexColorPicker color={selectedColor} onChange={setSelectedColor} style={{ paddingTop: "16px" }} />
			</Grid>
			<Grid item xs={12} sm={12}>
				<ValidationFormButtons cancelButtonClick={props.onCloseModal} saveButtonClick={handleSave} disabledSaveButton={false} />
			</Grid>
		</Grid>
	);
};

export default ColorPickerModal;
