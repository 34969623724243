import type { TIcon } from "@infrastructure/model/@types/icons/icon";
import { ApplicationColors } from "@key4-front-library/core";
import type { SimplePaletteColorOptions } from "@mui/material";

type IIssueDefinition = {
	key: string;
	icon: string;
	themeColor: SimplePaletteColorOptions;
};

type IIssuesDefinitions = {
	clashes: IIssueDefinition;
	anomalies: IIssueDefinition;
};

const IssuesDefinitions: IIssuesDefinitions = {
	clashes: {
		key: "clashes",
		icon: "calendar-circle-exclamation",
		themeColor: ApplicationColors.red,
	},
	anomalies: {
		key: "anomalies",
		icon: "triangle-exclamation",
		themeColor: ApplicationColors.orange,
	},
};

export enum EIssues {
	CLASHES = "clashes",
	ANOMALIES = "anomalies",
}

type TIssuesIcon = {
	name: string;
	icon: TIcon;
};

export const issuesObject: {
	[key in EIssues]: TIssuesIcon;
} = {
	[EIssues.ANOMALIES]: {
		name: EIssues.ANOMALIES,
		icon: {
			name: "triangle-exclamation",
			prefix: "fas",
			color: ApplicationColors.orange.main,
		},
	},
	[EIssues.CLASHES]: {
		name: EIssues.CLASHES,
		icon: {
			name: "calendar-circle-exclamation",
			prefix: "fas",
			color: ApplicationColors.red.main,
		},
	},
};

export type IssuesDefinitionsType = keyof typeof IssuesDefinitions;

export default IssuesDefinitions;
