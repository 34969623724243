import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import DashboardFactory from "@application/factory/dashboard/DashboardFactory";
import type { IIndicator } from "@infrastructure/model/interfaces/indicators/indicator.interface";
import { Services, useContextModule } from "@key4-front-library/core";

import DashboardIndicator from "../DashboardIndicator";

const DashboardProgrammeIndicator = () => {
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [indicators, setIndicators] = useState<Array<IIndicator>>([]);

	const fetchData = useCallback(async (clientId: string, eventId: string) => {
		setIsLoading(true);
		await Services.Events.Programme.DashboardService.getProgramme(clientId, eventId)
			.then((data) => {
				setIndicators(DashboardFactory.PopulateProgrammeIndicators(data));
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(() => {
		fetchData(client.id, event.id);
	}, [client.id, event.id]);

	return <DashboardIndicator title={t("old.programme.dashboard.indicators.programme.title")} indicators={indicators} isLoading={isLoading} />;
};

export default DashboardProgrammeIndicator;
