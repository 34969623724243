export enum EQuickSearchReducerAction {
	SELECT = 0,
	CLEAR = 1,
	CLEAR_ALL = 2,
}

type TQuickSearchReducerAction =
	| {
			type: EQuickSearchReducerAction.SELECT;
			id: string | undefined;
			searchKey: string | undefined;
			selection: string[] | undefined;
	  }
	| {
			type: EQuickSearchReducerAction.CLEAR;
			id: string | undefined;
	  }
	| {
			type: EQuickSearchReducerAction.CLEAR_ALL;
	  };

export default TQuickSearchReducerAction;
