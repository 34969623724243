import { EnumChairFieldKey, EnumChairFormKey, EnumChairSectionKey } from "@application/Enums/ChairEnum";
import ChairDataGridHelper from "@application/helpers/chairDatagrid.helper";
import type { TypeChairStaticField } from "@application/Types/ChairType";
import {
	type DtoChairWrite,
	EnumFormControlKind,
	EnumParticipantStatusInvitation,
	getCellBulletColorWithLabel,
	getCellButtonsList,
	sortPersonComparator,
	statusInvitationArray,
	statusInvitationParticipantObject,
} from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const defaultModel: DtoChairWrite = {
	participantId: "",
	invitationStatus: EnumParticipantStatusInvitation.UNSENT,
	customFieldValues: {},
	replacementEmail: null,
	replacementFirstname: null,
	replacementLastname: null,
	replacementComment: null,
	qualityIds: null,
};

const datagridHeaders: Array<GridColDef> = [
	{
		field: "chair",
		minWidth: 300,
		flex: 3,
		disableColumnMenu: true,
		renderCell: ChairDataGridHelper.renderChairCell,
		sortComparator: sortPersonComparator,
	},
	{ field: "invitationType", width: 150, flex: 2 },
	{
		field: "status",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 150,
		flex: 2,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;

			return labelA.localeCompare(labelB);
		},
	},
	{
		field: "actions",
		minWidth: 150,
		align: "center",
		sortable: false,
		flex: 1,
		disableColumnMenu: true,
		renderCell: getCellButtonsList,
	},
];

const staticListFormControlsObject: {
	[key in EnumChairFormKey]: {
		[key in EnumChairSectionKey]?: TypeChairStaticField;
	};
} = {
	[EnumChairFormKey.GENERAL]: {
		[EnumChairSectionKey.MAIN]: {
			[EnumChairFieldKey.PARTICIPANT_OR_CONTACT]: {
				value: null,
				component: {
					id: EnumChairFieldKey.PARTICIPANT_OR_CONTACT,
					kind: EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT,
					propsComponent: {
						required: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},

			[EnumChairFieldKey.QUALITY]: {
				value: null,
				component: {
					id: EnumChairFieldKey.QUALITY,
					kind: EnumFormControlKind.SELECT,
					propsComponent: {
						label: `old.programme.sessionDetails.chairs.form.fields.${EnumChairFieldKey.QUALITY}`,
						items: [],
						isNullable: true,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},

			[EnumChairFieldKey.INVITATION]: {
				value: EnumParticipantStatusInvitation.UNSENT,
				component: {
					id: EnumChairFieldKey.INVITATION,
					kind: EnumFormControlKind.SELECT_BULLET_COLOR,
					propsComponent: {
						label: "old.programme.sessionDetails.chairs.form.fields.invitationStatus",
						items: statusInvitationArray.map((statusEnum: EnumParticipantStatusInvitation) => {
							return {
								key: statusInvitationParticipantObject[statusEnum].value,
								label: statusInvitationParticipantObject[statusEnum].label,
								color: statusInvitationParticipantObject[statusEnum].bgColor,
							};
						}),
						isNullable: false,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
		},
	},

	[EnumChairFormKey.REPLACEMENT]: {
		[EnumChairSectionKey.MAIN]: {
			[EnumChairFieldKey.EMAIL]: {
				value: null,
				component: {
					id: EnumChairFieldKey.EMAIL,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.chairs.form.fields.email",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumChairFieldKey.FIRSTNAME]: {
				value: null,
				component: {
					id: EnumChairFieldKey.FIRSTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.chairs.form.fields.firstname",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumChairFieldKey.LASTNAME]: {
				value: null,
				component: {
					id: EnumChairFieldKey.LASTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.chairs.form.fields.lastname",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumChairFieldKey.COMMENTS]: {
				value: null,
				component: {
					id: EnumChairFieldKey.COMMENTS,
					kind: EnumFormControlKind.TEXT_AREA,
					propsComponent: {
						label: "old.programme.sessionDetails.chairs.form.fields.comment",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
		},
	},
	[EnumChairFormKey.DETAILS]: {
		[EnumChairSectionKey.MAIN]: {},
	},
};

const ChairConfiguration = {
	defaultModel,
	staticListFormControlsObject,
	datagridHeaders,
};

export default ChairConfiguration;
