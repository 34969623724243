import { Typography } from "@mui/material";

type Props = {
	label: string;
};

const FieldLabel = (props: Props) => {
	const { label } = props;
	return (
		<Typography variant="subtitle2" color="text.disabled" sx={{ pb: 0.375 }}>
			{label}
		</Typography>
	);
};

export default FieldLabel;
