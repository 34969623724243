import ControllersApp from "@application/Controllers/ControllersApp";
import { EnumChairFormKey } from "@application/Enums/ChairEnum";
import HelpersApp from "@application/helpers";
import { CustomFieldHelper, FormHelper, queryFilters, useContextModule, UseFormHelper } from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import type { DtoSessionGetSpeaker, SessionTemplatesRead } from "@key4-front-library/core/Dto";
import { EnumCustomFieldScope, ESieveOperator } from "@key4-front-library/core/Enums";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readWholeFormByScope } = ControllersBo.CustomFieldController.useEntity();
	const { readListSpeakerQualities } = ControllersApp.SpeakerController.useEntity();
	const { client, event } = useContextModule();
	const { read: readSession } = ControllersApp.SessionController.useEntity();

	const readWholeForm = (sessionId: string, speaker?: DtoSessionGetSpeaker) => {
		const { data, isFetching, refetch, isFetchedAfterMount } = useQuery({
			queryKey: ["speaker", "form", speaker?.participantId ? "edit" : "create"],
			queryFn: async (): Promise<{
				componentData: PropsFormTabs;
				useFormData: TypeUseFormListForms;
			}> => {
				let formsData = await readWholeFormByScope(EnumCustomFieldScope.SPEAKER);
				const qualities = await readListSpeakerQualities();
				const useFormData: TypeUseFormListForms = {};
				const componentData: PropsFormTabs = {
					tabs: [],
				};

				// CREATE SPEAKER
				// If the modal is in creation mode, we don't want to show the "replacement" tab
				if (!speaker?.participantId) {
					formsData = formsData.filter((tab) => {
						return tab.data.key !== EnumChairFormKey.REPLACEMENT;
					});
				}

				// #region 'Useform default values'
				// Add Forms / Sections useFormData
				UseFormHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);

				// Add Static fields useFormData
				HelpersApp.SpeakerHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData, qualities.length > 0);

				// Add Custom fields useFormData
				CustomFieldHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);
				// #endregion 'Useform default values'

				// #region 'Component data'
				// Add tabs and sections props
				FormHelper.mapFormTabsProps(formsData, componentData);

				// Add static fields props
				HelpersApp.SpeakerHelper.mapFormTabsPropsStaticFields(formsData, componentData, qualities, speaker?.participantId);

				// Add custom fields props
				CustomFieldHelper.mapFormTabsPropsFields(formsData, componentData);
				// #endregion 'Component data'

				// #region 'Add default values in fields'
				// Create Speaker
				if (!speaker?.participantId) {
					// Get the sessionTemplate if there is one
					const session = await readSession(sessionId);

					const tagId = session.tagTypes?.[0]?.tags?.[0]?.id;

					let sessionTemplatesAssociatedToTheSessionPrimaryTag: Array<SessionTemplatesRead> = [];
					if (tagId) {
						sessionTemplatesAssociatedToTheSessionPrimaryTag = await ControllersBo.SessionTemplatesController.getSessionTemplatesList(client.id, event.id, [
							...queryFilters(`primaryTagId${ESieveOperator.EQUALS}${tagId}`),
						]);
					}
					HelpersApp.SpeakerHelper.mapFormsTabsCreateSpeakerDefaultValues(useFormData, sessionTemplatesAssociatedToTheSessionPrimaryTag[0], qualities);
				}
				// Edit speaker
				else {
					// Add Static fields useFormData
					HelpersApp.SpeakerHelper.mapFormTabsEditUseFormDefaultValue(useFormData, speaker);
					// Add Custom fields useFormData
					CustomFieldHelper.mapFormTabsEditUseFormDefaultValue(useFormData, speaker.customFieldValues);
				}

				return { componentData, useFormData };
			},
		});

		return { data, isFetching, refetch, isFetchedAfterMount };
	};

	return { readWholeForm };
};

const SpeakerHook = {
	useEntity,
};

export default SpeakerHook;
