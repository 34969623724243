import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import GetBarChartOptions from "@infrastructure/components/charts/Area/barchar-options.configuration";
import type { IChartSerie } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import ChartService from "@infrastructure/services/chart/chart.service";
import { Box } from "@mui/material";

type Props = {
	series: IChartSerie[];
	dataCypressID?: string;
};
const BarChart = (props: Props) => {
	const { series, dataCypressID } = props;

	const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
	const [categories, setCategories] = useState<string[]>([]);

	const options = GetBarChartOptions({
		categories,
	});

	useEffect(() => {
		const _chartSeries: ApexAxisChartSeries = [];
		series.forEach((serie) => {
			_chartSeries.push({
				name: `${serie.name}`,
				data: serie.data.map((x) => x.value),
			});
		});
		setChartSeries(_chartSeries);
		setCategories(ChartService.GetAllAvailableKeyInSeriesSet({ series }));
	}, [series, setChartSeries]);

	return (
		<Box style={{ width: "95%", maxHeight: "250px", overflow: "hidden" }}>
			<Chart
				type="bar"
				options={options}
				series={chartSeries}
				width={"95%"}
				height={"250px"}
				data-cy={dataCypressID ? `${dataCypressID}-content` : undefined}
			/>
		</Box>
	);
};

export default BarChart;
