import SessionController from "@application/Controllers/SessionController";
import type { ISessionView } from "@domain/interfaces/session.interface";
import { Cultures, type DtoToken, type ErrorAPI, QUERY_KEYS_PROGRAMME, queryStringSorts, Services, useAuthenticationContext } from "@key4-front-library/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

export const useSessionToken = (clientId: string, eventId: string, sessionId: string): UseQueryResult<Array<DtoToken>> =>
	useQuery({
		queryKey: [QUERY_KEYS_PROGRAMME.sessions, "Token", clientId, eventId, sessionId],
		queryFn: async () => await Services.Events.Programme.TokenService.getSessionTokenList(clientId, eventId, sessionId, [...queryStringSorts(["-expireAt"])]),
	});

export const useSessionView = (clientId: string, eventId: string, sessionId: string): UseQueryResult<ISessionView | ErrorAPI> => {
	const { user } = useAuthenticationContext();

	return useQuery({
		queryKey: [QUERY_KEYS_PROGRAMME.sessions, "GetById", clientId, eventId, sessionId],
		queryFn: async () => {
			return await SessionController.getSessionDetails(clientId, eventId, sessionId, Cultures[user.locale].locale);
		},
	});
};
