import { getCellButtonsList, getCellChipColor } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const tagsDatagridHeaders: GridColDef[] = [
	{ field: "id" },
	{
		field: "label",
		renderCell: getCellChipColor,
		minWidth: 400,
		flex: 10,
		disableColumnMenu: true,
		hideSortIcons: true,
	},
	{
		field: "actionButtons",
		renderCell: getCellButtonsList,
		width: 110,
		minWidth: 180,
		flex: 1,
		hideSortIcons: true,
		disableColumnMenu: true,
		sortable: false,
		resizable: false,
	},
];

const tabsAdvancedSettings = {
	tagsDatagridHeaders,
};

export default tabsAdvancedSettings;
