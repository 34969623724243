import {
	faAddressCard,
	faArrowDownLeftAndArrowUpRightToCenter,
	faArrowRight,
	faArrowsRepeat,
	faArrowUpRightAndArrowDownLeftFromCenter,
	faArrowUpRightFromSquare,
	faBadgeCheck,
	faBed,
	faBell,
	faBook,
	faBooks,
	faBrakeWarning,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarDay,
	faCalendarDays,
	faCalendarLinesPen,
	faCalendarPen,
	faChalkboardUser,
	faChartLineUp,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCircleArrowLeft,
	faCircleArrowRight,
	faCircleChevronDown,
	faCircleExclamation,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faCircleXmark,
	faClipboardList,
	faClone,
	faDatabase,
	faDownLong,
	faEarthEurope,
	faEllipsisVertical,
	faEnvelope,
	faEnvelopesBulk,
	faFileChartColumn,
	faFileExport,
	faFileLines,
	faFileMagnifyingGlass,
	faFileXmark,
	faFilter,
	faFloppyDisk,
	faGear,
	faGift,
	faGlobe,
	faGripDotsVertical,
	faHandshake,
	faHeadset,
	faHeartCircleBolt,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faLink,
	faListCheck,
	faListOl,
	faMagnifyingGlass,
	faMailBulk,
	faMessageLines,
	faMessages,
	faMinus,
	faMobileButton,
	faPalette,
	faPaperPlane,
	faPaste,
	faPencil,
	faPenToSquare,
	faPersonCircleCheck,
	faPlus,
	faPodium,
	faScreenUsers,
	faScrewdriverWrench,
	faSignalStream,
	faStore,
	faTableList,
	faTag,
	faTrash,
	faTrashCan,
	faTriangleExclamation,
	faUpLong,
	faUpRightFromSquare,
	faUser,
	faUserCrown,
	faUserGroup,
	faUsers,
	faUsersCrown,
	faUsersRectangle,
	faUserTie,
	faXmark,
} from "@fortawesome/pro-regular-svg-icons";

const RegularSet = {
	faHeartCircleBolt,
	faAddressCard,
	faArrowDownLeftAndArrowUpRightToCenter,
	faArrowRight,
	faArrowUpRightAndArrowDownLeftFromCenter,
	faArrowUpRightFromSquare,
	faBadgeCheck,
	faBed,
	faBell,
	faBook,
	faBooks,
	faBrakeWarning,
	faCalendarCircleExclamation,
	faCalendarCirclePlus,
	faCalendarDay,
	faCalendarDays,
	faCalendarLinesPen,
	faCalendarPen,
	faChalkboardUser,
	faChartLineUp,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faCircleArrowLeft,
	faCircleArrowRight,
	faCircleChevronDown,
	faCircleExclamation,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faCircleXmark,
	faClipboardList,
	faClone,
	faDatabase,
	faDownLong,
	faEarthEurope,
	faEllipsisVertical,
	faEnvelope,
	faEnvelopesBulk,
	faFileChartColumn,
	faFileExport,
	faFileLines,
	faFileMagnifyingGlass,
	faFileXmark,
	faFilter,
	faFloppyDisk,
	faGear,
	faGift,
	faGlobe,
	faGripDotsVertical,
	faHandshake,
	faHeadset,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faLink,
	faListCheck,
	faListOl,
	faMagnifyingGlass,
	faMailBulk,
	faMessages,
	faMessageLines,
	faMinus,
	faMobileButton,
	faPalette,
	faPaperPlane,
	faPaste,
	faPencil,
	faPenToSquare,
	faPersonCircleCheck,
	faPlus,
	faPodium,
	faScreenUsers,
	faScrewdriverWrench,
	faSignalStream,
	faStore,
	faTableList,
	faTag,
	faTrash,
	faTrashCan,
	faTriangleExclamation,
	faUpLong,
	faUpRightFromSquare,
	faUser,
	faUserCrown,
	faUsers,
	faUserGroup,
	faUsersCrown,
	faUsersRectangle,
	faUserTie,
	faXmark,
	faArrowsRepeat,
};

export default RegularSet;
