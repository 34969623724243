type IIndicatorDefinition = {
	key: string;
	icon: string;
};

type IIndicatorsDefinitions = {
	sessions: IIndicatorDefinition;
	rooms: IIndicatorDefinition;
	presentations: IIndicatorDefinition;
	invitations: IIndicatorDefinition;
	faculties: IIndicatorDefinition;
	chairs: IIndicatorDefinition;
	speakers: IIndicatorDefinition;
	clashes: IIndicatorDefinition;
	anomalies: IIndicatorDefinition;
};

const IndicatorsDefinitions: IIndicatorsDefinitions = {
	sessions: {
		key: "sessions",
		icon: "clipboard-list",
	},
	rooms: {
		key: "rooms",
		icon: "screen-users",
	},
	presentations: {
		key: "presentations",
		icon: "podium",
	},
	invitations: {
		key: "invitations",
		icon: "envelopes-bulk",
	},
	faculties: {
		key: "faculties",
		icon: "user-group",
	},
	chairs: {
		key: "chairs",
		icon: "user-crown",
	},
	speakers: {
		key: "speakers",
		icon: "chalkboard-user",
	},
	clashes: {
		key: "clashes",
		icon: "calendar-circle-exclamation",
	},
	anomalies: {
		key: "anomalies",
		icon: "triangle-exclamation",
	},
};

export type IndicatorsDefinitionsType = keyof typeof IndicatorsDefinitions;

export default IndicatorsDefinitions;
