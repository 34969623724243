import { LinkWithCopy } from "@key4-front-library/core";
import { Grid, type GridSize, Typography } from "@mui/material";

type IconCardItemProps = {
	label: string;
	value?: string;
	gridSize?: { xs: GridSize; md: GridSize; lg: GridSize };
	isValueALink?: boolean;
};
const IconCardItem = (props: IconCardItemProps) => {
	const { label, value, gridSize = { xs: 12, md: 6, lg: 4 }, isValueALink = false } = props;

	return (
		<Grid item {...gridSize}>
			<Typography variant={"body2"} color={"text.secondary"}>
				{label}
			</Typography>
			{isValueALink && value ? (
				<LinkWithCopy text={value} path={value} isCompletePath={true} textVariant={"body2"} isDisplayTextInUpperCase={false} />
			) : (
				<Typography>{value ?? "-"}</Typography>
			)}
		</Grid>
	);
};

export default IconCardItem;
