import { t } from "i18next";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { ApplicationColors } from "@key4-front-library/core";
import { Box, Grid, TextField } from "@mui/material";

import ColorPickerModal from "./ColorPickerModal";

type Props = {
	name: string;
	label: string;
	color: string;
	onChange: (color: string) => void;
};

const ColorPicker = (props: Props) => {
	const { name, label, color, onChange } = props;

	const formContext = useFormContext();

	const {
		register,
		setValue,
		formState: { errors },
	} = formContext;

	const styles = {
		colorWatch: {
			p: 3,
			width: 0,
			height: 0,
			border: 1,
			borderRadius: 1,
			borderColor: ApplicationColors.grey.light,
			cursor: "pointer",
		},
	};

	const [currentColor, setCurrentColor] = useState<string>(color);

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const onChangeColor = (_color: string) => {
		onChange(_color);
		setValue(name, _color);
		setCurrentColor(_color);
	};

	const handleColorChange = (event: any) => {
		onChange(event.target.value);
		setValue(name, event.target.value);
		setCurrentColor(event.target.value);
	};

	const handleOpenModale = () => {
		setModaleOpenState(true);
	};
	const handleCloseModale = () => {
		setModaleOpenState(false);
	};

	return (
		<>
			<Grid container direction={"row"} alignItems={"center"}>
				<Grid item xs={2} md={2} sm={3} display={"flex"} justifyContent={"center"}>
					<Box style={{ backgroundColor: currentColor }} onClick={handleOpenModale} sx={styles.colorWatch} />
				</Grid>
				<Grid item xs={10} md={10} sm={9}>
					<TextField
						{...register(name)}
						onChange={handleColorChange}
						value={currentColor}
						label={label}
						placeholder={label}
						error={!!errors[name]}
						helperText={`${errors[name]?.message ? errors[name]?.message : ""}`}
						margin="dense"
						fullWidth
					/>
				</Grid>
			</Grid>

			<DefaultModal open={modaleOpenState} title={t("old.programme.advancedSettings.tags.modale.chooseColor")} maxWidth={"xs"}>
				<ColorPickerModal color={currentColor} onChangeColor={onChangeColor} onCloseModal={handleCloseModale} />
			</DefaultModal>
		</>
	);
};

export default ColorPicker;
