//#region 'Imports'

import { t } from "i18next";

import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";

//#endregion 'Imports'

//#region 'GetMenuItemsTranslations'
type GetMenuItemsTranslationsProps = {
	menuItems: IMenuItems;
};
const GetMenuItemsTranslations = (props: GetMenuItemsTranslationsProps): IMenuItems => {
	const { menuItems } = props;
	menuItems.map((menuItem) => {
		menuItem.name = t(`old.programme.navigation.tabs.${menuItem.reference}`) ?? menuItem.name;
		return menuItem;
	});
	return menuItems;
};
//#endregion 'GetMenuItemsTranslations'

const NavigationFactory = {
	GetMenuItemsTranslations,
};
export default NavigationFactory;
