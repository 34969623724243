import { Gravatar } from "@key4-front-library/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Stack } from "@mui/material";

type Props = {
	isDraggableIcon: boolean | undefined;
	isGravatar?: boolean;
	email?: string;
	firstname?: string;
	lastname?: string;
};

const DragIconAndGravatar = (props: Props) => {
	const { isDraggableIcon, email, firstname, lastname } = props;

	return (
		<Stack mr={1.5} pt="8px" direction={"row"}>
			{isDraggableIcon && (
				<Box mr={0.5}>
					<DragIndicatorIcon />
				</Box>
			)}
			<Box>
				<Gravatar email={email} firstname={firstname} lastname={lastname} size={40} />
			</Box>
		</Stack>
	);
};

export default DragIconAndGravatar;
