import { ApplicationColors } from "@key4-front-library/core";

const schedulerConfiguration = {
	slotDuration: { minutes: 15 },
	slotLabelInterval: { hours: 1 },
	dayHeaderFormat: { weekday: "long", day: "numeric" },
	initialView: "timeGridWeek",
};

const roomScheduler = {
	type: "timeGrid",
	slotLabelFormat: {
		hour: "numeric",
		minute: "2-digit",
		meridiem: false,
		hour12: false,
	},
	dayHeaderFormat: {
		weekday: "long",
		day: "numeric",
	},
	titleFormat: {
		day: "numeric",
		month: "long",
		year: "numeric",
	},
};

const multiRoomScheduler = {
	type: "resourceTimeGridDay",
	slotLabelFormat: {
		hour: "numeric",
		minute: "2-digit",
		meridiem: false,
		hour12: false,
	},
	dayHeaderFormat: {
		weekday: "long",
		day: "numeric",
	},
	titleFormat: {
		day: "numeric",
		month: "long",
		year: "numeric",
	},
};

const defaultSessionColor = {
	backgroundColor: ApplicationColors.greyStoneLight.main,
	borderColor: ApplicationColors.greyStoneLight.main,
	textColor: ApplicationColors.black.main,
};

const defaultMinDuration = 15;

const SchedulerConfiguration = {
	schedulerConfiguration,
	roomScheduler,
	multiRoomScheduler,
	defaultSessionColor,
	defaultMinDuration,
};
export default SchedulerConfiguration;
