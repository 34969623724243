import { ActionReference, type DtoConvocation } from "@key4-front-library/core";
import type { PropsButtonIcon } from "@key4-front-library/core/Bo/Components/Button/ButtonIcon";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import { EnumActionsReference } from "@key4-front-library/core/Enums";

/**
 * Get ActionButtons for a Cell
 * @param convocation Current ConvocationID
 * @param actions list of buttons's actions
 * @returns Cell buttons
 * @typedef PropsDataGridCellButtons Type of Cell Buttons
 */
const getGridActionButtons = (
	convocation: DtoConvocation,
	actions: {
		details: (convocationId: string) => void;
		delete: (convocationId: string) => void;
	},
): PropsDataGridCellButtons => {
	return {
		listButtons: [
			{
				...ActionReference[EnumActionsReference.DETAILS],
				onClick: () => {
					actions.details(convocation.id);
				},
			},
			{
				...ActionReference[EnumActionsReference.DELETE],
				onClick: () => {
					actions.delete(convocation.id);
				},
			},
		] as Array<PropsButtonIcon>,
	};
};

const ConvocationHelper = {
	getGridActionButtons,
};

export default ConvocationHelper;
