import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import type { ISettingsSessionTag } from "@domain/interfaces/settings.session-tag.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import ColorPicker from "@infrastructure/components/forms/ColorPicker/ColorPicker";
import ValidationFormButtons from "@infrastructure/components/interface/forms/ValidationFormButtons";
import { ApplicationColors, ChipColor, HEXADECIMAL_COLOR_REGEX, type ITagModel, Locales } from "@key4-front-library/core";
import { Box, Grid, TextField } from "@mui/material";

type Props = {
	tag: ITagModel | null;
	updateTag: (data: ISettingsSessionTag) => void;
	editModaleClose: () => void;
};

const AdvancedSettingsSessionTagEditModal = (props: Props) => {
	const defaultColorPickerColor = ApplicationColors.black.main;
	const defaultColorPickerBackGroundColor = ApplicationColors.greyStoneLight.main;

	const { tag, updateTag: handleUpdateTag, editModaleClose: handleEditModaleClose } = props;

	const [backgroundColor, setBackgroundColor] = useState<string>(tag?.backgroundColor || defaultColorPickerBackGroundColor);
	const [fontColor, setFontColor] = useState<string>(tag?.fontColor || defaultColorPickerColor);
	const [borderColor, setBorderColor] = useState<string>(tag?.borderColor || defaultColorPickerBackGroundColor);
	const [label, setLabel] = useState<string>(tag?.label ?? "Label");

	const formLabels = {
		label: t("old.programme.advancedSettings.tags.modale.edit.fields.label"),
		backgroundColor: t("old.programme.advancedSettings.tags.modale.edit.fields.backgroundColor"),
		fontColor: t("old.programme.advancedSettings.tags.modale.edit.fields.fontColor"),
		borderColor: t("old.programme.advancedSettings.tags.modale.edit.fields.borderColor"),
		createAnother: t("old.programme.advancedSettings.tags.modale.edit.fields.createAnother"),
	};

	const Schema: any = yup.object({
		label: yup.string().label(formLabels.label).required(Locales.Parsers.FormFieldRule.Required(formLabels.label)),
		backgroundColor: yup
			.string()
			.label(formLabels.backgroundColor)
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.backgroundColor))
			.matches(HEXADECIMAL_COLOR_REGEX, Locales.Parsers.FormFieldRule.HexColor(formLabels.borderColor)),
		fontColor: yup
			.string()
			.label(formLabels.fontColor)
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.fontColor))
			.matches(HEXADECIMAL_COLOR_REGEX, Locales.Parsers.FormFieldRule.HexColor(formLabels.borderColor)),
		borderColor: yup
			.string()
			.label(formLabels.borderColor)
			.required(Locales.Parsers.FormFieldRule.Required(formLabels.borderColor))
			.matches(HEXADECIMAL_COLOR_REGEX, Locales.Parsers.FormFieldRule.HexColor(formLabels.borderColor)),
		createAnother: yup.boolean().label(formLabels.createAnother),
	});

	const formMethods = useForm<any>({
		mode: "all",
		defaultValues: {
			label: tag?.label,
			backgroundColor,
			fontColor,
			borderColor,
		},
		resolver: yupResolver(Schema),
	});

	const {
		register,
		handleSubmit: onSubmit,
		formState: { isSubmitting, isValid, errors },
	} = formMethods;

	const handleSubmit = (_values: ISettingsSessionTag) => {
		handleUpdateTag(_values);
	};

	return (
		<FormProvider {...formMethods}>
			<Box component="form">
				<Grid container>
					<Grid item xs={12} sm={12}>
						<TextField
							id="label"
							aria-label="label"
							{...register("label")}
							label={formLabels.label}
							placeholder={formLabels.label}
							helperText={<>{errors.label?.message}</>}
							error={!!errors.label}
							margin="dense"
							fullWidth
							onChange={(e) => {
								setLabel(e.target.value);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<Grid container alignItems={"center"}>
							<Grid item xs={3} sm={3} display={"flex"} justifyContent={"center"} m={0} p={0}>
								<ChipColor label={label} backgroundColor={backgroundColor} borderColor={borderColor} fontColor={fontColor}></ChipColor>
							</Grid>
							<Grid item xs={9} sm={9}>
								<Grid container>
									<Grid item xs={12} sm={12}>
										<ColorPicker color={backgroundColor} onChange={setBackgroundColor} name={"backgroundColor"} label={formLabels.backgroundColor} />
									</Grid>
									<Grid item xs={12} sm={12}>
										<ColorPicker color={fontColor} onChange={setFontColor} name={"fontColor"} label={formLabels.fontColor} />
									</Grid>
									<Grid item xs={12} sm={12}>
										<ColorPicker color={borderColor} onChange={setBorderColor} name={"borderColor"} label={formLabels.borderColor} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12}>
						<ValidationFormButtons
							cancelButtonClick={handleEditModaleClose}
							saveButtonClick={onSubmit(handleSubmit)}
							disabledSaveButton={isSubmitting || !isValid}
						/>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	);
};

export default AdvancedSettingsSessionTagEditModal;
