import { type PresentationRead, getPresentations } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, queryStringsVersion } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const usePresentations = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<PresentationRead>> = {},
): UseQueryResult<Paginated<PresentationRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.presentations, ...queryKeyPart, "Get", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getPresentations({
				clientId,
				eventId,
				sessionId,
				queryStrings: queryStringsVersion(queryStrings, "2.0"),
				signal,
			}),
		...others,
	});
};
