import type { IPagination } from "@infrastructure/model/interfaces/api/api-pagination.interface";

const defaultPagination: IPagination = {
	page: 2,
	pageSize: 10,
	totalCount: 0,
};

const defaultRowsPerPageOptions: number[] = [8, 16, 20];
const extendedRowsPerPage: number[] = [20, 50, 100];

export { defaultPagination, defaultRowsPerPageOptions, extendedRowsPerPage };
