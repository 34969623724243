import { t } from "i18next";

import SessionController from "@application/Controllers/SessionController";
import type { TSessionDisplay } from "@domain/interfaces/session.interface";
import K4StatusBulletLegend from "@infrastructure/components/interface/icons/K4StatusBulletLegend";
import { type EnumSessionStatus, type IStatus, statusSessionObject } from "@key4-front-library/core";
import { MenuItem, Select } from "@mui/material";

type Props = {
	defaultValue: EnumSessionStatus;
	status: Array<IStatus>;
	session: TSessionDisplay;
	clientId: string;
	eventId: string;
	refreshSession: () => void;
};

const SelectStatus = (props: Props) => {
	const { defaultValue, status, session, eventId, clientId, refreshSession } = props;
	const activeStatus = statusSessionObject[defaultValue];

	const handleStatusChange = async (status: EnumSessionStatus) => {
		await SessionController.assignSessionStatus({
			clientId,
			eventId,
			sessionId: session.id ?? "",
			status,
		});
		// This component is used twice in parent component. One for desktop view and the other one for mobile view
		// Don't refresh state. Reload to be sure we have correct data in both views
		refreshSession();
	};

	return (
		<Select
			labelId="status-label"
			id="status"
			value={defaultValue}
			onChange={(event) => {
				handleStatusChange(event.target.value as EnumSessionStatus);
			}}
			fullWidth
			multiple={false}
			sx={{
				"& .circle": {
					display: "none",
				},
				bgcolor: activeStatus.bgColor,
				color: activeStatus.fontColor,
			}}
		>
			{status.map((item, index) => {
				return (
					<MenuItem key={index} value={item.value}>
						<K4StatusBulletLegend
							bullet={{
								color: item.bgColor,
							}}
							text={t(item.label)}
						/>
					</MenuItem>
				);
			})}
		</Select>
	);
};

export default SelectStatus;
