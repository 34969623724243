import { t } from "i18next";
// #region 'Imports'
import React from "react";

import FieldLabel from "@infrastructure/components/interface/titles/FieldLabel";
import { Typography } from "@mui/material";

// #endregion  'Imports'

type Props = {
	name: string;
};
const OrganizedByView = (props: Props) => {
	const { name } = props;

	return (
		<React.Fragment>
			<FieldLabel label={t("old.programme.sessionDetails.organizedBy.compagnyName")} />
			<Typography>{name}</Typography>
		</React.Fragment>
	);
};

export default OrganizedByView;
