import {
	EnumProgrammeExportDynamicFieldKey,
	EnumProgrammeExportDynamicFormKey,
	EnumProgrammeExportDynamicSectionKey,
	EnumProgrammeExportStaticFieldKey,
	EnumProgrammeExportStaticFormKey,
	EnumProgrammeExportStaticSectionKey,
} from "@application/Enums/ProgrammeExportEnum";
import type { TypeProgrammeExportDynamicField, TypeProgrammeExportStaticField } from "@application/Types/ProgrammeExportType";
import { EnumFormControlKind, getCellButtonsList } from "@key4-front-library/core";
import type {
	DtoDocument,
	DtoProgrammeExport,
	DtoProgrammeExportConfiguration,
	DtoProgrammeExportDocumentConfiguration,
	DtoProgrammeExportDocumentConfigurationSection,
	DtoProgrammeExportSection,
} from "@key4-front-library/core/Dto";
import { EnumDocumentFormat, EnumDocumentType, EnumProgrammeExportFormat, EnumTarget } from "@key4-front-library/core/Enums";
import type { GridColDef } from "@mui/x-data-grid-pro";

enum i18nKey {
	label = "label",
	legend = "legend",
}

const getFormControlTranslationKey = (fieldName: string, isSectionField = false, typeKey: string = i18nKey.label) =>
	["old.programme.programmeExport", ["formControls", isSectionField ? "sections" : undefined, fieldName, typeKey].filter((key) => key).join(".")].join(".");

const defaultDocumentConfigurationModel: DtoProgrammeExportDocumentConfiguration = {
	format: EnumProgrammeExportFormat.Pdf,
	filename: "",
	sections: [],
	backCoverUrl: "",
	frontCoverUrl: "",
	hasPageNumber: false,
	hasChairsIndex: false,
	hasSpeakersIndex: false,
};

const defaultDocumentConfigurationSectionModel: DtoProgrammeExportDocumentConfigurationSection = {
	name: "",
	displaySectionName: false,
	dividerUrl: "",
	backgroundUrl: "",
	isGroupedByDate: false,
	displayGroupName: false,
	filters: "",
	sessionTemplate: "",
};

const defaultDocumentModel: DtoDocument = {
	id: "",
	operationId: "",
	name: "",
	layoutId: null,
	description: "",
	target: EnumTarget.Session,
	format: EnumDocumentFormat.Pdf,
	type: EnumDocumentType.ProgrammeExport,
	configuration: defaultDocumentConfigurationModel,
	body: "",
};

const defaultProgrammeExportConfigurationModel: DtoProgrammeExportConfiguration = {
	filename: "",
	format: EnumProgrammeExportFormat.Pdf,
	frontCoverUrl: "",
	backCoverUrl: "",
	hasPageNumber: false,
	hasChairsIndex: false,
	hasSpeakersIndex: false,
	sections: [],
};

const defaultProgrammeExportModel: DtoProgrammeExport = {
	name: "",
	description: "",
	configuration: defaultProgrammeExportConfigurationModel,
};

const defaultProgrammeExportSectionModel: DtoProgrammeExportSection = {
	name: "",
	displaySectionName: false,
	dividerUrl: null,
	backgroundUrl: null,
	isGroupedByDate: false,
	displayGroupName: null,
	filters: null,
	sessionTemplate: "",
};

const datagridHeaders: GridColDef[] = [
	{ field: "id" },
	{ field: "name", flex: 3, minWidth: 250, disableColumnMenu: true },
	{ field: "sectionsCount", flex: 1, disableColumnMenu: true },
	{
		field: "actionButtons",
		renderCell: getCellButtonsList,
		minWidth: 110,
		flex: 1,
		hideSortIcons: true,
		disableColumnMenu: true,
		align: "right",
	},
];

const gridSize = { lg: 6, md: 6, xs: 12 };

const staticListFormControlsObject: {
	[key in EnumProgrammeExportStaticFormKey]: {
		[key in EnumProgrammeExportStaticSectionKey]?: TypeProgrammeExportStaticField;
	};
} = {
	[EnumProgrammeExportStaticFormKey.GeneralInformationForm]: {
		[EnumProgrammeExportStaticSectionKey.GeneralInformationSection]: {
			[EnumProgrammeExportStaticFieldKey.Name]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.Name,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.Name),
						required: true,
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.Filename]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.Filename,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.Filename),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.Filename, false, i18nKey.legend),
						required: true,
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.Format]: {
				// ! TODO: (later) - cf. K4PROG-2113 - Add toggle button
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.Format,
					kind: EnumFormControlKind.RADIO,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.Format),
						items: [
							{ key: "pdf", label: "PDF" },
							{ key: "word", label: "Word" },
						],
						isUnselectable: false,
						isRow: true,
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.FrontCoverUrl]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.FrontCoverUrl,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.FrontCoverUrl),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.FrontCoverUrl, false, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.BackCoverUrl]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.BackCoverUrl,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.BackCoverUrl),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.BackCoverUrl, false, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.HasPageNumber]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.HasPageNumber,
					kind: EnumFormControlKind.CHECKBOX,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasPageNumber),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasPageNumber, false, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.HasChairsIndex]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.HasChairsIndex,
					kind: EnumFormControlKind.CHECKBOX,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasChairsIndex),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasChairsIndex, false, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportStaticFieldKey.HasSpeakersIndex]: {
				value: null,
				component: {
					id: EnumProgrammeExportStaticFieldKey.HasSpeakersIndex,
					kind: EnumFormControlKind.CHECKBOX,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasSpeakersIndex),
						legend: getFormControlTranslationKey(EnumProgrammeExportStaticFieldKey.HasSpeakersIndex, false, i18nKey.legend),
					},
				},
			},
		},
	},
};

const dynamicListFormControlsObject: {
	[key in EnumProgrammeExportDynamicFormKey]: {
		[key in EnumProgrammeExportDynamicSectionKey]?: TypeProgrammeExportDynamicField;
	};
} = {
	[EnumProgrammeExportDynamicFormKey.DocumentSectionsForm]: {
		[EnumProgrammeExportDynamicSectionKey.DocumentSectionsSection]: {
			[EnumProgrammeExportDynamicFieldKey.Name]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.Name,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.Name, true),
						required: true,
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.DisplaySectionName]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.DisplaySectionName,
					kind: EnumFormControlKind.CHECKBOX,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.DisplaySectionName, true),
					},
				},
			},

			[EnumProgrammeExportDynamicFieldKey.DividerUrl]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.DividerUrl,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.DividerUrl, true),
						legend: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.DividerUrl, true, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.BackgroundUrl]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.BackgroundUrl,
					kind: EnumFormControlKind.TEXT_FIELD,
					gridSize: { ...gridSize, xs: 6 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.BackgroundUrl, true),
						legend: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.BackgroundUrl, true, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.IsGroupedByDate]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.IsGroupedByDate,
					kind: EnumFormControlKind.RADIO,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.IsGroupedByDate, true),
						items: [
							{
								key: "1",
								label: getFormControlTranslationKey([EnumProgrammeExportDynamicFieldKey.IsGroupedByDate, "items", "byDate"].join("."), true),
							},
							{
								key: "0",
								label: getFormControlTranslationKey([EnumProgrammeExportDynamicFieldKey.IsGroupedByDate, "items", "none"].join("."), true),
							},
						],
						isRow: true,
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.DisplayGroupName]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.DisplayGroupName,
					kind: EnumFormControlKind.CHECKBOX,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.DisplayGroupName, true),
						legend: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.DisplayGroupName, true, i18nKey.legend),
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.Filters]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.Filters,
					kind: EnumFormControlKind.CONTAINER_SEARCH_FILTER_QUERY_SELECT,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.Filters, true),
						items: [],
					},
				},
			},
			[EnumProgrammeExportDynamicFieldKey.SessionTemplate]: {
				value: null,
				component: {
					id: EnumProgrammeExportDynamicFieldKey.SessionTemplate,
					kind: EnumFormControlKind.TEXT_AREA,
					gridSize: { ...gridSize, lg: 12, md: 12 },
					propsComponent: {
						label: getFormControlTranslationKey(EnumProgrammeExportDynamicFieldKey.SessionTemplate, true),
					},
				},
			},
		},
	},
};

const ProgrammeExportConfiguration = {
	datagridHeaders,
	defaultDocumentConfigurationModel,
	defaultDocumentConfigurationSectionModel,
	defaultDocumentModel,
	defaultProgrammeExportConfigurationModel,
	defaultProgrammeExportModel,
	defaultProgrammeExportSectionModel,
	dynamicListFormControlsObject,
	staticListFormControlsObject,
};

export default ProgrammeExportConfiguration;
