import ConfigurationsApp from "@application/Configurations";
import HelpersApp from "@application/helpers";
import {
	EnumParticipantStatusInvitation,
	ErrorAPI,
	queryIncludeCustomFields,
	Services,
	type TypeApiResponsePost,
	useContextModule,
} from "@key4-front-library/core";
import type { DtoFaculty } from "@key4-front-library/core/Dto";
import type { DtoSpeakerGetQuality, DtoSpeakerWrite } from "@key4-front-library/core/Dto/SpeakerDto";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";

const useEntity = () => {
	const { client, event } = useContextModule();

	const create = async (
		useFormData: TypeUseFormListForms,
		sessionId: string,
		presentationId: string,
		includeSpeakerCustomFields?: boolean,
	): Promise<TypeApiResponsePost | ErrorAPI> => {
		try {
			const speaker: DtoSpeakerWrite = ConfigurationsApp.SpeakerConfiguration.defaultModel;
			HelpersApp.SpeakerHelper.mapUseFormToDtoSpeakerWrite(useFormData, speaker);

			// If the selected speaker is not a participant but only a contact, we need to create a participant
			const participantId = (await Services.Events.Registration.ParticipantsService.get(client.id, event.id, speaker.participantId)).id;
			let newParticipant: TypeApiResponsePost = { id: "" };

			if (!participantId) {
				const contact = await Services.Operations.Registration.ContactsService.get(client.id, event.communityId ?? event.id, speaker.participantId);

				newParticipant = await Services.Events.Registration.ParticipantsService.post(client.id, event.id, {
					email: contact.data.email,
					firstname: contact.data.firstname,
					lastname: contact.data.lastname,
					customFieldValues: contact.data.customFieldValues,
					invitationStatus: EnumParticipantStatusInvitation.UNSENT,
				});
			}

			if (!includeSpeakerCustomFields) {
				speaker.customFieldValues = {};
			}

			return await Services.Events.Programme.PresentationsService.postSpeaker(
				client.id,
				event.id,
				sessionId,
				presentationId,
				participantId ? speaker.participantId : newParticipant.id,
				speaker,
			);
		} catch (e) {
			const error = e as { status: number; message: unknown };
			return new ErrorAPI(error.status, error.message);
		}
	};

	const readListSpeakerQualities = async (): Promise<Array<DtoSpeakerGetQuality>> => {
		return await Services.Events.Programme.SpeakersService.getListQualities(client.id, event.id);
	};

	const readSpeaker = async (sessionId: string, presentationId: string, speaker: string): Promise<DtoFaculty | ErrorAPI> => {
		try {
			return await Services.Events.Programme.PresentationsService.getSpeaker(
				client.id,
				event.id,
				sessionId,
				presentationId,
				speaker,
				queryIncludeCustomFields(true),
			);
		} catch (e: any) {
			return Promise.reject(new ErrorAPI(e.status, e.message));
		}
	};

	const update = async (sessionId: string, presentationId: string, speakerId: string, useFormData: TypeUseFormListForms): Promise<boolean> => {
		try {
			const speaker: DtoSpeakerWrite = ConfigurationsApp.SpeakerConfiguration.defaultModel;

			HelpersApp.SpeakerHelper.mapUseFormToDtoSpeakerWrite(useFormData, speaker);

			await Services.Events.Programme.PresentationsService.putSpeaker(client.id, event.id, sessionId, presentationId, speakerId, speaker);
			return true;
		} catch {
			return false;
		}
	};

	return { readListSpeakerQualities, create, readSpeaker, update };
};

const SpeakerController = {
	useEntity,
};

export default SpeakerController;
