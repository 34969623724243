export enum HelpContentID {
	PRO_LPCSH_NoLPCSH = 1000,
	PRO_LPCSH_Dashboard = 1001,
	PRO_LPCSH_Scheduler = 1002,
	PRO_LPCSH_Sessions = 1003,
	PRO_LPCSH_SessionID = 1004,
	PRO_LPCSH_Faculties = 1005,
	PRO_LPCSH_FacultiesID = 1006,
	PRO_LPCSH_Room = 1007,
	PRO_LPCSH_Set_Scheduler = 1008,
	PRO_LPCSH_Set_Tags = 1009,
	PRO_LPCSH_RoomID = 1010,
}

type HelpDefinition = {
	route: string;
	contentID: HelpContentID;
};
const HelpConfiguration: HelpDefinition[] = [
	{
		route: "/:client/:event/dashboard",
		contentID: HelpContentID.PRO_LPCSH_Dashboard,
	},
	{
		route: "/:client/:event/scheduler",
		contentID: HelpContentID.PRO_LPCSH_Scheduler,
	},
	{
		route: "/:client/:event/sessions",
		contentID: HelpContentID.PRO_LPCSH_Sessions,
	},
	{
		route: "/:client/:event/sessions/:sessionId",
		contentID: HelpContentID.PRO_LPCSH_SessionID,
	},
	{
		route: "/:client/:event/faculties",
		contentID: HelpContentID.PRO_LPCSH_Faculties,
	},
	{
		route: "/:client/:event/faculties/:participantId/comments",
		contentID: HelpContentID.PRO_LPCSH_SessionID,
	},
	{
		route: "/:client/:event/rooms",
		contentID: HelpContentID.PRO_LPCSH_Room,
	},
	{
		route: "/:client/:event/rooms/:roomId/clashes",
		contentID: HelpContentID.PRO_LPCSH_RoomID,
	},
	{
		route: "/:client/:event/settings/scheduler",
		contentID: HelpContentID.PRO_LPCSH_Set_Scheduler,
	},
	{
		route: "/:client/:event/settings/tags",
		contentID: HelpContentID.PRO_LPCSH_Set_Tags,
	},
	{
		route: "/:client/:event/settings/anomalies",
		contentID: HelpContentID.PRO_LPCSH_NoLPCSH,
	},
	{
		route: "/:client/:event/settings/numbering",
		contentID: HelpContentID.PRO_LPCSH_NoLPCSH,
	},
];

export default HelpConfiguration;
