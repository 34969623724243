import { t } from "i18next";
import update from "immutability-helper";
import { uniqueId } from "lodash";
import type React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import type { ISettingsSessionChairQuality } from "@domain/interfaces/settings.session-chair-quality.interface";
import type { IChairQualityModel } from "@domain/model/chairQuality.model";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import { type IEntity, NoData, Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import { Skeleton, Stack } from "@mui/material";
import AdvancedSettingsChairQualityEdit from "@presentation/pages/advancedSettings/chairsTypes/AdvancedSettingsChairQualityEdit";
import SettingsChairQualityRender from "@presentation/pages/advancedSettings/chairsTypes/AdvancedSettingsChairQualityRender";

const AdvancedSettingsChairsTypes = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [currentChairQuality, setCurrentChairQuality] = useState<IChairQualityModel | null>(null);
	const [chairQualities, setChairQualities] = useState<any>(null);
	const [isLoadingChairQualities, setIsLoadingChairQualities] = useState<boolean>(false);

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleTopButtonOpenModalChairQualityAdd = () => {
		setCurrentChairQuality(null);
		setModaleOpenState(true);
	};

	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	const [sortedChairQualities, setSortedChairQualities] = useState<Array<IChairQualityModel>>([]);

	const handleOrderChairQualities = async (orderIds: Array<string>) => {
		await Services.Events.Programme.ChairsService.putQualitiesReOrder(client.id, event.id, { ids: orderIds }).catch(() => {
			sendError(t("old.common.errors.generic"));
		});
	};

	const fetchChairQualityData = async (clientid: string, eventId: string) => {
		setIsLoadingChairQualities(true);
		await Services.Events.Programme.ChairsService.getListQualities(clientid, eventId)
			.then((_data: Array<any>) => {
				setChairQualities(_data);
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			})
			.finally(() => {
				setIsLoadingChairQualities(false);
			});
	};

	const handleUpdateChairQuality = async (_data: ISettingsSessionChairQuality) => {
		if (!currentChairQuality) {
			await Services.Events.Programme.ChairsService.postQuality(client.id, event.id, _data)
				.then((_response: IEntity) => {
					setChairQualities([...chairQualities, { ..._data, id: _response.id }]);
					sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_creation"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		} else {
			await Services.Events.Programme.TagsService.put(client.id, event.id, currentChairQuality.id, currentChairQuality.id, _data)
				.then((_response: boolean | IErrorMessageReturn) => {
					setChairQualities(
						chairQualities?.map((x: any) =>
							x.id !== currentChairQuality.id
								? x
								: {
										..._data,
										id: currentChairQuality.id,
									},
						),
					);
					sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_modification"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		}
	};

	const handleDeleteChairQuality = async (id: string) => {
		await Services.Events.Programme.TagsService.deleteEntity(client.id, event.id, id, id)
			.then(() => {
				setChairQualities(chairQualities?.filter((x: any) => x.id !== id));
				sendSuccess(t("old.programme.advancedSettings.chairs.returnMessages.success_deletion"));
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const renderIconCardChildren = (): React.ReactElement => {
		if (!sortedChairQualities?.length) {
			return <NoData />;
		}
		return (
			<Stack spacing={2} key={uniqueId()}>
				{sortedChairQualities.map((chairQuality, index) => renderChairQuality(chairQuality, index))}
			</Stack>
		);
	};

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setSortedChairQualities((prevCards: Array<IChairQualityModel>) => {
				const newOrder = update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex]],
					],
				});
				handleOrderChairQualities(newOrder.map((x) => x.id));
				return newOrder;
			});
		},
		[handleOrderChairQualities],
	);

	useEffect(() => {
		setSortedChairQualities(chairQualities ?? []);
	}, [chairQualities]);

	const renderChairQuality = useCallback(
		(_chairQuality: IChairQualityModel, index: number) => {
			const handleOpenModalChairQualityEdit = (_entity: IChairQualityModel) => {
				setCurrentChairQuality(_entity);
				setModaleOpenState(true);
			};

			return (
				<DndProvider backend={HTML5Backend} key={uniqueId()}>
					<Stack key={_chairQuality.id}>
						<SettingsChairQualityRender
							index={index}
							chairQuality={_chairQuality}
							chairQualitiesCount={chairQualities.length}
							handleOpenModalChairQualityEdit={handleOpenModalChairQualityEdit}
							handleDeleteChairQuality={handleDeleteChairQuality}
							moveCard={moveCard}
						/>
					</Stack>
				</DndProvider>
			);
		},
		[moveCard, chairQualities?.length, handleDeleteChairQuality, setCurrentChairQuality],
	);

	useEffect(() => {
		fetchChairQualityData(client.id, event.id);
	}, []);

	return (
		<Fragment>
			<IconCard
				title={t("old.programme.advancedSettings.chairs.title")}
				icon={"user-crown"}
				button={{
					label: t("old.form.buttons.add"),
					icon: "plus",
					onClick: handleTopButtonOpenModalChairQualityAdd,
				}}
			>
				{isLoadingChairQualities ? <Skeleton height={200}></Skeleton> : renderIconCardChildren()}
			</IconCard>

			<DefaultModal
				open={modaleOpenState}
				title={currentChairQuality ? t("old.programme.advancedSettings.chairs.modale.creation") : t("old.programme.advancedSettings.chairs.modale.edition")}
			>
				<AdvancedSettingsChairQualityEdit
					chairQuality={currentChairQuality}
					updateChairQuality={handleUpdateChairQuality}
					editModaleClose={handleEditModaleClose}
				/>
			</DefaultModal>
		</Fragment>
	);
};

export default AdvancedSettingsChairsTypes;
