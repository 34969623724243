import { t } from "i18next";
import { useState } from "react";

import type { UserInformationWithMetadata } from "@application/Types";
import { BOX_SHADOW_ELEVATION_1, getTextField, isPluralKeyString, TextField, useDebounce } from "@key4-front-library/core";
import { Grid, Paper, Stack, Typography } from "@mui/material";

import { MatchingContactSection } from "../MatchingContactSection/MatchingContactSection";
import { MatchingParticipantSection } from "../MatchingParticipantSection/MatchingParticipantSection";

export interface MatchingProposalCardProps {
	matchingId?: string;
	onUserSelectedChange: (user: UserInformationWithMetadata) => void;
	userSelected?: UserInformationWithMetadata;
}

export const MatchingProposalCard = (props: MatchingProposalCardProps): React.ReactNode => {
	const { matchingId, userSelected, onUserSelectedChange } = props;
	const component = "old.common.matchingProposalCard";

	const [contactActivityCount, setContactActivityCount] = useState<number>(0);
	const [participantActivityCount, setParticipantActivityCount] = useState<number>(0);
	const [search, setSearch] = useState<string>("");
	const debouncedSearch = useDebounce(search);
	const proposalFound = participantActivityCount + contactActivityCount;

	return (
		<Paper sx={{ padding: 4, borderRadius: 4, boxShadow: BOX_SHADOW_ELEVATION_1 }} elevation={1}>
			<Stack spacing={1}>
				<Typography variant="h6">{t(`${component}.title`)}</Typography>
				<Typography color="text.disabled" variant="caption">
					{`${proposalFound} ${t(`${component}.proposalFound${isPluralKeyString(proposalFound > 1)}`)}`}
				</Typography>
			</Stack>
			<Stack>
				<TextField
					onChange={(e) => {
						setSearch(e.target.value);
					}}
					value={search}
					{...getTextField({ translation: component, type: "search" })}
				/>
			</Stack>
			<Stack sx={{ minHeight: "90vh" }}>
				<Grid container spacing={2} pt={2}>
					<Grid item xs={6}>
						<MatchingContactSection
							search={debouncedSearch}
							matchingId={matchingId}
							userSelected={userSelected}
							setContactActivityCount={setContactActivityCount}
							onUserSelectedChange={onUserSelectedChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<MatchingParticipantSection
							search={debouncedSearch}
							matchingId={matchingId}
							userSelected={userSelected}
							setParticipantActivityCount={setParticipantActivityCount}
							onUserSelectedChange={onUserSelectedChange}
						/>
					</Grid>
				</Grid>
			</Stack>
		</Paper>
	);
};
