import { EnumSpeakerFieldKey, EnumSpeakerFormKey, EnumSpeakerSectionKey } from "@application/Enums/SpeakerEnum";
import type { TypeSpeakerStaticField } from "@application/Types/SpeakerType";
import { EnumFormControlKind, EnumParticipantStatusInvitation, statusInvitationArray, statusInvitationParticipantObject } from "@key4-front-library/core";
import type { DtoSpeakerWrite } from "@key4-front-library/core/Dto";

const defaultModel: DtoSpeakerWrite = {
	participantId: "",
	invitationStatus: EnumParticipantStatusInvitation.UNSENT,
	customFieldValues: {},
	replacementEmail: null,
	replacementFirstname: null,
	replacementLastname: null,
	replacementComment: null,
	qualityIds: null,
};

const staticListFormControlsObject: {
	[key in EnumSpeakerFormKey]: {
		[key in EnumSpeakerSectionKey]?: TypeSpeakerStaticField;
	};
} = {
	[EnumSpeakerFormKey.GENERAL]: {
		[EnumSpeakerSectionKey.MAIN]: {
			[EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.PARTICIPANT_OR_CONTACT,
					kind: EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT,
					propsComponent: { required: true },
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},

			[EnumSpeakerFieldKey.QUALITY]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.QUALITY,
					kind: EnumFormControlKind.SELECT,
					propsComponent: {
						label: `old.programme.sessionDetails.speakers.form.fields.${EnumSpeakerFieldKey.QUALITY}`,
						items: [],
						isNullable: true,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},

			[EnumSpeakerFieldKey.INVITATION]: {
				value: EnumParticipantStatusInvitation.UNSENT,
				component: {
					id: EnumSpeakerFieldKey.INVITATION,
					kind: EnumFormControlKind.SELECT_BULLET_COLOR,
					propsComponent: {
						label: "old.programme.sessionDetails.speakers.form.fields.invitationStatus",
						items: statusInvitationArray.map((statusEnum: EnumParticipantStatusInvitation) => {
							return {
								key: statusInvitationParticipantObject[statusEnum].value,
								label: statusInvitationParticipantObject[statusEnum].label,
								color: statusInvitationParticipantObject[statusEnum].bgColor,
							};
						}),
						isNullable: false,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
		},
	},

	[EnumSpeakerFormKey.REPLACEMENT]: {
		[EnumSpeakerSectionKey.MAIN]: {
			[EnumSpeakerFieldKey.EMAIL]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.EMAIL,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.speakers.form.fields.email",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumSpeakerFieldKey.FIRSTNAME]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.FIRSTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.speakers.form.fields.firstname",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumSpeakerFieldKey.LASTNAME]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.LASTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.programme.sessionDetails.speakers.form.fields.lastname",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumSpeakerFieldKey.COMMENTS]: {
				value: null,
				component: {
					id: EnumSpeakerFieldKey.COMMENTS,
					kind: EnumFormControlKind.TEXT_AREA,
					propsComponent: {
						label: "old.programme.sessionDetails.speakers.form.fields.comment",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
		},
	},
	[EnumSpeakerFormKey.DETAILS]: {
		[EnumSpeakerSectionKey.MAIN]: {},
	},
};

const SpeakerConfiguration = {
	defaultModel,
	staticListFormControlsObject,
};

export default SpeakerConfiguration;
