import { createInstance } from "@jonkoops/matomo-tracker-react";
import { ExternalRouting } from "@key4-front-library/core";

const baseUrl = ExternalRouting.Matomo;

const MatomoConfiguration = createInstance({
	urlBase: baseUrl,
	siteId: 3,
	// userId: `undefined`, // optional, default value: `undefined`.
	// trackerUrl: `${baseUrl}/tracking.php`, // optional, default value: `${urlBase}matomo.php`
	// srcUrl: '//cdn.matomo.cloud/key4events.matomo.cloud/matomo.js', // optional, default value: `${urlBase}matomo.js`
	// disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
	// heartBeat: { // optional, enabled by default
	//     active: true, // optional, default value: true
	//     seconds: 15 // optional, default value: `15
	// },
	linkTracking: true, // optional, default value: true
	// configurations: { // optional, default value: {}. any valid matomo configuration, all below are optional
	//     disableCookies: true,
	//     setSecureCookie: true,
	//     setRequestMethod: 'POST'
	// }
});

export default MatomoConfiguration;
