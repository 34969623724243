import { t } from "i18next";
import { useEffect, useState } from "react";

import SettingsClashesSkeleton from "@application/components/settings/scheduler/clashes/SettingsClashesSkeleton";
import SettingsClashesView from "@application/components/settings/scheduler/clashes/SettingsClashesView";
import { IconItemReference } from "@application/enum/IconItemReference.enum";
import SettingsFactory from "@application/factory/settings/SettingsFactory";
import GetTranslationsForSettingsScheduler from "@application/helpers/translations/settings/GetTranslationsForSettingsScheduler";
import type { ISettingsClashesObject } from "@domain/interfaces/settings.clashes.interface";
import type { ISettingsClashesModel } from "@domain/model/settings.clashes.model";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { useSettingsContext } from "@infrastructure/contexts/settingsContext";
import { Culture, Cultures, Services, useAuthenticationContext, useContextModule, useSnackBarHook } from "@key4-front-library/core";

const AdvancedSettingsClashes = () => {
	const { client, event } = useContextModule();
	const { settingsContext } = useSettingsContext();

	const { sendError } = useSnackBarHook();

	const { user } = useAuthenticationContext();
	const translations = GetTranslationsForSettingsScheduler();

	const [culture, setCulture] = useState<string>(Culture[0].culture);
	const [settingsClashesData, setSettingsClashesData] = useState<ISettingsClashesObject>();
	const [isLoadingSettingsClashes, setIsLoadingSettingsClashes] = useState<boolean>(true);

	const clashesIconName = settingsContext?.icons.find((module) => module.reference === IconItemReference.TAG)?.icon;

	const fetchSettingsClashes = async (clientId: string, eventId: string) => {
		await Services.Events.Programme.ClashesService.getSettings(clientId, eventId)
			.then((_data) => {
				remapAndSetSettingsClashes(_data);
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			})
			.finally(() => {
				setIsLoadingSettingsClashes(false);
			});
	};

	const remapAndSetSettingsClashes = (_settingsClashesModel: any) => {
		const settingsClashesObject = SettingsFactory.TransformSettingsClashesDataForReading({
			settings: _settingsClashesModel,
			culture,
		});
		setSettingsClashesData(settingsClashesObject);
	};

	const handleSaveClashesSettings = (_data: ISettingsClashesObject) => {
		const updateData = async (clientId: string, eventId: string, settingsClashesModel: ISettingsClashesModel) => {
			await Services.Events.Programme.ClashesService.putSettings(clientId, eventId, settingsClashesModel)
				.then(() => {
					fetchSettingsClashes(clientId, eventId);
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				})
				.finally(() => {
					setIsLoadingSettingsClashes(false);
				});
		};

		const settingsClashesModel = SettingsFactory.TransformSettingsClashesDataForWriting({
			settings: _data,
			culture,
		});

		setIsLoadingSettingsClashes(true);
		updateData(client.id, event.id, settingsClashesModel);
	};

	useEffect(() => {
		const codeCulture = Cultures[user.locale].locale;
		setCulture(codeCulture);
	}, [t]);

	useEffect(() => {
		setIsLoadingSettingsClashes(true);
		fetchSettingsClashes(client.id, event.id);
	}, [event, culture]);

	return (
		<>
			{!isLoadingSettingsClashes ? (
				settingsClashesData && (
					<SettingsClashesView
						icon={clashesIconName as IconName}
						title={translations.clashes.title}
						culture={culture}
						translations={translations}
						settings={settingsClashesData}
						saveSettings={handleSaveClashesSettings}
					/>
				)
			) : (
				<SettingsClashesSkeleton />
			)}
		</>
	);
};

export default AdvancedSettingsClashes;
