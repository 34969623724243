import ChairConfiguration from "./Chair.configuration";
import PresentationConfiguration from "./PresentationConfiguration";
import ProgrammeExportConfiguration from "./ProgrammeExportConfiguration";
import SessionConfiguration from "./SessionConfiguration";
import SpeakerConfiguration from "./Speaker.configuration";

const ConfigurationsApp = {
	ChairConfiguration,
	SessionConfiguration,
	SpeakerConfiguration,
	PresentationConfiguration,
	ProgrammeExportConfiguration,
};

export default ConfigurationsApp;
