export enum MenuItemReference {
	ALERTS = "alerts",
	ADVANCED_SETTINGS = "advancedSettings",
	DASHBOARD = "dashboard",
	SCHEDULER = "scheduler",
	SESSIONS = "sessions",
	FACULTIES = "faculties",
	ROOMS = "rooms",
	ACTIONS = "actions",
	SETTINGS = "settings",
	HELP = "help",
	SUPPORT = "support",
}
