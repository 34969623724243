enum EnumProgrammeExportDynamicFormKey {
	DocumentSectionsForm = "document-sections-form",
}

enum EnumProgrammeExportDynamicSectionKey {
	DocumentSectionsSection = "document-sections-section",
}

enum EnumProgrammeExportDynamicFieldKey {
	Name = "name",
	DisplaySectionName = "displaySectionName",
	NameTemplate = "nameTemplate",
	DividerUrl = "dividerUrl",
	BackgroundUrl = "backgroundUrl",
	IsGroupedByDate = "isGroupedByDate",
	DisplayGroupName = "displayGroupName",
	Filters = "filters",
	SessionTemplate = "sessionTemplate",
}

enum EnumProgrammeExportStaticFormKey {
	GeneralInformationForm = "general-informations-form",
}

enum EnumProgrammeExportStaticSectionKey {
	GeneralInformationSection = "general-informations-section",
}

enum EnumProgrammeExportStaticFieldKey {
	Name = "name",
	Description = "description",
	Filename = "filename",
	Format = "format",
	FrontCoverUrl = "frontCoverUrl",
	BackCoverUrl = "backCoverUrl",
	HasPageNumber = "hasPageNumber",
	HasChairsIndex = "hasChairsIndex",
	HasSpeakersIndex = "hasSpeakersIndex",
}

enum EnumProgrammeExportSectionMoveDirection {
	Left = "left",
	Right = "right",
}

export {
	EnumProgrammeExportDynamicFieldKey,
	EnumProgrammeExportDynamicFormKey,
	EnumProgrammeExportDynamicSectionKey,
	EnumProgrammeExportSectionMoveDirection,
	EnumProgrammeExportStaticFieldKey,
	EnumProgrammeExportStaticFormKey,
	EnumProgrammeExportStaticSectionKey,
};
