import type { IconName, IconPrefix, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { TIcon } from "@infrastructure/model/@types/icons/icon";
import { Stack } from "@mui/material";

export interface K4DataGridCellIconProps {
	isDisplayed: boolean;
	icon: TIcon;
}

const K4DataGridCellIcon = (props: K4DataGridCellIconProps) => {
	const { isDisplayed, icon } = props;
	return (
		<Stack direction={"row"} alignItems={"center"}>
			{isDisplayed && (
				<Icon color={icon.color} prefix={icon.prefix as IconPrefix} iconName={icon.name as IconName} size={(icon.size as SizeProp | undefined) ?? "1x"} />
			)}
		</Stack>
	);
};

export default K4DataGridCellIcon;
