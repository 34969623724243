export enum MenuActionsReference {
	ADD = "add",
	CLONE = "clone",
	DELETE = "delete",
	DETAILS = "details",
	DOWN = "down",
	DRAG_AND_DROP = "drag-and-drop",
	EDIT = "edit",
	FILTER = "filter",
	MENU = "menu",
	REDIRECTION = "redirection",
	UP = "up",
}
