import { Controller, useFormContext } from "react-hook-form";

import AnomalyDescription from "@application/components/_common/Anomalies/AnomalyDescription";
import type { ISettingsAnomaliesItems } from "@domain/interfaces/settings.anomalies.interface";
import { Checkbox } from "@mui/material";

type AnomalyDescriptionProps = {
	item: ISettingsAnomaliesItems;
	index: number;
	isEdit?: boolean;
	styles?: any;
};
const AnomalyDescriptionForm = (props: AnomalyDescriptionProps) => {
	const { item, index, isEdit, styles } = props;
	const { watch, control } = useFormContext();
	// ? where to unsubscribe to useForm watcher ?
	const watchIsChecked = watch(item.id, item.checked);

	const CheckboxAnomaly = () => {
		return (
			<Controller
				name={item.id}
				control={control}
				render={({ field }) => (
					<Checkbox
						onChange={(e) => {
							field.onChange(e.target.checked);
						}}
						checked={field.value}
					/>
				)}
			/>
		);
	};

	return (
		<AnomalyDescription item={item} index={index} isEdit={isEdit} styles={styles} watchIsChecked={watchIsChecked}>
			<CheckboxAnomaly />
		</AnomalyDescription>
	);
};

export default AnomalyDescriptionForm;
