import ControllersApp from "@application/Controllers/ControllersApp";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readSettingsScheduler: readSettingsSchedulerData } = ControllersApp.SettingsController.useEntity();

	const readSettingsScheduler = () => {
		const { data } = useQuery({
			queryKey: ["get", "settings", "scheduler"],
			queryFn: async () => {
				return await readSettingsSchedulerData();
			},
		});

		return { data };
	};
	return { readSettingsScheduler };
};

const SettingsHook = {
	useEntity,
};

export default SettingsHook;
