import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ConfigurationsApp from "@application/Configurations";
import ControllersApp from "@application/Controllers/ControllersApp";
import {
	EnumProgrammeExportDynamicFormKey,
	EnumProgrammeExportSectionMoveDirection,
	EnumProgrammeExportStaticSectionKey,
} from "@application/Enums/ProgrammeExportEnum";
import ProgrammeExportTabs from "@application/components/ProgrammeExport/ProgrammeExportTabs";
import HelpersApp from "@application/helpers";
import HooksApp from "@application/hooks";
import SnackBarHook from "@application/hooks/SnackBarHook";
import { ButtonCancel, ButtonSave, ConfirmationModal, DialogOld, EConfirmationModalAction } from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import { DialogActions, DialogContent, Skeleton, Stack, Typography } from "@mui/material";

import type { TypeUseFormListForms } from "@key4-front-library/core";
const staticSectionKey = EnumProgrammeExportStaticSectionKey.GeneralInformationSection;
const dynamicFormKey = EnumProgrammeExportDynamicFormKey.DocumentSectionsForm;

const sectionModel = ConfigurationsApp.ProgrammeExportConfiguration.defaultProgrammeExportSectionModel;

enum Action {
	CREATED = "created",
	UPDATED = "updated",
	INVALID = "invalid",
}

type PropsContainerProgrammeExportModal = {
	id: string | null;
	isOpen: boolean;
	changeIsOpen: (isOpen: boolean) => void;
	activeTabKey: string | null;
	onChangeTab: (key: string | null) => void;
	callbackSubmit?: () => void;
};

const ContainerProgrammeExportModal = (props: PropsContainerProgrammeExportModal) => {
	const { id, isOpen, changeIsOpen, activeTabKey, onChangeTab, callbackSubmit } = props;

	const [currentProgrammeExportId, setCurrentProgrammeExportId] = useState<string | null>(id);

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const [isOpenConfirmationModalDelete, setIsOpenConfirmationModalDelete] = useState<boolean>(false);
	const [currentSectionKeyToDelete, setCurrentSectionKeyToDelete] = useState<string | undefined>(undefined);

	const { read } = HooksApp.ProgrammeExportHook.useEntity();
	const { data, isLoading, isFetching, refetch } = read(currentProgrammeExportId);

	const { sendSuccess, sendError } = SnackBarHook.useSnackBar();
	const { update, create } = ControllersApp.ProgrammeExportController.useEntity();

	const translationNameSpace = "old.programme.programmeExport";

	const translationBaseKey: string = [translationNameSpace, "modal"].join(".");
	const translationActionsKey: string = [translationBaseKey, "actions"].join(".");
	const newSectionKey = [translationBaseKey, "sections", "newSection"].join(".");
	const translations = {
		dialogTitle: t([translationBaseKey, "title", !id ? "create" : "update"].join(".")),
		confirmationDeletePrompt: t([translationBaseKey, "confirmationDeletePrompt"].join(".")),
		newSection: t(newSectionKey),
		deletedSection: t([translationBaseKey, "sections", "deletedSection"].join(".")),
	};

	const formMethods = useForm({
		mode: "onSubmit",
		resolver: HelpersApp.ProgrammeExportHelper.getResolver(data?.useFormData ?? null),
	});

	const { handleSubmit: onSubmit, setValue } = formMethods;

	useEffect(() => {
		setCurrentProgrammeExportId(id);
	}, [id]);

	useEffect(() => {
		if (!isLoading && !isFetching && data?.useFormData) {
			HelpersApp.ProgrammeExportHelper.formsSetValues(data.useFormData, setValue);
		}
	}, [isLoading, isFetching]);

	const handleDialogButtonSaveClick = (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setIsLoadingSubmit(true);
		onSubmit((form) => handleSubmit({ form, closeModal: true }), handleInvalid)(mouseEvent);
	};

	const handleDialogButtonCancelClick = () => {
		callbackSubmit?.();
		changeIsOpen(false);
	};

	const handleChangeTab = (key: string | null) => {
		onChangeTab(key ?? null);
	};

	const handleAddSection = () => onSubmit((form) => handleSubmit({ form, addSection: true }), handleInvalid)();

	const handleCloneSection = (key: string) => onSubmit((form) => handleSubmit({ form, cloneSection: true, key }), handleInvalid)();

	const handleMoveLeftSection = (key: string) =>
		onSubmit(
			(form) =>
				handleSubmit({
					form,
					moveSection: true,
					moveSectionDirection: EnumProgrammeExportSectionMoveDirection.Left,
					key,
				}),
			handleInvalid,
		)();

	const handleMoveRightSection = (key: string) =>
		onSubmit(
			(form) =>
				handleSubmit({
					form,
					moveSection: true,
					moveSectionDirection: EnumProgrammeExportSectionMoveDirection.Right,
					key,
				}),
			handleInvalid,
		)();

	const handleRemoveSection = (key: string) => {
		setCurrentSectionKeyToDelete(key);
		handleConfirmationModalDeleteOpen();
	};

	const handleConfirmationModalDeleteOpen = () => {
		setIsOpenConfirmationModalDelete(true);
	};

	const handleConfirmationModalDeleteClose = () => {
		setIsOpenConfirmationModalDelete(false);
	};

	const handleConfirmationModalDeleteAction = async () => {
		const key = currentSectionKeyToDelete;
		setCurrentSectionKeyToDelete(undefined);
		setIsOpenConfirmationModalDelete(false);
		onChangeTab(staticSectionKey);
		onSubmit((form) => handleSubmit({ form, removeSection: true, key }))();
	};

	const handleCreate = async (form: TypeUseFormListForms) => {
		await create(form)
			.then((response) => {
				if (!currentProgrammeExportId && response) {
					setCurrentProgrammeExportId(response.id);
				}
				sendSuccess(t([translationActionsKey, Action.CREATED].join(".")));
			})
			.catch((error) => sendError(error));
	};

	const handleUpdate = async (id: string, form: TypeUseFormListForms) =>
		await update(id, form)
			.then(() => sendSuccess(t([translationActionsKey, Action.UPDATED].join("."))))
			.catch((error) => sendError(error));

	const handleSubmit = async (props: {
		form: TypeUseFormListForms;
		closeModal?: boolean;
		addSection?: boolean;
		cloneSection?: boolean;
		moveSection?: boolean;
		moveSectionDirection?: EnumProgrammeExportSectionMoveDirection;
		removeSection?: boolean;
		key?: string;
	}) => {
		const { form, closeModal = false, addSection = false, removeSection = false, cloneSection = false, moveSection = false, moveSectionDirection, key } = props;

		let currtentTabKey: string | null = activeTabKey;

		if (addSection) {
			const newSectionKey = HelpersApp.ProgrammeExportHelper.getDynamicSectionKey(data?.sectionsCount ?? 0);
			currtentTabKey = newSectionKey;
			if (!form[dynamicFormKey]) {
				form[dynamicFormKey] = {};
			}
			form[dynamicFormKey][newSectionKey] = {};
			setValue([dynamicFormKey, newSectionKey].join("."), {
				...sectionModel,
				name: t(translations.newSection),
			});
		}

		if (cloneSection && key) {
			const originalSection = form[dynamicFormKey][key];
			const cloneSectionKey = HelpersApp.ProgrammeExportHelper.getDynamicSectionKey(data?.sectionsCount ?? 0);
			currtentTabKey = cloneSectionKey;
			form[dynamicFormKey][cloneSectionKey] = cloneDeep(originalSection);
		}

		if (moveSection && moveSectionDirection && key) {
			HelpersApp.ProgrammeExportHelper.moveSection(form, key, moveSectionDirection);
		}

		if (removeSection && key) {
			delete form[dynamicFormKey][key];
		}

		(!currentProgrammeExportId ? handleCreate(form) : handleUpdate(currentProgrammeExportId, form))
			.then(() => {
				refetch().then(() => {
					if (currtentTabKey !== activeTabKey) {
						onChangeTab(currtentTabKey);
					}
				});
				if (closeModal) {
					changeIsOpen(false);
					callbackSubmit?.();
				}
			})
			.catch((error) => sendError(error))
			.finally(() => {
				setIsLoadingSubmit(false);
			});
	};

	const handleInvalid = () => {
		setIsLoadingSubmit(false);
		sendError(t([translationActionsKey, Action.INVALID].join(".")).toString());
	};

	return (
		<>
			<DialogOld isOpen={isOpen}>
				<>
					<DialogTitle
						title={
							<Stack direction={"row"} spacing={1} alignItems={"center"}>
								<Stack>
									<Typography variant="h5">{translations.dialogTitle}</Typography>
								</Stack>
							</Stack>
						}
						onCloseClick={() => {
							changeIsOpen(false);
						}}
					/>
					<DialogContent>
						{!isLoading && !isFetching && data?.componentData && (
							<FormProvider {...formMethods}>
								<ProgrammeExportTabs
									activeTabKey={activeTabKey}
									onChangeTab={handleChangeTab}
									onAddSectionClick={handleAddSection}
									onCloneSectionClick={handleCloneSection}
									onMoveLeftSectionClick={handleMoveLeftSection}
									onMoveRightSectionClick={handleMoveRightSection}
									onRemoveSectionClick={handleRemoveSection}
									tabsDefinitions={data.componentData}
								/>
							</FormProvider>
						)}
						{(isLoading || isFetching || !data?.componentData) && <Skeleton sx={{ width: "100%", height: 600 }} animation="wave" variant="rectangular" />}
					</DialogContent>
					<DialogActions>
						<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
							<ButtonCancel onClick={handleDialogButtonCancelClick} />
							<ButtonSave isLoading={isLoadingSubmit || isFetching} onClick={handleDialogButtonSaveClick} />
						</Stack>
					</DialogActions>
				</>
			</DialogOld>

			{isOpenConfirmationModalDelete && (
				<ConfirmationModal
					open={isOpenConfirmationModalDelete}
					text={translations.confirmationDeletePrompt}
					action={EConfirmationModalAction.DELETE}
					handleAction={handleConfirmationModalDeleteAction}
					handleModaleClose={handleConfirmationModalDeleteClose}
					maxWidth={"sm"}
				/>
			)}
		</>
	);
};

export default ContainerProgrammeExportModal;
