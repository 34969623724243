import { type DtoFaculty, QUERY_KEYS_PROGRAMME, Services, type TypeApiQueryString, type TypeApiResponsePagined } from "@key4-front-library/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

interface Args {
	clientId: string;
	sessionId: string;
	eventId: string;
	queryStrings?: TypeApiQueryString[];
}

export const useChairs = (args: Args): UseQueryResult<TypeApiResponsePagined<Array<DtoFaculty>>> => {
	const { clientId, sessionId, eventId, queryStrings } = args;
	return useQuery({
		queryKey: [QUERY_KEYS_PROGRAMME.chairs, "Token", clientId, eventId, sessionId],
		queryFn: async () => await Services.Events.Programme.SessionsService.getListPaginedChair(clientId, eventId, sessionId, queryStrings),
	});
};
