import { useState } from "react";

import AnomaliesBySessionContainer from "@application/components/_common/Anomalies/AnomaliesBySessionContainer";
import AnomaliesFilters from "@application/components/_common/Anomalies/AnomaliesFilters";
import AnomaliesFactory from "@application/factory/anomalies/anomaliesFactory";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import type { IAnomaliesByType } from "@domain/interfaces/anomalies.interface";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { Stack } from "@mui/material";

const AlertsAnomalies = () => {
	const translations = GetTranslationsForAnomalies();

	const [anomaliesCheckboxes, setAnomaliesCheckboxes] = useState<Array<IAnomaliesByType>>(AnomaliesFactory.TransformAnomaliesByType({ translations }));

	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const handleIsOnlyActiveChange = () => {
		setIsOnlyActive((prev) => !prev);
	};

	const handleAnomalyCheckboxClick = (anomalyItem: IAnomaliesByType) => {
		setAnomaliesCheckboxes((prev) =>
			[...prev].map((object) => {
				if (anomalyItem && object.anomaly === anomalyItem.anomaly) {
					return {
						...object,
						checked: !object.checked,
					};
				}
				return object;
			}),
		);
	};

	const handleSelectAllAnomaliesClick = (selectAll: boolean) => {
		setAnomaliesCheckboxes((prev) =>
			[...prev].map((object) => {
				return {
					...object,
					checked: selectAll,
				};
			}),
		);
	};

	return (
		<AppBox>
			<Stack spacing={2}>
				<AnomaliesFilters
					isOnlyActive={isOnlyActive}
					onIsOnlyActiveChange={handleIsOnlyActiveChange}
					anomaliesCheckboxes={anomaliesCheckboxes}
					onAnomalyCheckboxClick={handleAnomalyCheckboxClick}
					onSelectAllAnomaliesClick={handleSelectAllAnomaliesClick}
				/>
				<AnomaliesBySessionContainer anomaliesCheckboxes={anomaliesCheckboxes} isOnlyActive={isOnlyActive} />
			</Stack>
		</AppBox>
	);
};

export default AlertsAnomalies;
