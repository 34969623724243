import { t } from "i18next";
import type { MouseEventHandler } from "react";

import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";

import K4ButtonSave from "../../../infrastructure/components/interface/button/K4ButtonSave";

interface Props {
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonSaveDecorated = (props: Props & dataCypressProp) => {
	const { isDisabled = false, onClick, dataCypressID } = props;
	return <K4ButtonSave label={t("old.common.button.save")} isDisabled={isDisabled} onClick={onClick} data-cy={dataCypressID} />;
};

export default K4ButtonSaveDecorated;
