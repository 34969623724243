// #region 'Imports'
import { t } from "i18next";

import StatsCircle from "@infrastructure/components/charts/Donut/StatsCircle";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import { Grid, Stack } from "@mui/material";

// #endregion 'Imports'

type LegendProps = {
	name: string;
	value: number;
	color?: string;
};

const Legend = (props: LegendProps) => {
	const { name, value, color } = props;

	return (
		<Stack direction="row" spacing={3} justifyContent="space-between">
			<Stack direction="row" spacing={1} alignItems="center">
				<div
					style={{
						backgroundColor: color,
						borderRadius: "50%",
						width: "12px",
						height: "12px",
					}}
				/>
				<span>{t(name)}</span>
			</Stack>
			<Stack justifyContent="flex-end">
				<NumberThousandSeparator value={value} />
			</Stack>
		</Stack>
	);
};

type Props = {
	statistics: Array<IChartSeriesItem>;
};

const DonutChart = (props: Props) => {
	const { statistics } = props;
	return (
		<Grid container justifyContent="space-around" alignItems="center" spacing={4}>
			<Grid item>
				<StatsCircle sqSize={100} strokeWidth={10} statistics={statistics} />
			</Grid>
			<Grid item>
				<Stack spacing={1}>
					{statistics.map((item, index) => {
						return <Legend {...item} key={index} />;
					})}
				</Stack>
			</Grid>
		</Grid>
	);
};

export default DonutChart;
