export type TRoomDetails = {
	id: string;
	name: string;
	capacity: number;
};

export enum ERoomView {
	LIST = "list",
	SCHEDULER = "scheduler",
}
