import { ApplicationColors } from "@key4-front-library/core";

export interface IPublish {
	value: EPublishSession;
	label: string;
	bgColor: string;
}

export enum EPublishSession {
	NULL = "publish",
	PUBLISH_ON = "publishOn",
	PUBLISHED_SINCE = "publishedSince",
}

export const publish: Array<IPublish> = [
	{
		value: EPublishSession.NULL,
		label: "old.programme.sessionDetails.buttons.publish.publish",
		bgColor: ApplicationColors.greyStone.main,
	},
	{
		value: EPublishSession.PUBLISH_ON,
		label: "old.programme.sessionDetails.buttons.publish.on",
		bgColor: "warning",
	},
	{
		value: EPublishSession.PUBLISHED_SINCE,
		label: "old.programme.sessionDetails.buttons.publish.since",
		bgColor: "success",
	},
];
