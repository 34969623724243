import { useState } from "react";

import SearchListHelper from "@infrastructure/helpers/searchList.helper";
import type { DtoSearchFilterGet } from "@key4-front-library/core/Dto";
import type { Fields } from "@react-awesome-query-builder/ui";

const useSearchList = () => {
	const [search, setSearch] = useState<string>("");
	const [newQueryFilterId, setNewQueryFilterId] = useState<string>();
	const handleSearchChange = (search: string) => {
		setSearch(search);
	};
	const [searchFilterQueriesList, setSearchFilterQueriesList] = useState<Array<DtoSearchFilterGet>>();

	const searchListTranslation = SearchListHelper.getTranslations();
	const [fields, setFields] = useState<Fields>();
	const [openSaveNewQueryModale, setOpenSaveNewQueryModale] = useState<boolean>(false);
	const [queryBuilderString, setQueryBuilderString] = useState<string>("");

	const handleQueryBuilderChange = (queryString: string) => {
		setQueryBuilderString(queryString);
	};

	const handleOpenSaveNewQueryModaleClick = (isOpen: boolean) => {
		setOpenSaveNewQueryModale(isOpen);
	};

	return {
		searchListTranslation,
		search,
		fields,
		setFields,
		queryBuilderString,
		handleSearchChange,
		handleQueryBuilderChange,
		openSaveNewQueryModale,
		setOpenSaveNewQueryModale,
		newQueryFilterId,
		setNewQueryFilterId,
		handleOpenSaveNewQueryModaleClick,
		searchFilterQueriesList,
		setSearchFilterQueriesList,
	};
};

export default useSearchList;
