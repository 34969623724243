import i18next, { t, type TFunction } from "i18next";
import { Fragment, useEffect, useState } from "react";

import K4StatusBulletLegend from "@infrastructure/components/interface/icons/K4StatusBulletLegend";
import { extendedRowsPerPage } from "@infrastructure/constants/default-pagination.constant";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Link } from "@key4-front-library/core";
import { Box, Chip, TablePagination } from "@mui/material";
import {
	DataGridPro,
	frFR,
	type GridColumnHeaderParams,
	type GridColumns,
	gridPageSelector,
	type GridRenderCellParams,
	type GridRowsProp,
	type GridSortModel,
	type GridValidRowModel,
	useGridApiContext,
	useGridSelector,
} from "@mui/x-data-grid-pro";
import type { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

import { Icon } from "../icons/Icon";

export type IGridModel = {
	initialState: GridInitialStateCommunity;
	columnsDefinition: GridColumns<GridValidRowModel>;
};

export enum GridDisplayFormatEnum {
	TEXT = 0,
	ICON = 1,
	SESSIONSTATUS = 2,
	INVITATIONSTATUS = 3,
	TAG = 4,
	LINK = 5,
}

const GridInitialState: GridInitialStateCommunity = {
	columns: {
		columnVisibilityModel: {
			id: false,
			room: false,
		},
	},
};

const dataGridFormatDefaultValues = {
	minWidth: 100,
	maxWidth: 800,
};

type displayFormatType = {
	value: GridDisplayFormatEnum;
	iconName?: string;
	color?: string;
	to?: string;
};

type schemeAndColumnTitlesType = {
	headerName: string;
	reference?: string;
	minWidth?: number;
	maxWidth?: number;
	hide?: boolean;
	displayFormat?: displayFormatType;
};

type IDataGrid = {
	data: GridRowsProp;
	schemeAndColumnTitles: { [key: string]: schemeAndColumnTitlesType };
	page: number;
	pageSize: number;
	totalRowCount: number;
	isDoubleClick: boolean;
	handleChangePage: (page: number) => void;
	handleChangePageSize: (pageSize: number) => void;
	handleSingleClick?: (data: any) => void;
	handleDoubleClick?: (data: any) => void;
	handleChangeSortCriteria: (criteria: GridSortModel) => void;
};

/**
 *
 * @deprecated
 */
const K4ListGrid = (props: IDataGrid & dataCypressProp) => {
	const {
		data,
		schemeAndColumnTitles,
		page,
		pageSize,
		totalRowCount,
		isDoubleClick = false,
		handleChangePage,
		handleChangePageSize,
		handleSingleClick,
		handleDoubleClick,
		handleChangeSortCriteria,
	} = props;

	const [dataGridModel, setDataGridModel] = useState<IGridModel>();

	const customRenderCell = (params: GridRenderCellParams, displayFormat: any, t: TFunction) => {
		const { value, id } = params;
		if (displayFormat === undefined) {
			return <Box>{value}</Box>;
		}
		switch (displayFormat.value) {
			case GridDisplayFormatEnum.TEXT:
				return <Box>{value}</Box>;
			case GridDisplayFormatEnum.ICON:
				return <Box>{value && <Icon prefix="fas" iconName={displayFormat.iconName} color={displayFormat.color} />}</Box>;
			case GridDisplayFormatEnum.SESSIONSTATUS:
			case GridDisplayFormatEnum.INVITATIONSTATUS: {
				return (
					<K4StatusBulletLegend
						text={t(value.label)}
						bullet={{
							color: value.bgColor,
						}}
					/>
				);
			}
			case GridDisplayFormatEnum.TAG: {
				const currentTag = params.row.primaryTag[0];
				return (
					<Box>
						{currentTag.label && (
							<Chip
								sx={{
									backgroundColor: currentTag.backgroundColor,
									color: currentTag.fontColor,
									borderColor: currentTag.borderColor,
									borderWidth: "1px",
									borderStyle: "solid",
								}}
								label={currentTag.label}
							/>
						)}
					</Box>
				);
			}
			case GridDisplayFormatEnum.LINK:
				return <Link to={`${displayFormat.to}/${id}`}>{value}</Link>;

			default:
				return <Box>{value}</Box>;
		}
	};

	const customRenderHeader = (params: GridColumnHeaderParams) => {
		const { colDef } = params;
		return <Box className="MuiDataGrid-columnHeaderTitle">{`${colDef.headerName}`}</Box>;
	};

	const defaultGridColDef: any = {
		renderHeader: customRenderHeader,
		editable: false,
		hideable: false,
		flex: 1,
	};

	useEffect(() => {
		const GetGridColDefinition = (modelDefinition: {
			[key: string]: schemeAndColumnTitlesType;
		}): GridColumns<GridValidRowModel> => {
			const result: GridColumns<GridValidRowModel> = [];
			Object.keys(modelDefinition).forEach((key: any) => {
				const item = modelDefinition[key];
				let gridColDef = defaultGridColDef;

				if (key === "id" || key === "hasAnomalies" || key === "hasClashes") {
					gridColDef = {
						...defaultGridColDef,
						hideable: true,
						sortable: false,
					};
				}
				if (key === "primaryTag") {
					gridColDef = {
						...defaultGridColDef,
						hideable: false,
						sortable: true,
					};
				}
				if (key === "room") {
					gridColDef = {
						...defaultGridColDef,
						hideable: true,
						sortable: true,
					};
				}

				result.push({
					...gridColDef,
					field: key.toString(),
					headerName: item.headerName,
					minWidth: item.minWidth ?? dataGridFormatDefaultValues.minWidth,
					maxWidth: item.maxWidth ?? dataGridFormatDefaultValues.maxWidth,
					filterable: false,
					renderCell: (params: GridRenderCellParams) => customRenderCell(params, item.displayFormat, t),
				});
			});
			return result;
		};

		const GetGrid = (): IGridModel => {
			return {
				initialState: GridInitialState,
				columnsDefinition: GetGridColDefinition(schemeAndColumnTitles),
			};
		};
		if (data && schemeAndColumnTitles) {
			setDataGridModel(GetGrid());
		}
	}, [data, schemeAndColumnTitles, t]);

	const CustomPagination = () => {
		const apiRef = useGridApiContext();
		const page = useGridSelector(apiRef, gridPageSelector);
		return (
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
					handleChangePage(newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={extendedRowsPerPage}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		);
	};

	let clickTimeout: any = null;
	const handleClicks = (selectedRow: any) => {
		if (clickTimeout !== null) {
			// double click executed right after the second click
			handleDoubleClick?.(selectedRow);
			clearTimeout(clickTimeout);
			clickTimeout = null;
		} else {
			clickTimeout = setTimeout(() => {
				// single click executed after waiting for 500ms
				handleSingleClick?.(selectedRow);
				clearTimeout(clickTimeout);
				clickTimeout = null;
			}, 500);
		}
	};
	return (
		<Fragment>
			{dataGridModel && data && (
				<DataGridPro
					initialState={{
						...dataGridModel.initialState,
					}}
					autoHeight={true}
					checkboxSelection={false}
					columns={dataGridModel.columnsDefinition}
					rows={data}
					rowCount={totalRowCount}
					disableVirtualization
					density="compact"
					pagination={true}
					paginationMode="server"
					page={page}
					pageSize={pageSize}
					editMode={"row"}
					sortingMode="server"
					{...(i18next.language === "fr"
						? {
								localeText: frFR.components.MuiDataGrid.defaultProps.localeText,
							}
						: {})}
					onSortModelChange={handleChangeSortCriteria}
					onCellClick={(selectedCell) => {
						if (isDoubleClick) {
							handleClicks(selectedCell);
						} else {
							handleSingleClick?.(selectedCell);
						}
					}}
					components={{
						Pagination: CustomPagination,
					}}
					sx={{
						"& .MuiDataGrid-cell:hover": {
							cursor: "pointer",
						},
					}}
				/>
			)}
		</Fragment>
	);
};

export default K4ListGrid;
