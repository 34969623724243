import { Grid, Skeleton } from "@mui/material";

const BlocdSkeleton = () => {
	return (
		<Grid item xs={12} md={12} lg={12}>
			<Skeleton variant="rounded" height={150} />
		</Grid>
	);
};

type ClashesSkeletonProps = {
	quantity: number;
};
const ClashesSkeleton = (props: ClashesSkeletonProps) => {
	const { quantity } = props;
	return (
		<Grid container spacing={2} mt={5}>
			{[...Array(quantity).keys()].map((_, index) => (
				<BlocdSkeleton key={index} />
			))}
		</Grid>
	);
};

export default ClashesSkeleton;
