import "@application/design/styles/mails/mails.scss";

import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import EmailController from "@application/Controllers/EmailController";
import FacultyController from "@application/Controllers/FacultyController";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { type DtoMailMessage, EmailDetailDivider, NoData, queryStringSorts, useContextModule } from "@key4-front-library/core";
import { Skeleton, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { FacultyMailDetail } from "./FacultyMailDetail";
import { FacultyMailsList } from "./FacultyMailsList";

export const TabFacultyMails = () => {
	const { client, event } = useContextModule();
	const { participantId } = useParams();
	const [currentMail, setCurrentMail] = useState<DtoMailMessage | null>(null);

	const { data: participant } = useQuery({
		queryKey: ["faculty", participantId],
		queryFn: async () => await FacultyController.getFacultyDetails(client.id, event.id, participantId!).then((response) => response),
		enabled: !!participantId,
	});

	const facultyId = participant?.id;

	const { data } = useQuery({
		queryKey: ["mails", facultyId],
		queryFn: async () =>
			await EmailController.getEmails(client.id, event.id, facultyId, [...queryStringSorts(["-sendingDate"])]).then((response) => {
				response.data.forEach((mail: DtoMailMessage) => {
					mail.to = mail.to.replaceAll(";", ", ");
				});
				return response;
			}),
		enabled: !!facultyId,
	});

	const mails = data?.data;

	const handleClickMail = (id: string) => {
		const mail = mails?.find((x) => x.id === id);
		if (mail) {
			setCurrentMail(mail);
		} else {
			enqueueSnackbar(t("old.common.mail.notFound"), {
				variant: "error",
			});
		}
	};

	useEffect(() => {
		if (mails) {
			setCurrentMail(mails[0]);
		}
	}, [mails]);

	if (participant === undefined) {
		return <Skeleton height={210}></Skeleton>;
	}

	return (
		<>
			{mails && mails.length > 0 ? (
				<AppBox>
					<Stack spacing={2} direction={{ xs: "column", lg: "row" }} divider={<EmailDetailDivider />}>
						<Stack flex={3} width={"100%"} minWidth={"300px"} maxHeight={"600px"} display={{ overflowY: "auto" }}>
							<FacultyMailsList data={mails} onClickMail={handleClickMail} />
						</Stack>
						<Stack flex={9}>
							<FacultyMailDetail mail={currentMail} />
						</Stack>
					</Stack>
				</AppBox>
			) : (
				<Stack>
					<NoData />
				</Stack>
			)}
		</>
	);
};
