import { t } from "i18next";
import { uniqueId } from "lodash";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import ConfigurationsApp from "@application/Configurations";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ContainerExternalSessionLinkModal from "@application/Containers/ContainerExternalSessionLinkModal";
import ContainerSessionModal from "@application/Containers/ContainerSessionModal";
import SessionController from "@application/Controllers/SessionController";
import { EnumSessionFieldKey, EnumSessionFormKey } from "@application/Enums/SessionEnum";
import { dtoTokenToExternalLink } from "@application/components/sessions/GeneralView/GeneralView.mapper";
import OrganizedByView from "@application/components/sessions/OrganizedByView";
import PublishView from "@application/components/sessions/PublishView";
import SelectStatus from "@application/components/sessions/SelectStatus";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import Card from "@infrastructure/components/interface/card/Card";
import Loading from "@infrastructure/components/interface/loading/Loading";
import K4Tags from "@infrastructure/components/interface/tags/K4Tags";
import { isGuid } from "@infrastructure/services/api/guid";
import {
	Badge,
	type ButtonTitleProps,
	type ColorStandard,
	ConfirmationModal,
	Culture,
	Cultures,
	type DtoFaculty,
	ESieveOperator,
	EnumApiErrorStatus,
	EnumCustomFieldScope,
	ErrorAPI,
	ErrorTemplateOld,
	Services,
	Tab,
	Tabs,
	TitleOld,
	queryFilters,
	queryStringPagination,
	sessionStatus,
	statusSessionObject,
	useAuthenticationContext,
	useContextModule,
	useTabsRoute,
} from "@key4-front-library/core";
import { CustomFieldDefinition } from "@key4-front-library/core/Bo/Providers";
import { Box, Grid, Stack } from "@mui/material";

import { useAnomalies } from "../../../../Hooks/Anomaly";
import { useClashes } from "../../../../Hooks/Clash";
import { useSessionToken, useSessionView } from "../../../../Hooks/Session";

import type { TypeSessionExtraData } from "@application/Types/SessionType";
import type { ISessionView } from "@domain/interfaces/session.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
const tabs: Array<string> = ["general", "roomAndSchedule", "chairs", "presentations", "sessionInvitation", "clashes", "anomalies"];

export const PageSessionDetails = (): React.ReactNode => {
	const component = "old.common.sessionDetails";
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const { client, event } = useContextModule();
	const { active } = useTabsRoute(tabs);
	const { user } = useAuthenticationContext();

	const { sessionId = "" } = useParams();

	const [session, setSession] = useState<ISessionView>();
	const [numberOfPresentations, setNumberOfPresentations] = useState<number>(0);

	const [chairs, setChairs] = useState<Array<DtoFaculty>>([]);

	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [error, setError] = useState<ErrorAPI | undefined>();
	const [culture, setCulture] = useState<string>(Culture[0].culture);

	const [isOpenSessionModal, setIsOpenSessionModal] = useState(false);
	const [isOpenExternalLinkModal, setIsOpenExternalLinkModal] = useState(false);

	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const [listExtraData, setListExtraData] = useState<Array<TypeSessionExtraData>>();
	const [activeTabKey, setActiveTabKey] = useState<string>();

	const [activeClashesCounter, setActiveClashesCounter] = useState<number>(0);
	const [activeAnomaliesCounter, setActiveAnomaliesCounter] = useState<number>(0);

	const [externalLink, setExternalLink] = useState<string>();

	const sessionTokenQuery = useSessionToken(client.id, event.id, sessionId);
	const sessionViewQuery = useSessionView(client.id, event.id, sessionId);

	useEffect(() => {
		if (sessionTokenQuery.data?.[0]) {
			setExternalLink(dtoTokenToExternalLink(sessionTokenQuery.data[0], event.domain));
		}
	}, [sessionTokenQuery]);

	const defaultCurrentSession = {
		...ConfigurationsApp.SessionConfiguration.defaultModel2,
	};

	if (sessionId) defaultCurrentSession.id = sessionId;

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	useEffect(() => {
		const codeCulture = Cultures[user.locale].locale;
		setCulture(codeCulture);
	}, [t]);

	const actionsConfigurations = GetMenuActionsConfigurations();

	const editConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.EDIT);
	const cloneConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.CLONE);
	const deleteConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.DELETE);

	let iconId = 0;
	const titleIcons = [
		{
			name: "circle",
			color: session && statusSessionObject[session.sessionDisplay.status].bgColor,
			title: "",
			id: iconId++,
		},
	];

	const initComponent = useCallback(() => {
		if (!sessionId) return;

		if (!isGuid(sessionId)) {
			setError(new ErrorAPI(EnumApiErrorStatus.STATUS_404, "session not found"));

			return;
		}

		const fetchData = async () => {
			return {
				_session: await SessionController.getSessionDetails(client.id, event.id, sessionId, culture),
				_chairs: await SessionController.getChairs({
					clientId: client.id,
					sessionId,
					eventId: event.id,
					sorts: ["order"],
				}),
			};
		};

		fetchData().then((results) => {
			const { _session, _chairs } = results;
			if (_session instanceof ErrorAPI) {
				setError(_session);
				return;
			}
			if (_chairs instanceof ErrorAPI) {
				setError(_chairs);
				return;
			}
			setSession(_session);
			setChairs(_chairs);
			setIsApiLoading(false);
		});
	}, [sessionId, event, culture]);

	const clashesCounterQuery = useClashes({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: [
			...queryFilters(
				`ignoredDate${ESieveOperator.EQUALS}null${ESieveOperator.AND}entityType${ESieveOperator.EQUALS + EnumSessionFacultyClashesEntityType.ALL}`,
			),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		],
	});

	const anomaliesCounterQuery = useAnomalies({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: [
			...queryFilters(`onlyActive${ESieveOperator.EQUALS}true`),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		],
	});

	useEffect(() => {
		setActiveAnomaliesCounter(anomaliesCounterQuery.data?.pagination.totalCount ?? 0);
	}, [anomaliesCounterQuery.data]);

	useEffect(() => {
		setActiveClashesCounter(clashesCounterQuery.data?.pagination.totalCount ?? 0);
	}, [clashesCounterQuery.data]);

	const initBadgesCountersTabs = useCallback(() => {
		if (!sessionId) return;

		if (!isGuid(sessionId)) {
			setError(new ErrorAPI(EnumApiErrorStatus.STATUS_404, "session not found"));
			return;
		}

		const fetchData = async () => {
			return {
				_unactiveClashes: await Services.Events.Programme.SessionsService.getListPaginedClashes(client.id, event.id, sessionId, [
					...queryFilters(
						`ignoredDate${ESieveOperator.NOT_EQUALS}null${ESieveOperator.AND}entityType${ESieveOperator.EQUALS + EnumSessionFacultyClashesEntityType.ALL}`,
					),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
				_unactiveAnomalies: await Services.Events.Programme.SessionsService.getListPaginedAnomalies(client.id, event.id, sessionId, [
					...queryFilters(`onlyActive${ESieveOperator.EQUALS}false`),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
				_presentations: await Services.Events.Programme.SessionsService.getListPresentations(client.id, event.id, sessionId),
			};
		};

		fetchData().then((results) => {
			const { _unactiveClashes, _unactiveAnomalies, _presentations } = results;
			if (_unactiveClashes instanceof ErrorAPI) {
				setError(_unactiveClashes);
				return;
			}
			if (_unactiveAnomalies instanceof ErrorAPI) {
				setError(_unactiveAnomalies);
				return;
			}
			if (_presentations instanceof ErrorAPI) {
				setError(_presentations);
				return;
			}

			setNumberOfPresentations(_presentations.length);
		});
	}, [sessionId, event]);

	useEffect(() => {
		initComponent();
		initBadgesCountersTabs();
	}, [event, initBadgesCountersTabs, initComponent]);

	const handleModaleClose = (): void => {
		setOpenConfirmationModal(false);
	};

	const titleButtons: Array<ButtonTitleProps> = [
		{
			label: t("old.form.buttons.edit"),
			icon: { iconName: editConfig?.icon! },
			handleClick: () => session && handleOpenModaleSession(),
		},
	];

	const titleSettingsButtons = [
		{
			label: t("old.form.buttons.clone"),
			icon: { iconName: cloneConfig?.icon! },
			handleClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.CLONE,
					handleAction: () => {
						session && handleSessionClone(session.sessionUseForm.id ?? "");
					},
				};
				setOpenConfirmationModal(true);
			},
		},
		{
			label: t("old.form.buttons.delete"),
			icon: { iconName: deleteConfig?.icon! },
			handleClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.DELETE,
					handleAction: () => {
						session && handleDeleteSession(session.sessionUseForm.id ?? "");
					},
				};
				setOpenConfirmationModal(true);
			},
		},
		{
			label: t("old.form.buttons.externalEditingLink"),
			icon: { iconName: "arrow-up-right-from-square" as IconName },
			handleClick: () => {
				setIsOpenExternalLinkModal(true);
			},
			disabled: !!externalLink,
		},
	];

	const refreshSession = async () => {
		sessionViewQuery.refetch();
		if (!sessionId) return;
		const fetchData = async () => {
			return {
				_session: await SessionController.getSessionDetails(client.id, event.id, sessionId, culture),
			};
		};
		fetchData().then((results) => {
			const { _session } = results;
			if (_session instanceof ErrorAPI) {
				setError(_session);
				return;
			}
			setSession(_session);
		});
	};

	const handleOpenModaleSession = (newListExtraData?: Array<TypeSessionExtraData>, newActiveTabKey?: string) => {
		setActiveTabKey(newActiveTabKey);
		setListExtraData(newListExtraData);
		setIsOpenSessionModal(true);
	};

	const handleSessionCallbackSubmit = () => {
		refreshSession();
	};

	const handleDeleteSession = async (_id: string) => {
		if (_id) {
			try {
				await SessionController.deleteSession(client.id, event.id, _id);
				enqueueSnackbar(t("old.programme.sessions.create_edit_modale.snackbar.deleted"));
				navigate("..");
			} catch (_error: any) {
				enqueueSnackbar(_error.message, { variant: "error" });
			} finally {
				handleModaleClose();
			}
		}
	};

	const handleSessionClone = async (sessionId: string) => {
		await SessionController.cloneSession({
			clientId: client.id,
			eventId: event.id,
			sessionId,
		})
			.then(() => {
				enqueueSnackbar(<Box>{t("old.programme.sessions.create_edit_modale.snackbar.cloned")}</Box>, { variant: "success" });
			})
			.catch((error) => enqueueSnackbar(error.message, { variant: "error" }))
			.finally(() => {
				handleModaleClose();
			});
	};

	if (error || !sessionId) return <ErrorTemplateOld code={404} />;

	if (isApiLoading || !session) return <Loading />;

	const getMainTabsBadge = (numberToDisplay: number, color: ColorStandard = "secondary", opacity = "1") => {
		return (
			<Stack pl={0.5}>
				<Badge sx={{ opacity }} showZero badgeContent={numberToDisplay} color={color} />
			</Stack>
		);
	};

	const getMainTabsBadgeDisabled = () => getMainTabsBadge(0, "neutral", "0.3");

	const renderRightSide = () => {
		return (
			<Stack my={2} spacing={2}>
				<Card isHeader={false}>
					<Grid spacing=".3rem" container>
						<Grid item xs={12}>
							<PublishView
								data={session.publish}
								handleClick={() => {
									handleOpenModaleSession(
										session.publish.shortDateTime
											? undefined
											: [
													{
														key: EnumSessionFieldKey.PUBLICATION_DATE,
														value: DateTime.now(),
													},
												],
										EnumSessionFormKey.WORKFLOWTAGS,
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} mt=".5rem">
							<SelectStatus
								session={session.sessionDisplay}
								clientId={client.id}
								eventId={event.id}
								status={sessionStatus}
								defaultValue={session.sessionDisplay.status}
								refreshSession={refreshSession}
							/>
						</Grid>
					</Grid>
				</Card>
				{session.tags && (
					<Card title={t("old.programme.sessionDetails.tags.label")}>
						<Grid my="1rem" spacing=".3rem" container>
							<Grid item xs={12}>
								<K4Tags tags={session.tags} />
							</Grid>
						</Grid>
					</Card>
				)}
				{session.sessionDisplay.organizedBy && (
					<Card title={t("old.programme.sessionDetails.organizedBy.label")}>
						<Grid my="1rem" spacing=".3rem" container>
							<Grid item xs={12}>
								<OrganizedByView name={session.sessionDisplay.organizedBy} />
							</Grid>
						</Grid>
					</Card>
				)}
			</Stack>
		);
	};

	if (error) throw error;

	return (
		<>
			<TitleOld
				title={session.sessionDisplay.title ? session.sessionDisplay.title : ""}
				reference={session.sessionDisplay.code ? session.sessionDisplay.code : ""}
				buttons={titleButtons}
				settingsButtons={titleSettingsButtons}
				icons={titleIcons}
			/>

			<CustomFieldDefinition scope={[EnumCustomFieldScope.SESSION]}>
				<Grid container>
					<Grid item xs={12} md={9}>
						<Stack pr={2}>
							<Tabs value={active}>
								<Tab key={uniqueId("tab-")} label={t(`${component}.tab.${tabs[0]}`)} to={tabs[0]} />
								<Tab key={uniqueId("tab-")} label={t(`${component}.tab.${tabs[1]}`)} to={tabs[1]} />
								<Tab key={uniqueId("tab-")} label={t(`${component}.tab.${tabs[2]}`)} to={tabs[2]} icon={getMainTabsBadge(chairs.length)} iconPosition="end" />
								<Tab
									key={uniqueId("tab-")}
									label={t(`${component}.tab.${tabs[3]}`)}
									to={tabs[3]}
									icon={getMainTabsBadge(numberOfPresentations)}
									iconPosition="end"
								/>
								<Tab key={uniqueId("tab-")} label={t(`${component}.tab.${tabs[4]}`)} to={tabs[4]} />
								<Tab
									disabled={!activeClashesCounter}
									key={uniqueId("tab-")}
									label={t(`${component}.tab.${tabs[5]}`)}
									to={tabs[5]}
									icon={activeClashesCounter ? getMainTabsBadge(activeClashesCounter, "error") : getMainTabsBadgeDisabled()}
									iconPosition="end"
								/>
								<Tab
									disabled={!activeAnomaliesCounter}
									key={uniqueId("tab-")}
									label={t(`${component}.tab.${tabs[6]}`)}
									to={tabs[6]}
									icon={activeAnomaliesCounter ? getMainTabsBadge(activeAnomaliesCounter, "warning") : getMainTabsBadgeDisabled()}
									iconPosition="end"
								/>
							</Tabs>
							<Stack pt={2} pr={2}>
								<Outlet />
							</Stack>
						</Stack>
					</Grid>

					<Grid item xs={12} md={3} container>
						<Grid item xs={true} display={{ xs: "none", sm: "block" }}>
							{renderRightSide()}
						</Grid>
					</Grid>
				</Grid>
			</CustomFieldDefinition>
			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
			<ContainerSessionModal
				isOpen={isOpenSessionModal}
				changeIsOpen={setIsOpenSessionModal}
				callbackSubmit={handleSessionCallbackSubmit}
				id={sessionId}
				listExtraData={listExtraData}
				activeTabKey={activeTabKey}
			/>
			<ContainerExternalSessionLinkModal
				isOpen={isOpenExternalLinkModal}
				changeIsOpen={setIsOpenExternalLinkModal}
				sessionId={sessionId}
				refreshSession={sessionTokenQuery.refetch}
			/>
		</>
	);
};
