import { type SessionRead, type SessionReadV1, getSession, getSessionV1 } from "@api";
import { QUERY_KEYS_PROGRAMME, type UseQueryArgs, queryStringsVersion } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useSession = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionRead> = {}): UseQueryResult<SessionRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSession({
				clientId,
				eventId,
				sessionId,
				queryStrings: queryStringsVersion(queryStrings, "2.0"),
				signal,
			}),
		...others,
	});
};

export const useSessionV1 = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionReadV1> = {}): UseQueryResult<SessionReadV1> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "V1", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionV1({
				clientId,
				eventId,
				sessionId,
				queryStrings: queryStringsVersion(queryStrings, "1.0"),
				signal,
			}),
		...others,
	});
};
