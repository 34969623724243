import { EMenuPath, ExternalRouting, type IMenu } from "@key4-front-library/core";

const tNameSpace = "old.programme.navigation";

const Menu: IMenu = {
	items: [
		{
			translation: [tNameSpace, EMenuPath.DASHBOARD].join("."),
			path: EMenuPath.DASHBOARD,
			icon: {
				prefix: "far",
				iconName: "chart-line-up",
			},
		},

		{
			translation: [tNameSpace, EMenuPath.SESSIONS].join("."),
			path: EMenuPath.SESSIONS,
			icon: {
				prefix: "far",
				iconName: "clipboard-list",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.SCHEDULER].join("."),
			path: EMenuPath.SCHEDULER,
			icon: {
				prefix: "far",
				iconName: "calendar-circle-plus",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.FACULTIES].join("."),
			path: EMenuPath.FACULTIES,
			icon: {
				prefix: "far",
				iconName: "user-group",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.ROOMS].join("."),
			path: EMenuPath.ROOMS,
			icon: {
				prefix: "far",
				iconName: "screen-users",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.ALERTS].join("."),
			path: EMenuPath.ALERTS,
			icon: {
				prefix: "far",
				iconName: "bell",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.SETTINGS].join("."),
			path: EMenuPath.SETTINGS,
			icon: {
				prefix: "far",
				iconName: "gear",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.ADVANCED_SETTINGS].join("."),
			path: EMenuPath.ADVANCED_SETTINGS,
			icon: {
				prefix: "far",
				iconName: "screwdriver-wrench",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.USERS].join("."),
			path: EMenuPath.USERS,
			icon: {
				prefix: "far",
				iconName: "users",
			},
		},
		{ type: "divider" },
		{
			translation: [tNameSpace, EMenuPath.SUPPORT].join("."),
			path: EMenuPath.SUPPORT,
			icon: {
				prefix: "far",
				iconName: "headset",
			},
			externalLink: ExternalRouting.Support,
			target: "_blank",
		},
		{ type: "divider" },
	],
};

export default Menu;
