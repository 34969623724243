import K4Card from "@infrastructure/components/interface/card/K4Card";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import type { IK4Card } from "@key4-front-library/core";
import { Container, Grid, Skeleton, Stack, TablePagination } from "@mui/material";

type Props = {
	isLoading: boolean;
	page: number;
	pageSize: number;
	totalRowCount: number;
	handleChangePage: (page: number) => void;
	handleChangePageSize: (pageSize: number) => void;
	rowsPerPageOptions: Array<number>;
	menuItems: IMenuItems;
	data: Array<IK4Card> | undefined;
	handleCardSingleClick?: (data: IK4Card) => void;
	handleCardDoubleClick?: (data: IK4Card) => void;
};

const K4CardList = (props: Props) => {
	const {
		isLoading,
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		rowsPerPageOptions,
		menuItems,
		data,
		handleCardSingleClick,
		handleCardDoubleClick,
	} = props;

	if (isLoading) {
		return (
			<Container maxWidth={"xl"}>
				{[...Array(10).keys()].map((item) => (
					<Skeleton key={item} animation="wave" width={"xl"} height={50} />
				))}
			</Container>
		);
	}
	return (
		<Stack>
			<Grid container spacing="2rem" pb="2rem">
				{!isLoading &&
					data?.map((_data) => {
						return (
							<Grid key={_data.id} px={{ xs: "1.5rem", sm: "0" }} item xs={12} md={6} lg={4} xl={3}>
								<K4Card data={_data} menuItems={menuItems} handleCardSingleClick={handleCardSingleClick} handleCardDoubleClick={handleCardDoubleClick} />
							</Grid>
						);
					})}
			</Grid>
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
					handleChangePage(newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(event.target.value, 10));
				}}
				showFirstButton
				showLastButton
			/>
		</Stack>
	);
};

export default K4CardList;
