import { DateTime } from "luxon";

const ParseDate = (value: any, input: any, dateMaskFormat: string) => {
	if (value && !isNaN(Date.parse(value))) {
		return value;
	}
	if (input && !input.invalid) {
		return new Date(input.ts);
	}
	if (input && !input.invalid) {
		DateTime.fromFormat(input, dateMaskFormat, {
			zone: "utc",
		});
	}
	return null;
};

export const FormYupService = {
	ParseDate,
};
