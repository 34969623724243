import { t } from "i18next";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import SchedulerConfiguration from "@application/Configurations/scheduler.configuration";
import CalendarController from "@application/Controllers/CalendarController";
import SettingsController from "@application/Controllers/SettingsController";
import { ESchedulerView, type ICalendarSession, type ICalendarSettings } from "@domain/interfaces/calendar.interface";
import K4Calendar from "@infrastructure/components/calendar/K4Calendar";
import {
	AppBox,
	ApplicationColors,
	AutoCompleteParticipantOrContact,
	EnumThemeColor,
	ErrorAPI,
	listThemeColors,
	themeColors,
	TitleOld,
	type TypeParticipant,
	useContextModule,
	useIdentity,
	useLocalStorage,
} from "@key4-front-library/core";
import { FormGroup, lighten, Stack, Typography } from "@mui/material";

interface ICalendarSessionAugment extends ICalendarSession {
	nameColor: string;
	participantId: string;
}

export const FacultyCalendar = () => {
	const MAX_TAGS = 8;
	const [selectedFaculties, setSelectedFaculties] = useLocalStorage<Array<TypeParticipant>>("selectedFaculties", []);
	const { client, event } = useContextModule();

	const identity = useIdentity();

	const [calendarSettings, setCalendarSettings] = useState<ICalendarSettings>();
	const [sessions, setSessions] = useState<Array<ICalendarSessionAugment> | null>(null);

	const formMethods = useForm<any>({
		mode: "all",
		defaultValues: {},
	});

	useEffect(() => {
		getSessions(selectedFaculties);
	}, []);

	useEffect(() => {
		setSelectedFaculties(selectedFaculties);
	}, [selectedFaculties]);

	const onChangeFaculties = async (changedFaculties: Array<TypeParticipant>) => {
		getSessions(changedFaculties);
		setSelectedFaculties(changedFaculties);
	};

	// TODO: K4PROG-1788 - Add anonymous logic
	const mergeConflictsSessions = (sessions: Array<ICalendarSession>) => {
		sessions.sort((a, b) => new Date(String(a.start)).getTime() - new Date(String(b.start)).getTime());
		const mergedSessions: Array<ICalendarSession> = [];
		let currentSession: ICalendarSession | null = null;
		let currentEnd = new Date();

		for (const session of sessions) {
			if (!session.start || !session.end) {
				return;
			}
			const start = new Date(session.start);
			const end = new Date(session.end);
			if (!currentSession) {
				currentSession = { ...session };
				if (currentSession.end) {
					currentEnd = new Date(currentSession.end);
				}
			} else if (currentEnd && start <= currentEnd) {
				currentSession.end = moment(end > currentEnd ? end : currentEnd).format("YYYY-MM-DD HH:mm:ss");
			} else {
				mergedSessions.push(currentSession);
				currentSession = { ...session };
			}
		}

		if (currentSession) {
			mergedSessions.push(currentSession);
		}

		return mergedSessions;
	};

	const getSessions = useCallback(async (faculties: Array<TypeParticipant>) => {
		const settingsClashes = await SettingsController.getSettingsClashes(client.id, event.id);
		const dataCalendarSettings = await CalendarController.getCalendarSettings(client.id, event.id);

		if (dataCalendarSettings instanceof ErrorAPI) {
			return;
		}

		const facultiesIdList: Array<string> = [];
		faculties.forEach((faculty: any) => {
			facultiesIdList.push(faculty.id);
		});

		Promise.all(
			facultiesIdList.map((facultyId) => {
				if (settingsClashes instanceof ErrorAPI || dataCalendarSettings instanceof ErrorAPI) {
					return;
				}

				return CalendarController.getFacultySessions(client.id, event.id, facultyId, settingsClashes.isSpeakerBusySessionTimeSlot, dataCalendarSettings, true);
			}),
		).then((listSessions) => {
			const _sessions = listSessions.flatMap((sessions, index) => {
				if (sessions instanceof ErrorAPI) {
					return null;
				}
				return sessions
					?.map((session) => ({
						...session,
						...(listThemeColors[index] ? listThemeColors[index] : themeColors[EnumThemeColor.GREY]),
						participantId: faculties[index].id,
					}))
					.filter((session) => !session.allDay);
			});
			if (_sessions) {
				mergeConflictsSessions(_sessions as Array<ICalendarSessionAugment>);
				setSessions(_sessions as Array<ICalendarSessionAugment>);
			}
		});

		setCalendarSettings(dataCalendarSettings);
		setSelectedFaculties(faculties);
	}, []);

	return (
		<>
			<TitleOld title={t("old.programme.faculties.time-slots.search")} />
			<AppBox>
				<Stack>
					<FormProvider {...formMethods}>
						<FormGroup sx={{ pb: 2 }}>
							<Controller
								name={"AutoCompleteParticipantOrContact"}
								render={({ field, fieldState: { error } }) => (
									<AutoCompleteParticipantOrContact
										defaultValue={selectedFaculties}
										onChange={(faculty: any) => {
											onChangeFaculties(faculty);
										}}
										error={error?.message}
										field={field}
										areContactsActivated={false}
										isMultipleOptions={true}
										maxTagsNumber={MAX_TAGS}
									></AutoCompleteParticipantOrContact>
								)}
							/>
						</FormGroup>
					</FormProvider>

					<Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
						<Stack
							sx={{
								width: "40px",
								height: "20px",
								border: [ApplicationColors.grey.light!, "solid", "1px"].join(" "),
								backgroundImage: [
									"repeating-linear-gradient(",
									"-45deg,",
									"transparent 0px 5px,",
									[lighten(ApplicationColors.grey.light!, 0.5), " 2px 10px"].join(""),
									")",
								].join(""),
							}}
						></Stack>
						<Typography>: {t("old.programme.faculties.notAcceptedInvitation")}</Typography>
					</Stack>

					<Stack direction={"row"} mt={2} justifyContent={"flex-start"}>
						{calendarSettings && selectedFaculties && (
							<K4Calendar
								calendarSettings={calendarSettings}
								events={sessions ?? []}
								view={ESchedulerView.CALENDAR}
								views={{ calendar: SchedulerConfiguration.roomScheduler }}
								locale={identity.language}
								isReadOnly
							/>
						)}
					</Stack>
				</Stack>
			</AppBox>
		</>
	);
};
