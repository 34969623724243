import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import AnomalyDescription from "@application/components/_common/Anomalies/AnomalyDescription";
import IssuesDefinitions from "@application/Configurations/issues.configuration";
import AnomaliesFactory from "@application/factory/anomalies/anomaliesFactory";
import GetTranslationsForAnomalies from "@application/helpers/translations/anomalies/GetTranslationsForAnomalies";
import type { ISessionAnomaly } from "@domain/model/anomalies.model";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { ApplicationColors, useContextModule } from "@key4-front-library/core";
import { Card, CardContent, CardHeader, Chip, Stack, TextField, Tooltip } from "@mui/material";

type Props = {
	anomaly: ISessionAnomaly;
	index: number;
	onAnomalyUpdate: (clientId: string, eventId: string, anomaly: string, comments: string, isIgnored: boolean, snackbarMessage: string) => void;
};
const SessionAnomaly = (props: Props & dataCypressProp) => {
	const { anomaly, index, onAnomalyUpdate, dataCypressID } = props;
	// #region 'Definitions'
	const navigate = useNavigate();
	const { client, event } = useContextModule();
	const translations = GetTranslationsForAnomalies();

	const iconColorAnomaly = anomaly.ignoredDate ? ApplicationColors.greyStone.light : IssuesDefinitions.anomalies.themeColor.main;
	// #endregion 'Definitions

	// #region 'useForm'
	const formMethods = useForm<{ comments: string }>({
		defaultValues: {
			comments: anomaly.comments ?? "",
		},
	});
	const { handleSubmit: onSubmit, control } = formMethods;
	// #endregion 'useForm'

	// #region 'Handlers'
	const handleSubmitIsIgnored = (_isIgnored: boolean) => {
		event &&
			onAnomalyUpdate(
				client.id,
				event.id,
				anomaly.id,
				anomaly.comments ?? "",
				_isIgnored,
				_isIgnored ? translations.returnMessages.desactivated_anomaly : translations.returnMessages.activated_anomaly,
			);
	};

	const handleSubmitComments = ({ comments }: { comments: string }) => {
		event && onAnomalyUpdate(client.id, event.id, anomaly.id, comments, !!anomaly.ignoredDate, translations.returnMessages.success_comments);
	};
	// #endregion 'Handlers'

	// #region 'Rendering'
	return (
		<Card
			sx={{
				backgroundColor: anomaly.ignoredDate ? ApplicationColors.greyStone.light : IssuesDefinitions.anomalies.themeColor.contrastText,
			}}
		>
			<Stack spacing={1}>
				<CardHeader
					action={
						anomaly.ignoredDate ? (
							<Tooltip title={t("old.common.session_type.show")}>
								<Chip
									label={<Icon prefix="fal" iconName={"eye-slash"} size={"1x"} color={iconColorAnomaly} />}
									variant="outlined"
									onClick={() => {
										handleSubmitIsIgnored(false);
									}}
									sx={{
										border: `solid 1px ${iconColorAnomaly}`,
									}}
									data-cy={dataCypressID ? `${dataCypressID}-activate_button` : undefined}
								/>
							</Tooltip>
						) : (
							<Tooltip title={t("old.common.session_type.hide")}>
								<Chip
									label={<Icon prefix="fal" iconName={"eye"} size={"1x"} color={ApplicationColors.white.main} />}
									onClick={() => {
										handleSubmitIsIgnored(true);
									}}
									sx={{
										backgroundColor: iconColorAnomaly,
									}}
									data-cy={dataCypressID ? `${dataCypressID}-desactivate_button` : undefined}
								/>
							</Tooltip>
						)
					}
					title={
						client &&
						event && (
							<AnomalyDescription
								key={anomaly.id}
								item={AnomaliesFactory.TransformSessionAnomalyDataForReading({
									anomaly,
									translationsAnomalies: translations,
									clientKey: client.key,
									eventCode: event.key,
									navigate,
								})}
								index={index}
								styles={{
									iconColor: iconColorAnomaly,
									chipColor: iconColorAnomaly,
									textColor: iconColorAnomaly,
									boxBackgroundColor: anomaly.ignoredDate ? ApplicationColors.greyStone.light : IssuesDefinitions.anomalies.themeColor.contrastText,
								}}
								dataCypressID={dataCypressID}
							/>
						)
					}
				/>

				<CardContent>
					<Stack spacing={1} alignItems={"center"} direction={"row"}>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									placeholder={translations.comments}
									sx={{
										backgroundColor: ApplicationColors.white.main,
									}}
									multiline
									maxRows={4}
									data-cy={dataCypressID ? `${dataCypressID}-comments_input` : undefined}
								/>
							)}
							name="comments"
							control={control}
						/>
						<Tooltip title={t("old.common.session_type.saveComment")}>
							<Chip
								label={<Icon prefix="far" iconName={"check"} size={"1x"} color={ApplicationColors.white.main} />}
								onClick={onSubmit(handleSubmitComments)}
								sx={{
									backgroundColor: iconColorAnomaly,
								}}
								data-cy={dataCypressID ? `${dataCypressID}-comments_button` : undefined}
							/>
						</Tooltip>
					</Stack>
				</CardContent>
			</Stack>
		</Card>
	);
	// #endregion 'Rendering'
};

export default SessionAnomaly;
