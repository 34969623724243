import HelpersApp from "@application/helpers";
import {
	ESieveOperator,
	getProgrammeExportFileExtension,
	type IEntity,
	queryFilters,
	queryStringSorts,
	Services,
	type TypeUseFormListForms,
	useContextModule,
} from "@key4-front-library/core";
import type { DtoDocument, DtoProgrammeExport } from "@key4-front-library/core/Dto";
import type { TypeApiResponsePagined } from "@key4-front-library/core/Types";

const useEntity = () => {
	const { client, event } = useContextModule();

	const read = async (programmeExportId: string): Promise<DtoDocument> => await Services.Operations.DocumentService.get(client.id, event.id, programmeExportId);

	const readList = async (): Promise<TypeApiResponsePagined<Array<DtoDocument>>> =>
		await Services.Operations.DocumentService.getListPagined(client.id, event.id, [
			...queryFilters(["type", ESieveOperator.EQUALS, "programmeExport"].join("")),
			...queryStringSorts(["name"]),
		]);

	const create = async (useFormData: TypeUseFormListForms): Promise<IEntity> => {
		const programmeExport: DtoProgrammeExport = HelpersApp.ProgrammeExportHelper.mapUseFormToDtoProgrammeExport(useFormData);
		return await Services.Programme.Clients.ProgrammeExportService.postProgrammeExport(client.id, event.id, programmeExport);
	};

	const update = async (id: string, useFormData: TypeUseFormListForms): Promise<boolean> => {
		const programmeExport: DtoProgrammeExport = HelpersApp.ProgrammeExportHelper.mapUseFormToDtoProgrammeExport(useFormData);
		return await Services.Programme.Clients.ProgrammeExportService.putProgrammeExport(client.id, event.id, id, programmeExport);
	};

	const cloneEntity = async (id: string): Promise<IEntity> => {
		return await Services.Operations.DocumentService.get(client.id, event.id, id).then(async (initialProgrammeExport) => {
			return await Services.Programme.Clients.ProgrammeExportService.postProgrammeExport(
				client.id,
				event.id,
				HelpersApp.ProgrammeExportHelper.mapDtoDocumenToDtoProgrammeExportForCloning(initialProgrammeExport),
			);
		});
	};

	const deleteEntity = async (id: string): Promise<boolean> => await Services.Operations.DocumentService.deleteEntity(client.id, event.id, id);

	const getExport = async (id: string) => {
		await read(id).then((document) => {
			const configuration =
				typeof document.configuration === "string"
					? HelpersApp.ProgrammeExportHelper.mapDtoDocumentContigurationToDtoProgrammeExportConfiguration(document.configuration)
					: null;
			const fileExtention = getProgrammeExportFileExtension(configuration?.format);
			const fileName = [configuration?.filename, fileExtention].join(".");
			return Services.Programme.Clients.ProgrammeExportService.getProgrammeExport(client.id, event.id, id, fileName);
		});
	};

	return {
		read,
		readList,
		create,
		update,
		cloneEntity,
		deleteEntity,
		getExport,
	};
};

const ProgrammeExportController = {
	useEntity,
};

export default ProgrammeExportController;
