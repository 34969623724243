import { Fragment, useState } from "react";

import SettingsSchedulerEdit, { type ISettingsSchedulerEditType } from "@application/components/settings/scheduler/scheduler/SettingsSchedulerEdit";
import type { ISettingsSchedulerObject } from "@domain/interfaces/settings.scheduler.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { Grid } from "@mui/material";

type Props = {
	title: string;
	icon: IconName;
	settings: ISettingsSchedulerObject;
	culture: string;
	translations: any;
	saveSettings: (data: ISettingsSchedulerEditType) => void;
};
const SettingsSchedulerView = (props: Props) => {
	const { title, icon, settings, translations, culture, saveSettings } = props;

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleEditModaleOpen = (): void => {
		setModaleOpenState(true);
	};
	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	return (
		<Fragment>
			<IconCard
				title={title}
				icon={icon}
				children={
					<Grid container spacing={3}>
						<IconCardItem label={translations.scheduler.settings.startDate} value={settings.formatedValues.programmeStartDate} />
						<IconCardItem label={translations.scheduler.settings.startTime} value={settings.formatedValues.schedulerStartHour} />
						<IconCardItem
							label={translations.scheduler.settings.dayTimeSlot}
							value={`${settings.time.interval} ${settings.time.interval! > 1 ? translations.unities.hours : translations.unities.hour}`}
						/>
						<IconCardItem label={translations.scheduler.settings.endDate} value={settings.formatedValues.programmeEndDate} />
						<IconCardItem label={translations.scheduler.settings.endTime} value={settings.formatedValues.schedulerEndHour} />
						<IconCardItem
							label={translations.scheduler.settings.programmeDuration}
							value={`${settings.dates.interval} ${settings.dates.interval! > 1 ? translations.unities.days : translations.unities.day}`}
						/>
					</Grid>
				}
				button={{
					label: translations.button,
					icon: "pen-to-square" as IconName,
					onClick: handleEditModaleOpen,
				}}
			/>
			<DefaultModal open={modaleOpenState} title={translations.scheduler.title}>
				<SettingsSchedulerEdit
					settings={settings}
					culture={culture}
					translations={translations}
					onSave={saveSettings}
					handleModaleClose={handleEditModaleClose}
				/>
			</DefaultModal>
		</Fragment>
	);
};

export default SettingsSchedulerView;
