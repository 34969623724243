import { Fragment, useEffect } from "react";

import DashboardBarChart from "@application/components/dashboard/dashboardDataComponents/DashboardBarChart";
import DashboardFacultiesIndicator from "@application/components/dashboard/dashboardDataComponents/DashboardFacultiesIndicator";
import DashboardInvitationsDonutChart from "@application/components/dashboard/dashboardDataComponents/DashboardInvitationsDonutChart";
import DashboardAlerts from "@application/components/dashboard/dashboardDataComponents/DashboardIssues";
import DashboardProgrammeIndicator from "@application/components/dashboard/dashboardDataComponents/DashboardProgrammeIndicator";
import DashboardPublicationsDonutChart from "@application/components/dashboard/dashboardDataComponents/DashboardPublicationsDonutChart";
import DashboardSessionsDonutChart from "@application/components/dashboard/dashboardDataComponents/DashboardSessionsDonutChart";
import PeriodContainer from "@infrastructure/components/interface/period/PeriodContainer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { TitleOld, useContextModule } from "@key4-front-library/core";
import { Grid, Stack } from "@mui/material";

const Dashboard = () => {
	const { trackPageView } = useMatomo();

	useEffect(() => {
		trackPageView();
	}, [trackPageView]);

	const { event } = useContextModule();

	return (
		<Fragment>
			<Stack>
				<TitleOld title={event.name ?? ""} reference={<PeriodContainer dateStartIso={event.startDate} dateEndIso={event.endDate} />} />
			</Stack>
			<Grid container spacing="30px">
				<DashboardBarChart />
				<DashboardProgrammeIndicator />
				<DashboardFacultiesIndicator />
				<DashboardSessionsDonutChart />
				<DashboardInvitationsDonutChart />
				<DashboardPublicationsDonutChart />
				<DashboardAlerts />
			</Grid>
		</Fragment>
	);
};

export default Dashboard;
