import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import type { IconName } from "@fortawesome/fontawesome-svg-core";

interface IMenuActionsConfiguration {
	reference: MenuActionsReference;
	icon: IconName;
}

type IMenuActionsConfigurations = Array<IMenuActionsConfiguration>;

export const GetMenuActionsConfigurations = (): IMenuActionsConfigurations => [
	{
		reference: MenuActionsReference.ADD,
		icon: "plus",
	},
	{
		reference: MenuActionsReference.EDIT,
		icon: "pen-to-square",
	},
	{
		reference: MenuActionsReference.DELETE,
		icon: "trash-can",
	},
	{
		reference: MenuActionsReference.CLONE,
		icon: "clone",
	},
	{
		reference: MenuActionsReference.DRAG_AND_DROP,
		icon: "grip-dots-vertical",
	},
	{
		reference: MenuActionsReference.MENU,
		icon: "ellipsis-vertical",
	},
	{
		reference: MenuActionsReference.UP,
		icon: "up-long",
	},
	{
		reference: MenuActionsReference.DOWN,
		icon: "down-long",
	},
	{
		reference: MenuActionsReference.DETAILS,
		icon: "magnifying-glass",
	},
	{
		reference: MenuActionsReference.REDIRECTION,
		icon: "up-right-from-square",
	},
	{
		reference: MenuActionsReference.FILTER,
		icon: "filter",
	},
];
