import { type TagTypeRead, getTagTypes } from "@api";
import { type Paginated, QUERY_KEYS_PROGRAMME, type UseQueryArgs, queryStringsVersion } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useTagTypes = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<TagTypeRead>> = {}): UseQueryResult<Paginated<TagTypeRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.tags, ...queryKeyPart, "Get", clientId, eventId, queryStrings],
		queryFn: ({ signal }) =>
			getTagTypes({
				clientId,
				eventId,
				queryStrings: queryStringsVersion(queryStrings, "2.0"),
				signal,
			}),
		...others,
	});
};
