import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ClashesFilters from "@application/components/_common/Clashes/ClashesFilters";
import ClashesSkeleton from "@application/components/_common/Clashes/ClashesSkeleton";
import type { ISessionAnomaly } from "@domain/model/anomalies.model";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import { ESieveOperator, NoData, QUERY_KEYS_PROGRAMME, queryFilters, queryStringPagination, Services } from "@key4-front-library/core";
import { useContextModule, useNotificationContext } from "@key4-front-library/core/Contexts";
import { Stack } from "@mui/material";
import SessionAnomaly from "@presentation/pages/sessions/SessionDetails/anomalies/SessionAnomaly";
import { useQueryClient } from "@tanstack/react-query";

const SessionAnomalies = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { client, event } = useContextModule();
	const { fetchCounterActiveAnomalies } = useNotificationContext();
	const { sessionId } = useParams();
	const queryClient = useQueryClient();

	const [anomalies, setAnomalies] = useState<Array<ISessionAnomaly>>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const fetchAnomalies = useCallback(
		(clientId: string, eventId: string, sessionId: string, isOnlyActive: boolean) => {
			setIsLoading(true);
			Services.Events.Programme.SessionsService.getListPaginedAnomalies(clientId, eventId, sessionId, [
				...queryFilters(`onlyActive${ESieveOperator.EQUALS}${isOnlyActive}`),
				...queryStringPagination({
					page: 0,
					pageSize: 100,
				}),
			])
				.then((_response: any) => {
					const { data } = _response;
					setAnomalies(data);
					void queryClient.invalidateQueries({
						queryKey: [...QUERY_KEYS_PROGRAMME.anomalies, "getList", client.id, eventId, sessionId],
					});
				})
				.catch((_error: IErrorMessageReturn) => {
					enqueueSnackbar(_error.message, {
						variant: "error",
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[enqueueSnackbar, fetchCounterActiveAnomalies],
	);
	useEffect(() => {
		if (sessionId) {
			fetchAnomalies(client.id, event.id, sessionId, isOnlyActive);
		}
	}, [client, event, enqueueSnackbar, sessionId, isOnlyActive, fetchAnomalies]);

	const handleIsOnlyActiveChange = useCallback(() => {
		setIsOnlyActive((prev) => !prev);
	}, []);

	const handleAnomalyUpdate = useCallback(
		(clientId: string, eventId: string, anomalyId: string, comments: string, isIgnored: boolean, snackbarMessage?: string) => {
			Services.Events.Programme.AnomaliesService.patch(clientId, eventId, anomalyId, {
				comments,
				isIgnored,
			})
				.then(() => {
					if (sessionId) {
						fetchAnomalies(client.id, event.id, sessionId, isOnlyActive);
						enqueueSnackbar(snackbarMessage, {
							variant: "success",
						});
					}
				})
				.catch((_error: IErrorMessageReturn) => {
					enqueueSnackbar(_error.message, {
						variant: "error",
					});
				});
		},
		[sessionId, fetchAnomalies, client.id, event.id, isOnlyActive, enqueueSnackbar],
	);

	return (
		<AppBox>
			<Stack spacing={2}>
				<ClashesFilters isOnlyActive={isOnlyActive} OnIsOnlyActiveChange={handleIsOnlyActiveChange} isGroupButtons={false} />
				{isLoading ? (
					<ClashesSkeleton quantity={5} />
				) : (
					<Stack spacing={2}>
						{anomalies && anomalies.length > 0 ? (
							anomalies.map((anomaly, index) => {
								return <SessionAnomaly key={anomaly.id} anomaly={anomaly} index={index} onAnomalyUpdate={handleAnomalyUpdate} />;
							})
						) : (
							<NoData />
						)}
					</Stack>
				)}
			</Stack>
		</AppBox>
	);
};

export default SessionAnomalies;
