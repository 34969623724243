import type { MouseEventHandler } from "react";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Button } from "@mui/material";

interface Props {
	label: string;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonSave = (props: Props & dataCypressProp) => {
	const { label, isDisabled = false, onClick, dataCypressID } = props;
	return (
		<Button
			color={"success"}
			disabled={isDisabled}
			onClick={onClick}
			variant="contained"
			data-cy={dataCypressID}
			startIcon={<Icon prefix="fal" iconName={"save"} size={"1x"} />}
		>
			{label}
		</Button>
	);
};

export default K4ButtonSave;
