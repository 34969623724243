export enum EConfirmationModalAction {
	EDIT = "edit",
	CREATE = "create",
	DELETE = "delete",
	CLONE = "clone",
}
export enum EFormAction {
	EDIT = "edit",
	CREATE = "create",
	DELETE = "delete",
}
