import { t } from "i18next";

const GetTranslationsForFaculties = () => {
	return {
		title: t(`old.programme.faculties.title`),
		sort: {
			id: t(`old.programme.faculties.sort.id`),
			firstname: t(`old.programme.faculties.sort.firstname`),
			lastname: t(`old.programme.faculties.sort.lastname`),
			profile: t(`old.programme.faculties.sort.profile`),
		},
	};
};

export default GetTranslationsForFaculties;
