import type { Reducer } from "react";

import type ISelectionState from "@application/components/_common/QuickSearch/interface/ISelectionState.interface";
import type TQuickSearchReducerAction from "@application/components/_common/QuickSearch/model/TQuickSearchReducerAction";
import { EQuickSearchReducerAction } from "@application/components/_common/QuickSearch/model/TQuickSearchReducerAction";

const QuickSearchReducer: Reducer<ISelectionState[], TQuickSearchReducerAction> = (state = [], action) => {
	switch (action.type) {
		case EQuickSearchReducerAction.SELECT:
			return [
				...state.filter((selection) => selection.id !== action.id),
				{
					id: action.id,
					searchKey: action.searchKey!,
					selection: action.selection!,
				},
			];

		case EQuickSearchReducerAction.CLEAR:
			return [...state.filter((selection) => selection.id !== action.id)];

		case EQuickSearchReducerAction.CLEAR_ALL:
			return [];

		default:
			return state;
	}
};

export default QuickSearchReducer;
