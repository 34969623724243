import { t } from "i18next";
import update from "immutability-helper";
import { uniqueId } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import type { ISettingsSessionSpeakerQuality } from "@domain/interfaces/settings.session-speaker-quality.interface copy";
import type { ISpeakerQualityModel } from "@domain/model/speakerQuality.model";
import IconCard from "@infrastructure/components/interface/card/IconCardWithButton";
import type { IErrorMessageReturn } from "@infrastructure/model/interfaces/api/api-errors-message-return.interface";
import { type IEntity, NoData, Services, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import { Skeleton, Stack } from "@mui/material";
import SettingsSpeakerQualityEdit from "@presentation/pages/advancedSettings/speakersTypes/AdvancedSettingsSpeakerQualityEdit";

import SettingsSpeakerQualityRender from "./AdvancedSettingsSpeakerQualityRender";

export const AdvancedSettingsSpeakersTypes = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [currentSpeakerQuality, setCurrentSpeakerQuality] = useState<ISpeakerQualityModel | null>(null);
	const [speakerQualities, setSpeakerQualities] = useState<any>(null);
	const [isLoadingSpeakerQualities, setIsLoadingSpeakerQualities] = useState<boolean>(false);
	const [sortedSpeakerQualities, setSortedSpeakerQualities] = useState<Array<ISpeakerQualityModel>>([]);

	const [modaleOpenState, setModaleOpenState] = useState<boolean>(false);

	const handleTopButtonOpenModalSpeakerQualityAdd = () => {
		setCurrentSpeakerQuality(null);
		setModaleOpenState(true);
	};

	const handleEditModaleClose = (): void => {
		setModaleOpenState(false);
	};

	const handleDeleteSpeakerQuality = async (tagTypeId: string, tagId: string) => {
		await Services.Events.Programme.TagsService.deleteEntity(client.id, event.id, tagTypeId, tagId)
			.then(() => {
				setSpeakerQualities(speakerQualities?.filter((x: any) => x.id !== tagId));
				sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_deletion"));
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			});
	};

	const handleOrderSpeakerQualities = async (orderIds: Array<string>) => {
		await Services.Events.Programme.SpeakersService.putQualityReOrder(client.id, event.id, { ids: orderIds }).catch(() => {
			sendError(t("old.common.errors.generic"));
		});
	};

	const handleUpdateSpeakerQuality = async (_data: ISettingsSessionSpeakerQuality) => {
		if (!currentSpeakerQuality) {
			await Services.Events.Programme.SpeakersService.postQuality(client.id, event.id, _data)
				.then((_response: IEntity) => {
					setSpeakerQualities([...speakerQualities!, { ..._data, id: _response.id }]);
					sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_creation"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		} else {
			await Services.Events.Programme.TagsService.put(client.id, event.id, currentSpeakerQuality.id, currentSpeakerQuality.id, _data)
				.then((_response: boolean | IErrorMessageReturn) => {
					setSpeakerQualities(speakerQualities?.map((x: any) => (x.id !== currentSpeakerQuality.id ? x : { ..._data, id: currentSpeakerQuality.id })));
					sendSuccess(t("old.programme.advancedSettings.speakers.returnMessages.success_modification"));
				})
				.catch(() => {
					sendError(t("old.common.errors.generic"));
				});
		}
	};

	const moveCard = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setSortedSpeakerQualities((prevCards: Array<ISpeakerQualityModel>) => {
				const newOrder = update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex]],
					],
				});
				handleOrderSpeakerQualities(newOrder.map((x) => x.id));
				return newOrder;
			});
		},
		[handleOrderSpeakerQualities],
	);

	const fetchSpeakerQualityData = async (clientId: string, eventId: string) => {
		setIsLoadingSpeakerQualities(true);
		await Services.Events.Programme.SpeakersService.getListQualities(clientId, eventId)
			.then((_data: Array<any>) => {
				setSpeakerQualities(_data);
			})
			.catch(() => {
				sendError(t("old.common.errors.generic"));
			})
			.finally(() => {
				setIsLoadingSpeakerQualities(false);
			});
	};

	const renderSpeakerQuality = useCallback(
		(_speakerQuality: ISpeakerQualityModel, index: number) => {
			const handleOpenModalSpeakerQualityEdit = (_entity: ISpeakerQualityModel) => {
				setCurrentSpeakerQuality(_entity);
				setModaleOpenState(true);
			};

			return (
				<DndProvider backend={HTML5Backend} key={uniqueId()}>
					<Stack spacing={2} key={_speakerQuality.id}>
						<SettingsSpeakerQualityRender
							index={index}
							speakerQuality={_speakerQuality}
							speakerQualitiesCount={speakerQualities.length}
							handleOpenModalSpeakerQualityEdit={handleOpenModalSpeakerQualityEdit}
							handleDeleteSpeakerQuality={handleDeleteSpeakerQuality}
							moveCard={moveCard}
						/>
					</Stack>
				</DndProvider>
			);
		},
		[moveCard, speakerQualities?.length, handleDeleteSpeakerQuality, setCurrentSpeakerQuality],
	);

	useEffect(() => {
		setSortedSpeakerQualities(speakerQualities ?? []);
	}, [speakerQualities]);

	useEffect(() => {
		fetchSpeakerQualityData(client.id, event.id);
	}, []);

	const renderIconCardChildren = () => {
		if (!sortedSpeakerQualities.length) {
			return <NoData></NoData>;
		}
		return (
			<Stack spacing={2} key={uniqueId()}>
				{sortedSpeakerQualities.map((speakerQuality, index) => renderSpeakerQuality(speakerQuality, index))}
			</Stack>
		);
	};

	return (
		<>
			<IconCard
				title={t("old.programme.advancedSettings.speakers.title")}
				icon={"chalkboard-user"}
				children={isLoadingSpeakerQualities ? <Skeleton height={200}></Skeleton> : renderIconCardChildren()}
				button={{
					label: t("old.form.buttons.add"),
					icon: "plus",
					onClick: handleTopButtonOpenModalSpeakerQualityAdd,
				}}
			/>

			<DefaultModal
				open={modaleOpenState}
				title={
					currentSpeakerQuality ? t("old.programme.advancedSettings.speakers.modale.edition") : t("old.programme.advancedSettings.speakers.modale.creation")
				}
			>
				<SettingsSpeakerQualityEdit
					speakerQuality={currentSpeakerQuality}
					updateSpeakerQuality={handleUpdateSpeakerQuality}
					editModaleClose={handleEditModaleClose}
				/>
			</DefaultModal>
		</>
	);
};
