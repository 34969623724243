import { t } from "i18next";
import type { MouseEventHandler } from "react";

import K4ButtonClearAll from "@infrastructure/components/interface/button/K4ButtonClearAll";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";

interface Props {
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonClearAllDecorated = (props: Props & dataCypressProp) => {
	const { isDisabled = false, onClick, dataCypressID } = props;
	return <K4ButtonClearAll label={t("old.common.button.clearAll")} isDisabled={isDisabled} onClick={onClick} dataCypressID={dataCypressID} />;
};

export default K4ButtonClearAllDecorated;
