import type { UserInformationWithMetadata } from "@application/Types";
import type { DtoParticipantGet } from "@key4-front-library/core";

export const participantDtoListToUserInformationList = (participants?: Array<DtoParticipantGet>): Array<UserInformationWithMetadata> => {
	return (
		participants?.map((participant) => {
			return {
				id: participant.id,
				type: "participant",
				user: {
					email: participant.email ?? undefined,
					lastname: participant.lastname ?? undefined,
					firstname: participant.firstname ?? undefined,
					link: { children: participant.key },
				},
			};
		}) ?? []
	);
};
