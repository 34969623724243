import { t } from "i18next";
import { useEffect, useState } from "react";

import type { TRoomView } from "@domain/interfaces/calendar.interface";
import { Autocomplete, Stack, TextField } from "@mui/material";

interface IHeaderCalendarRoomProps {
	roomId: string;
	rooms: Array<TRoomView>;
	changeRoom: (roomId: string) => void;
}

const CalendarSelectRoom = ({ roomId, rooms, changeRoom }: IHeaderCalendarRoomProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const selectedRoom = rooms.find((room) => room.id === roomId);

	useEffect(() => {
		setIsLoading(false);
	}, [rooms, isLoading]);

	return (
		<Stack direction="row" alignItems={"center"} justifyContent={"center"} mb="1.5em" mx="1em">
			<Autocomplete
				options={rooms}
				getOptionLabel={(option: TRoomView) => option.title}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				fullWidth
				value={selectedRoom}
				onChange={(_e, newValue) => {
					if (newValue) {
						setIsLoading(true);
						changeRoom(newValue.id);
					}
				}}
				renderInput={(params) => <TextField {...params} label={t("old.programme.scheduler.roomsSelect")} />}
			/>
		</Stack>
	);
};

export default CalendarSelectRoom;
