import { DateTime, Duration } from "luxon";

import type { TypePresentationSchedule } from "@application/hooks";
import type { ICalendarSettings } from "@domain/interfaces/calendar.interface";
import DateService from "@infrastructure/services/dates/date.service";

const formatCalendarHours = (hourStart: string, hourEnd: string) => {
	let dtStart = DateTime.fromISO(hourStart, { zone: "utc" });
	let dtEnd = DateTime.fromISO(hourEnd, { zone: "utc" });

	dtStart = dtStart.set({ minute: 0 });
	if (dtEnd.minute > 0) {
		dtEnd = dtEnd.plus({ hour: 1 }).set({ minute: 0 });
	}

	return {
		hourStart: dtStart,
		hourEnd: dtEnd,
	};
};

const getCalendarProgrammeSettings = (model: any): ICalendarSettings => {
	const schedulerHours = formatCalendarHours(model.schedulerStartHour, model.schedulerEndHour);
	const dateStart = DateTime.fromISO(model.programmeStartDate, { zone: "utc" });
	const dateEnd = DateTime.fromISO(model.programmeEndDate, { zone: "utc" });

	return {
		date: {
			start: dateStart,
			end: dateEnd,
		},
		hour: {
			start: schedulerHours.hourStart,
			end: schedulerHours.hourEnd,
		},
		dateDurationDays: getDurationDays(dateStart, dateEnd),
	};
};

const getCalendarWholeProgrammeDateSession = (calendarSettings: ICalendarSettings) => {
	return {
		hourStart: calendarSettings.hour.start.toLocaleString(DateTime.TIME_24_SIMPLE),
		hourEnd: calendarSettings.hour.end.toLocaleString(DateTime.TIME_24_SIMPLE),
		start: `${calendarSettings.date.start.toFormat("yyyy-MM-dd")} ${calendarSettings.hour.start.toLocaleString(DateTime.TIME_24_SIMPLE)}:00`,
		end: `${calendarSettings.date.end.plus({ day: 1 }).toFormat("yyyy-MM-dd")} ${calendarSettings.hour.end.toLocaleString(DateTime.TIME_24_SIMPLE)}:00`,
	};
};

// get duration for Scheduler
const getDurationDays = (start: DateTime, end: DateTime) => {
	return Math.ceil(Math.abs(start.diff(end, ["days"]).toObject().days ?? 0));
};

const setDurationAndDatesSession = (dateStart: DateTime, dateEnd: DateTime, minDuration: number | string | null) => {
	let minDurationToNumberFormat = 0;

	if (minDuration) {
		if (typeof minDuration === "string") {
			minDurationToNumberFormat = Number.parseInt(Duration.fromISO(minDuration).toFormat("mm"));
		} else {
			minDurationToNumberFormat = minDuration;
		}
	}

	const minutesDiff = dateEnd.diff(dateStart, ["minutes"]).toObject().minutes ?? 0;

	if (minutesDiff >= minDurationToNumberFormat) {
		return {
			dateStart,
			dateEnd,
			duration: Duration.fromObject({ minutes: minutesDiff }).toFormat("hh:mm:ss"),
		};
	}
	return {
		dateStart,
		dateEnd: dateStart.plus({ minutes: minDurationToNumberFormat }),
		duration: Duration.fromObject({
			minutes: minDurationToNumberFormat,
		}).toFormat("hh:mm:ss"),
	};
};

const isDurationEligible = (dateStart: DateTime, dateEnd: DateTime, minDuration: number | null): boolean => {
	if (!minDuration) return true;
	let minDurationToNumberFormat = 0;

	if (minDuration) {
		if (typeof minDuration === "string") {
			minDurationToNumberFormat = Number.parseInt(Duration.fromISO(minDuration).toFormat("mm"));
		} else {
			minDurationToNumberFormat = minDuration;
		}
	}

	const minutesDiff = dateEnd.diff(dateStart, ["minutes"]).toObject().minutes ?? 0;
	return minutesDiff >= minDurationToNumberFormat;
};

const durationSuggestionOptionsHours = (hoursRange: number, stepsInMinutes: number): Array<string> => {
	const NumberOfStepByHours = 60 / stepsInMinutes;
	const today = DateTime.now().toUTC().set({ hour: 0, minute: 0 });
	return [...Array(NumberOfStepByHours * hoursRange)].map((_, index) =>
		DateService.Format.TimeSimple({
			date: today.plus({ minutes: (index + 1) * stepsInMinutes }),
		}),
	);
};

const durationSuggestionOptions = (hoursRange: number, stepsInMinutes: number): Array<string> => {
	let duration = Duration.fromMillis(0);
	return ["00:00", ...Array((60 / stepsInMinutes) * hoursRange - 1)].map(() => {
		duration = duration.plus({ minutes: stepsInMinutes });

		return duration.toFormat("hh:mm");
	});
};

const getDatesListRange = (dateStart: string, dateEnd: string) => {
	const startDate = DateService.GetDateFromIsoString(dateStart);
	const endDate = DateService.GetDateFromIsoString(dateEnd);

	const difference = DateService.Differences.DateDiffDays({
		startDate,
		endDate,
	});

	const dateList = [];
	if (difference) {
		for (let i = 0; i < difference; i++) {
			const date = startDate?.plus({ days: i }) ?? DateTime.now();

			dateList.push({
				key: DateService.Format.IsoStringDate({ date }),
				label: DateService.Format.ShortDate({
					date,
				}),
			});
		}
	}
	return dateList;
};

export function getDateTimeFromPartialInformation(
	date: string | undefined | null,
	hour: string | undefined | null,
): { date: DateTime | null; dateType: TypePresentationSchedule } {
	if (date && hour) {
		return {
			date: DateTime.fromISO(`${date}T${hour}`, { zone: "UTC" }),
			dateType: "FullDate",
		};
	}
	if (date) {
		return {
			date: DateTime.fromISO(date, { zone: "UTC" }),
			dateType: "DateOnly",
		};
	}
	if (hour) {
		return {
			date: DateTime.fromISO(hour, { zone: "UTC" }),
			dateType: "HourOnly",
		};
	}
	return { date: null, dateType: "None" };
}

export function getHourAndDateFromIsoString(isoString: string): {
	date: string;
	hour: string;
} {
	const dateSplit = isoString.split("T");
	return { hour: dateSplit[1], date: dateSplit[0] };
}

const DateHelper = {
	getCalendarProgrammeSettings,
	formatCalendarHours,
	setDurationAndDatesSession,
	isDurationEligible,
	getDurationDays,
	getCalendarWholeProgrammeDateSession,
	durationSuggestionOptionsHours,
	durationSuggestionOptions,
	getDatesListRange,
};

export default DateHelper;
