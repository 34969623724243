import type { SessionRead, SessionReadV1 } from "@api";
import { type PathEntity, type ServiceArgs, get } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions", id }];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSession = async (args: GetSessionArgs): Promise<SessionRead> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface GetSessionV1Args extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionV1 = async (args: GetSessionV1Args): Promise<SessionReadV1> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};
