import {
	type DtoFacultySession,
	type DtoFacultySessionPresentation,
	EBulkActionAction,
	FacultyHelper,
	getCellBulletColorWithLabel,
	getCellButtonsList,
} from "@key4-front-library/core";
import type { PropsButtonIcon } from "@key4-front-library/core/Bo/Components/Button/ButtonIcon";
import type { DataGridCellBulletColorProps } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellBulletColor";
import type { GridColDef } from "@mui/x-data-grid-pro";

export enum EFacultyType {
	CHAIR = "chair",
	SPEAKER = "speaker",
}

// TODO TM : Not sure it should be in the configuration
export interface InvitationRow {
	id: string;
	session: DtoFacultySession;
	presentation: DtoFacultySessionPresentation | null;
	invitationType: string | null | undefined;
	status: DataGridCellBulletColorProps;
	actions: {
		// TODO TM : Should be directly an Array of PropsButtonIcon
		listButtons: Array<PropsButtonIcon>;
	};
}

const datagridHeaders: Array<GridColDef> = [
	{ field: "id" },
	{ field: "key", minWidth: 180, maxWidth: 180, flex: 1 },
	{ field: "firstname", minWidth: 400 },
	{ field: "lastname", minWidth: 400 },
	{ field: "email", minWidth: 600, flex: 2 },
];

const invitationsDatagridHeaders: Array<GridColDef> = [
	{
		field: "session",
		minWidth: 450,
		flex: 3,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderSessionCell,
	},
	{
		field: "presentation",
		flex: 3,
		minWidth: 300,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderPresentationCell,
	},
	{ field: "invitationType", width: 150, flex: 1 },
	{
		field: "status",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 150,
		flex: 1,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;

			return labelA.localeCompare(labelB);
		},
	},
	{
		field: "actions",
		minWidth: 125,
		flex: 1,
		align: "center",
		sortable: false,
		disableColumnMenu: true,
		renderCell: getCellButtonsList,
	},
];

const bulkActionActions: Array<EBulkActionAction> = [EBulkActionAction.MAILING, EBulkActionAction.INVITATION];

const sieveKeys = ["key", "participant.firstname", "participant.lastname", "participant.email"];

const Faculty = {
	datagridHeaders,
	invitationsDatagridHeaders,
	bulkActionActions,
	sieveKeys,
};

export default Faculty;
