import "@application/design/styles/dataCy/data-cy.scss";
import "@application/design/styles/scrollbar/scrollbar.scss";

import ReactDOM from "react-dom/client";

import MatomoConfiguration from "@application/Configurations/matomo.configuration";
import { MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { Core, DefaultQueryClient } from "@key4-front-library/core";

import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<MatomoProvider value={MatomoConfiguration}>
			<Core queryClient={DefaultQueryClient} queryDebug={true}>
				<App />
			</Core>
		</MatomoProvider>,
	);
}
