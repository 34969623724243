import { getCellButtonsList } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const columns: GridColDef[] = [
	{
		field: "key",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "lastname",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "firstname",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "email",
		minWidth: 100,
		flex: 3,
		disableColumnMenu: true,
	},
	{
		field: "actionButtons",
		renderCell: getCellButtonsList,
		minWidth: 40,
		align: "right",
		hideSortIcons: true,
		disableColumnMenu: true,
	},
];

const ConvocationConfiguration = {
	datagrid: {
		columns,
	},
};

export default ConvocationConfiguration;
