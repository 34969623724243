import { t } from "i18next";

import { submitMatching } from "@application/Configurations/Matching";
import { type DtoClient, type DtoEvent, getColumnAction, renderComponent } from "@key4-front-library/core";

import type { PropositionInfo } from "@application/Mappers";
import type { GridColDef } from "@mui/x-data-grid";

export const getColumns = (translation: string): Array<GridColDef> => {
	const commonParams = {
		sortable: false,
		editable: false,
		disableColumnMenu: true,
	};
	return [
		{
			...commonParams,
			headerName: t(`${translation}.column.key`),
			field: "key",
			minWidth: 150,
			flex: 1,
			renderCell: renderComponent,
		},
		{
			...commonParams,
			headerName: t(`${translation}.column.proposal`),
			field: "proposal",
			minWidth: 250,
			flex: 1,
			renderCell: renderComponent,
		},
		{
			...commonParams,
			headerName: t(`${translation}.column.type`),
			field: "type",
			minWidth: 150,
			flex: 1,
			renderCell: renderComponent,
		},
		{
			...getColumnAction()[0],
			minWidth: 250,
		},
	];
};

export const submitMatchingList = async (client: DtoClient, event: DtoEvent, selectedPropositionInfos: Array<PropositionInfo>): Promise<boolean> => {
	let isSuccess = true;
	await Promise.all(selectedPropositionInfos.map((selectedPropositionInfo) => submitMatching(client, event, selectedPropositionInfo))).then((result) => {
		result.forEach((r) => {
			if (!r) {
				isSuccess = false;
			}
		});
	});

	return isSuccess;
};
