// #region 'Imports'
import React from "react";

import IssuesDefinitions from "@application/Configurations/issues.configuration";
import GetTranslationsForRooms from "@application/helpers/translations/GetTranslationsForRooms";
import type { IRoom } from "@domain/model/room.model";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { IUiMenu } from "@infrastructure/model/interfaces/components/UiMenu.interface";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, CardHeader, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, useTheme } from "@mui/material";

// #endregion 'Imports'

type Props = {
	room: IRoom;
	menu?: IUiMenu;
	handleCardSingleClick: (data: IRoom) => void;
	handleCardDoubleClick: (data: IRoom) => void;
};

const RoomCard = (props: Props & dataCypressProp) => {
	// #region "Definition"
	const { room, menu, handleCardSingleClick, handleCardDoubleClick, dataCypressID } = props;
	const translations = GetTranslationsForRooms();
	const theme = useTheme();

	const [anchorElementMenu, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const menuOpenState = Boolean(anchorElementMenu);

	const styles = {
		onHover: {
			opacity: ".6",
			cursor: "pointer",
		},
	};
	// #endregion "Definition"

	// #region 'handlers'
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	// #endregion 'handlers'

	// #region 'rendering fragment'
	const RenderMenu = () => {
		return (
			<Menu
				id="settings-menu"
				anchorEl={anchorElementMenu}
				open={menuOpenState}
				onClose={handleCloseMenu}
				MenuListProps={{
					"aria-labelledby": "button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{menu?.items.map((item, index) => {
					return (
						<Box key={`menu-item-${index}`}>
							{typeof item !== "string" && (
								<MenuItem
									key={item.reference}
									data-cy={dataCypressID ? `${dataCypressID}-menu_${item.reference}` : undefined}
									onClick={() => {
										item.onClick(room);
										handleCloseMenu();
									}}
								>
									<ListItemIcon>
										<Icon iconName={item.icon} color={theme.palette.secondary.main} />
									</ListItemIcon>
									{item.label}
								</MenuItem>
							)}
							{typeof item === "string" && <Divider />}
						</Box>
					);
				})}
			</Menu>
		);
	};
	// #endregion 'rendering fragment'

	// #region "rendering"
	return (
		<>
			<Card
				onDoubleClick={() => {
					handleCardDoubleClick(room);
				}}
			>
				<CardHeader
					avatar={<DragIndicatorIcon />}
					title={
						<Box
							component="span"
							data-cy={dataCypressID ? `${dataCypressID}-title` : undefined}
							onClick={() => {
								handleCardSingleClick(room);
							}}
							sx={{
								"&:hover": styles.onHover,
							}}
						>
							{room.name}
						</Box>
					}
					subheader={
						<React.Fragment>
							{translations.capacity}:
							<Box component="span" data-cy={dataCypressID ? `${dataCypressID}-capacity` : undefined}>
								{room.capacity}
							</Box>
						</React.Fragment>
					}
					action={
						<Stack direction={"row"} alignItems={"center"}>
							{room.hasClashes && (
								<Icon
									color={IssuesDefinitions.clashes.themeColor.main}
									prefix="fas"
									iconName={IssuesDefinitions.clashes.icon as IconName}
									dataCypressID={dataCypressID ? `${dataCypressID}-icon_clash` : undefined}
								/>
							)}
							{menu && (
								<>
									<IconButton
										aria-label="settings"
										aria-haspopup="true"
										aria-expanded={menuOpenState ? "true" : undefined}
										onClick={handleOpenMenu}
										data-cy={dataCypressID ? `${dataCypressID}-button` : undefined}
									>
										<MoreVertIcon />
									</IconButton>
									<RenderMenu />
								</>
							)}
						</Stack>
					}
				/>
			</Card>
		</>
	);
	// #endregion "rendering"
};

export default RoomCard;
