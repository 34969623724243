import { getCellButtonsList, getCellChipColor } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const datagridHeaders: GridColDef[] = [
	{ field: "id" },
	{
		field: "primaryTag",
		renderCell: getCellChipColor,
		sortComparator: (a, b) => {
			const labelA: string = a?.chip?.label ?? "";
			const labelB: string = b?.chip?.label ?? "";
			return labelA.localeCompare(labelB);
		},
		width: 1000,
		flex: 8,
		sortable: false,
		minWidth: 200,
		disableColumnMenu: true,
	},
	{
		field: "actionButtons",
		renderCell: getCellButtonsList,
		width: 110,
		minWidth: 50,
		flex: 1,
		hideSortIcons: true,
		disableColumnMenu: true,
		sortable: false,
		resizable: false,
	},
];

const SessionTemplates = {
	datagridHeaders,
};

export default SessionTemplates;
