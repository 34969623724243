import ParticipantController from "@key4-front-library/core/Bo/Controllers/ParticipantController";

import CalendarController from "./CalendarController";
import ChairController from "./ChairController";
import ConvocationController from "./ConvocationController";
import CustomFieldController from "./CustomFieldController";
import FacultyController from "./FacultyController";
import MailTemplateController from "./MailTemplateController";
import PresentationController from "./PresentationController";
import ProgrammeController from "./ProgrammeController";
import ProgrammeExportController from "./ProgrammeExportController";
import RoomController from "./RoomController";
import SearchFilterController from "./SearchFilterController";
import SessionController from "./SessionController";
import SettingsController from "./SettingsController";
import SpeakerController from "./SpeakerController";

const ControllersApp = {
	CalendarController,
	ChairController,
	ConvocationController,
	CustomFieldController,
	FacultyController,
	MailTemplateController,
	ParticipantController,
	PresentationController,
	ProgrammeController,
	ProgrammeExportController,
	RoomController,
	SearchFilterController,
	SessionController,
	SettingsController,
	SpeakerController,
};

export default ControllersApp;
