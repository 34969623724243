import { type MailSenderRead, MailTemplate, type Paginated, paginationToQueryString, useContextModule, usePagination } from "@key4-front-library/core";
import type { UseQueryResult } from "@tanstack/react-query";
import type { ReactNode } from "react";
import { useProgrammeMailSenders } from "@api";

export const TabMailTemplate = (): ReactNode => {
	const { page, pageSize } = usePagination();
	const { client, event } = useContextModule();

	const mailSenderQuery: UseQueryResult<Paginated<MailSenderRead>> = useProgrammeMailSenders(client.id, event.id, {
		queryStrings: [
			...paginationToQueryString({
				page: page + 1,
				pageSize,
			}),
		],
		enabled: !!client.id && !!event.id,
	});

	return <MailTemplate moduleMailSenders={mailSenderQuery} targets={["faculty", "chair", "speaker", "session", "presentation"]} targetModal="faculty" />;
};
