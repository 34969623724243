export enum EnumSpeakerFormKey {
	GENERAL = "general",
	REPLACEMENT = "replacement",
	DETAILS = "details",
}

export enum EnumSpeakerSectionKey {
	MAIN = "main",
}

export enum EnumSpeakerFieldKey {
	COMMENTS = "comments",
	EMAIL = "email",
	FIRSTNAME = "firstname",
	INVITATION = "invitation",
	LASTNAME = "lastname",
	PARTICIPANT_OR_CONTACT = "participantOrContact",
	QUALITY = "quality",
}
