import { type DtoFaculty, PersonHeader } from "@key4-front-library/core";
import type { PropsButtonIcon } from "@key4-front-library/core/Bo/Components/Button/ButtonIcon";
import type { DataGridCellBulletColorProps } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellBulletColor";
import type { GridCellParams } from "@mui/x-data-grid-pro";

export interface ChairRow {
	id: string;
	chair: DtoFaculty;
	status: DataGridCellBulletColorProps;
	invitationType: string | null | undefined;
	actions: {
		// TODO TM : Should be directly an Array of PropsButtonIcon
		listButtons: Array<PropsButtonIcon>;
	};
}

const renderChairCell = (params: GridCellParams) => {
	return <PersonHeader data={params.row.chair} />;
};

const ChairDataGridHelper = {
	renderChairCell,
};

export default ChairDataGridHelper;
