import { useEffect, useState } from "react";

import { ErrorAPI, type ITagTypeModelWithTags, queryStringSorts, Services, useContextModule } from "@key4-front-library/core";
import type { DtoEvent } from "@key4-front-library/core/Dto";

const useTagTypes = () => {
	const { client, event } = useContextModule();

	const [data, setData] = useState<ITagTypeModelWithTags[]>([]);
	const [error, setError] = useState<ErrorAPI | string | undefined>("");
	const [loading, setLoading] = useState<boolean | null>(null);

	useEffect(() => {
		const loadingData = async (event: DtoEvent) => {
			setLoading(true);
			await Services.Events.Programme.SessionsService.getListTags(client.id, event.id, queryStringSorts(["order"]))
				.then((response: any[] | ErrorAPI) => {
					if (response instanceof ErrorAPI) {
						setError(response);
					} else {
						setData(response);
					}
					setLoading(false);
				})
				.catch((_error) => {
					setError(_error);
					setLoading(false);
				});
		};

		loadingData(event);
	}, [event]);

	return { data, error, loading };
};

export default useTagTypes;
