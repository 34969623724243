export enum ESort {
	ASC = "asc",
	DESC = "desc",
}

export type ISort = {
	field: string;
	sort: ESort;
	label: string;
};
