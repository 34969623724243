import DonutChart from "@infrastructure/components/charts/Donut/DonutChart";
import Card from "@infrastructure/components/interface/card/Card";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import { Grid, Skeleton } from "@mui/material";

type Props = {
	title: string;
	statistics: Array<IChartSeriesItem>;
	isLoading: boolean;
};

const DashboardDonutChart = (props: Props) => {
	const { title, statistics, isLoading } = props;
	return (
		<Grid item xs={12} md={6} lg={3} display={"flex"}>
			{isLoading ? (
				<Skeleton
					variant="rounded"
					width="100%"
					height={"100%"}
					sx={{
						borderRadius: "16px",
						minHeight: 300,
					}}
				/>
			) : (
				<Card title={title}>
					<DonutChart statistics={statistics} />
				</Card>
			)}
		</Grid>
	);
};

export default DashboardDonutChart;
