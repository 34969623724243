const transformKebabToSnake = (str: string) => str.replaceAll("-", "_");

const transformCamelToSnake = (str: string) => {
	str = str.charAt(0).toLowerCase() + str.slice(1);
	str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/[0-9]+/g, (number) => `_${number}`);
	if (str.charAt(0) === "_") str = str.slice(1);
	return str;
};

const transformSpacesAndCapsToSnake = (str: string) => str.replaceAll(" ", "_").toLocaleLowerCase();

const pad = (number: number, size: number) => {
	let numberStr = number.toString();
	while (numberStr.length < size) numberStr = "0" + numberStr;
	return numberStr;
};

const capitalizeStr = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const StringService = {
	transformKebabToSnake,
	transformCamelToSnake,
	transformSpacesAndCapsToSnake,
	pad,
	capitalizeStr,
};

export default StringService;
