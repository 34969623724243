import type { TFunction } from "i18next";

const GetTranslationsForCreateEditSession = (t: TFunction) => {
	return {
		locale: t("old.common.current_locale"),
		title: t("old.programme.sessions.title"),
		sort: {
			date: t("old.programme.sessions.sort.date"),
			room: t("old.programme.sessions.sort.room"),
			title: t("old.programme.sessions.sort.title"),
			code: t("old.programme.sessions.sort.code"),
		},
		create_edit_modale: {
			title: {
				create: t("old.programme.sessions.create_edit_modale.title.create"),
				edit: t("old.programme.sessions.create_edit_modale.title.edit"),
			},
			tabs: {
				general: {
					title: t("old.programme.sessions.create_edit_modale.tabs.general.title"),
					form: {
						code: t("old.programme.sessions.create_edit_modale.tabs.general.form.code"),
						organized_by: t("old.programme.sessions.create_edit_modale.tabs.general.form.organized_by"),
						title: t("old.programme.sessions.create_edit_modale.tabs.general.form.title"),
						description: t("old.programme.sessions.create_edit_modale.tabs.general.form.description"),
						is_private: t("old.programme.sessions.create_edit_modale.tabs.general.form.is_private"),
					},
				},
				room_schedule: {
					title: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.title"),
					form: {
						is_everywhere: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.is_everywhere"),
						room: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.room"),
						expected_audience: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.expected_audience"),
						max_pax: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.max_pax"),
						max_pax_capacity: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.max_pax_capacity"),
						max_pax_not_set: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.max_pax_not_set"),
						is_whole_programme: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.is_whole_programme"),
						start_date: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.start_date"),
						end_date: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.end_date"),
						start_hour: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.start_hour"),
						end_hour: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.end_hour"),
						is_full_day: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.is_full_day"),
						duration: t("old.programme.sessions.create_edit_modale.tabs.room_schedule.form.duration"),
					},
				},
				workflow_tags: {
					title: t("old.programme.sessions.create_edit_modale.tabs.workflow_tags.title"),
					form: {
						session_status: t("old.programme.sessions.create_edit_modale.tabs.workflow_tags.form.session_status"),
						publish_status: t("old.programme.sessions.create_edit_modale.tabs.workflow_tags.form.publish_status"),
					},
				},
			},
			snackbar: {
				created: t("old.programme.sessions.create_edit_modale.snackbar.created"),
				updated: t("old.programme.sessions.create_edit_modale.snackbar.updated"),
				deleted: t("old.programme.sessions.create_edit_modale.snackbar.deleted"),
				cloned: t("old.programme.sessions.create_edit_modale.snackbar.cloned"),
			},
			create_another: t("old.form.actions.createAnother"),
			keep_values: t("old.form.actions.keepValues"),
		},
		buttons: {
			add: t("old.form.buttons.add"),
			edit: t("old.form.buttons.edit"),
			clone: t("old.form.buttons.clone"),
			delete: t("old.form.buttons.delete"),
			details: t("old.form.buttons.details"),
		},
		dates: {
			dateTimeMaskFormat: t("old.common.datetime-mask-format"),
			dateMaskFormat: t("old.common.date-mask-format"),
			timeMaskFormat: t("old.common.time-mask-format"),
		},
		validation: {
			startHourWithoutDates: t("old.programme.sessions.validation.startHourWithoutDates"),
			startHourCannotBeNullIfEndHourIsFilled: t("old.programme.sessions.validation.startHourCannotBeNullIfEndHourIsFilled"),
			endHourWithoutDates: t("old.programme.sessions.validation.endHourWithoutDates"),
			endHourCannotBeNullIfStartHourIsFilled: t("old.programme.sessions.validation.endHourCannotBeNullIfStartHourIsFilled"),
			startDateTimeMustBeAnteriorAtEndDateTime: t("old.programme.sessions.validation.startDateTimeMustBeAnteriorAtEndDateTime"),
			startDateWithoutEndDate: t("old.programme.sessions.validation.startDateWithoutEndDate"),
			startDateMustBeInferiorToEndDate: t("old.programme.sessions.validation.startDateMustBeInferiorToEndDate"),
			endDateWithoutStartDate: t("old.programme.sessions.validation.endDateWithoutStartDate"),
			endDateMustBeSuperiorToStartDate: t("old.programme.sessions.validation.endDateMustBeSuperiorToStartDate"),
			durationFormatNotMatchWithDayMode: t("old.programme.sessions.validation.durationFormatNotMatchWithDayMode"),
			durationFormatNotMatchWithHourMode: t("old.programme.sessions.validation.durationFormatNotMatchWithHourMode"),
		},
	};
};

export const GetTranslationsForSessionEditTabGeneral = (t: TFunction) => {
	return GetTranslationsForCreateEditSession(t).create_edit_modale.tabs.general.form;
};

export const GetTranslationsForSessionEditTabRoomSchedule = (t: TFunction) => {
	return GetTranslationsForCreateEditSession(t).create_edit_modale.tabs.room_schedule.form;
};

export const GetTranslationsForSessionEditTabWorkflowTags = (t: TFunction) => {
	return GetTranslationsForCreateEditSession(t).create_edit_modale.tabs.workflow_tags.form;
};

export default GetTranslationsForCreateEditSession;
