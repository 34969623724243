import ControllersApp from "@application/Controllers/ControllersApp";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readList: readListRoom } = ControllersApp.RoomController.useEntity();
	const readList = () => {
		const { data } = useQuery({
			queryKey: ["get", "list", "rooms"],
			queryFn: async () => {
				return await readListRoom();
			},
			placeholderData: (previousData) => previousData,
		});

		return { data };
	};
	return { readList };
};

const SessionHook = {
	useEntity,
};

export default SessionHook;
