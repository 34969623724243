export enum IconItemReference {
	TAG = "tag",
	ANOMALIES = "anomalies",
	PROGRAMME = "programme",
	DASHBOARD = "dashboard",
	SCHEDULER = "scheduler",
	SESSION = "session",
	PRESENTATION = "presentation",
	ROOM = "room",
	FACULTY = "faculty",
	CHAIR = "chair",
	SPEAKER = "speaker",
	NOTIFICATION = "notification",
	CLASH = "clash",
	ANOMALY = "anomaly",
	ACTIONS = "actions",
	INVITATION = "invitation",
	NUMBERING = "numbering",
	PUBLICATION = "publication",
	SETTINGS = "settings",
	SESSION_TEMPLATES = "sessionTemplates",
}
