import {
	EField,
	type TPickFieldAutoCompleteBulletColor,
	type TPickFieldAutoCompleteMultiChipColor,
	type TPickFieldAutoCompleteSubLabel,
	type TPickFieldCheckbox,
	type TPickFieldDatePicker,
	type TPickFieldDateTimePicker,
	type TPickFieldNumeric,
	type TPickFieldSelectChipColor,
	type TPickFieldText,
	type TPickFieldTextArea,
	type TPickFieldTimePicker,
} from "@key4-front-library/core";

const DEFAULT_CHIP_FONT_COLOR = "black";
const DEFAULT_CHIP_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.08)";
const DEFAULT_CHIP_BORDER_COLOR = "transparent";

const DEFAULT_CHIP_SELECT_ALL_FONT_COLOR = "black";
const DEFAULT_CHIP_SELECT_ALL_BACKGROUND_COLOR = "transparent";
const DEFAULT_CHIP_SELECT_ALL_BORDER_COLOR = "transparent";

const baseFieldPicker = {
	isStatic: true,
	key: "",
};

const pickCheckbox: TPickFieldCheckbox = {
	...baseFieldPicker,
	kind: EField.CHECKBOX,
	checkbox: {
		label: "",
	},
};

const pickText: TPickFieldText = {
	...baseFieldPicker,
	kind: EField.TEXT,
	text: {
		label: "",
	},
};

const pickSelectChipColor: TPickFieldSelectChipColor = {
	...baseFieldPicker,
	kind: EField.SELECT_CHIP_COLOR,
	selectChipColor: {
		label: "",
		items: [],
	},
};

const pickTextArea: TPickFieldTextArea = {
	...baseFieldPicker,
	kind: EField.TEXT_AREA,
	textArea: {
		label: "",
	},
};

const pickNumeric: TPickFieldNumeric = {
	...baseFieldPicker,
	kind: EField.NUMERIC,
	numeric: {
		type: "number",
		label: "",
	},
};

const pickAutoCompleteSubLabel: TPickFieldAutoCompleteSubLabel = {
	...baseFieldPicker,
	kind: EField.AUTO_COMPLETE_SUB_LABEL,
	autoCompleteSubLabel: {
		label: "",
		items: [],
	},
};

const pickDatePicker: TPickFieldDatePicker = {
	...baseFieldPicker,
	kind: EField.DATE_PICKER,
	datePicker: {
		label: "",
	},
};

const pickTimePicker: TPickFieldTimePicker = {
	...baseFieldPicker,
	kind: EField.TIME_PICKER,
	timePicker: {
		label: "",
	},
};

const pickAutoCompleteBulletColor: TPickFieldAutoCompleteBulletColor = {
	...baseFieldPicker,
	kind: EField.AUTO_COMPLETE_BULLET_COLOR,
	autoCompleteBulletColor: {
		label: "",
		items: [],
	},
};

const pickDateTimePicker: TPickFieldDateTimePicker = {
	...baseFieldPicker,
	kind: EField.DATE_TIME_PICKER,
	dateTimePicker: {
		label: "",
	},
};

const pickFieldAutocompleteMultiChipColor: TPickFieldAutoCompleteMultiChipColor = {
	...baseFieldPicker,
	kind: EField.AUTOCOMPLETE_MULTI_CHIP_COLOR,
	autocompleteMultiChipColor: {
		label: "",
		items: [],
	},
};

const FormHelper = {
	baseField: {
		pick: {
			checkbox: pickCheckbox,
			text: pickText,
			selectChipColor: pickSelectChipColor,
			textArea: pickTextArea,
			numeric: pickNumeric,
			autoCompleteSubLabel: pickAutoCompleteSubLabel,
			datePicker: pickDatePicker,
			timePicker: pickTimePicker,
			autoCompleteBulletColor: pickAutoCompleteBulletColor,
			dateTimePicker: pickDateTimePicker,
			autocompleteMultiChipColor: pickFieldAutocompleteMultiChipColor,
		},
	},
	DEFAULT_CHIP_FONT_COLOR,
	DEFAULT_CHIP_BACKGROUND_COLOR,
	DEFAULT_CHIP_BORDER_COLOR,
	DEFAULT_CHIP_SELECT_ALL_FONT_COLOR,
	DEFAULT_CHIP_SELECT_ALL_BACKGROUND_COLOR,
	DEFAULT_CHIP_SELECT_ALL_BORDER_COLOR,
};

export default FormHelper;
