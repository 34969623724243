import type { PresentationRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (sessionId: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "presentations", id },
];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getPresentations = async (args: GetSessionArgs): Promise<Paginated<PresentationRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};
