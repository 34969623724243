import { DateTime, Interval } from "luxon";

import { Culture } from "@key4-front-library/core";

type DateType = {
	date: DateTime;
	culture?: string;
};

type DateAsObjectType = {
	date: any;
};

type DateAsJsDateType = {
	date: Date;
};

type DateAsNumberType = {
	date: number;
};

type DateComparaisonType = {
	startDate: DateTime | null;
	endDate: DateTime | null;
};

type TimeComparaisonType = {
	startTime: DateTime | null;
	endTime: DateTime | null;
};

const IsDate = (props: DateAsObjectType): boolean => {
	const { date } = props;
	return DateTime.isDateTime(date);
};

const IsValidDateNullableyyyyMMdd = (value: DateTime | null): boolean => {
	if (value === null) return true;
	return value.toFormat("yyyy-MM-dd") !== "Invalid DateTime";
};

/**
 * Support hh:mm:00 format.
 * Take care of regex, from 00:00:00 to 99:99:00.
 * @param value string
 * @returns boolean
 */
function IsValidHHMM99_99_00(value: string): boolean {
	if (value === "" || value === null) return true;
	const regTimeHours99_99 = /^(0?[1-9]|\d\d):[0-5]\d:00$/;
	const result: boolean = regTimeHours99_99.test(value);
	return result;
}

// ! Depreciated GetDateFromIsoString, use the second one
const GetDateFromIsoString = (text: string | null | undefined): DateTime | null => {
	return text
		? DateTime.fromISO(text, {
				zone: "utc",
			})
		: null;
};
const GetDateFromIsoString2 = (text: string): DateTime => {
	return DateTime.fromISO(text, {
		zone: "utc",
	});
};

const GetDateFromJSNumber = (props: DateAsNumberType): DateTime | null => {
	const { date } = props;
	const NativeDate = window.Date;
	const { year, month, day } = DateTime.fromJSDate(new NativeDate(date)).toObject();
	return DateTime.utc(year! ?? 0, month! ?? 0, day! ?? 0);
};

const GetDateFromJSObject = (props: DateAsJsDateType): DateTime | null => {
	const { date } = props;
	return DateTime.fromJSDate(date, { zone: "utc" });
};

/**
 *
 * @param dateIsoFormat 2022-02-23T15:00:00Z
 * @returns 23/10/2022
 */
const GetLocaleStringFromIsoString = (dateIsoFormat: string): string => {
	const dateObject = DateTime.fromISO(dateIsoFormat);
	return dateObject.toUTC().toLocaleString();
};

const GetDateNow = (): DateTime => {
	return DateTime.now();
};

const ShortDate = (props: DateType): string => {
	const { date, culture = Culture[0].culture } = props;
	return date ? date.toUTC().setLocale(culture).toLocaleString(DateTime.DATE_SHORT) : "(Invalid date)";
};

const ShortDateTimeInLocalTimeZone = (props: DateType): string => {
	const { date, culture = Culture[0].culture } = props;
	return date.setZone("local").setLocale(culture).toLocaleString(DateTime.DATETIME_SHORT);
};

const MonthDayDate = (props: DateType): string => {
	const { date, culture = Culture[0].culture } = props;
	return date ? date.toUTC().setLocale(culture).toFormat("dd/MM") : "(Invalid date)";
};

const FullDate = (props: DateType): string => {
	const { date, culture = Culture[0].culture } = props;
	return date.toLocaleString(DateTime.DATE_FULL, { locale: culture });
};

const IsoStringDate = (props: DateType): string => {
	const { date } = props;
	const { year, month, day, hour, minute, second } = date.toUTC().toObject();
	return DateTime.utc(year ?? 0, month ?? 0, day ?? 0, hour ?? 0, minute ?? 0, second ?? 0).toISO() ?? "";
};

const TimeSimple = (props: DateType): string => {
	const { date, culture = Culture[0].culture } = props;
	return date ? date.toUTC().setLocale(culture).toLocaleString(DateTime.TIME_SIMPLE) : "(Invalid date)";
};

const DateTimeToJSObject = (props: DateType): Date => {
	const { date } = props;
	return date.toJSDate();
};

/**
 * Add hours to a date.
 * @param date
 * @param hours
 * @returns
 */
const AddHoursToDate = (date: Date, hours: number): Date => {
	return new Date(new Date(date).setHours(date.getHours() + hours));
};

/**
 * Add minutes to a date.
 * @param date
 * @param minutes
 * @returns
 */
const AddMinutesToDate = (date: Date, minutes: number): Date => {
	return new Date(new Date(date).setMinutes(date.getMinutes() + minutes));
};

/**
 * Add minutes and hours in format "hh:mm" to a date.
 * @param partHoursMinutes
 * @param date
 * @returns
 */
const FusionDateHours = (partHoursMinutes: string, date: Date): Date => {
	const hourMinuteSplitted = partHoursMinutes.split(":");
	const hours: any = hourMinuteSplitted[0];
	const minutes: any = hourMinuteSplitted[1];
	let newDate = AddHoursToDate(date, hours as number);
	newDate = AddMinutesToDate(newDate, minutes as number);
	return newDate;
};

const FusionDateHoursDateTime = (partHoursMinutes: string, date: DateTime): DateTime => {
	const hourMinuteSplitted = partHoursMinutes.split(":");
	const hours: number = Number.parseInt(hourMinuteSplitted[0]);
	const minutes: number = Number.parseInt(hourMinuteSplitted[1]);
	const newDate: DateTime = date.set({ hour: hours, minute: minutes });
	return newDate;
};

const DateDiffDays = (props: DateComparaisonType): number | null => {
	const { startDate, endDate } = props;
	if (startDate && endDate) {
		return Math.ceil(Interval.fromDateTimes(startDate, endDate).length("days"));
	}
	return null;
};

const DateDiffHours = (props: TimeComparaisonType): number | null => {
	const { startTime, endTime } = props;
	if (startTime && endTime) {
		return Math.ceil(Interval.fromDateTimes(startTime, endTime).length("hours"));
	}
	return null;
};

const isIsoStringBeforeNow = (isoString: string) => {
	const dateToCompare = DateService.GetDateFromIsoString(isoString);

	const dateNow = DateService.GetDateFromJSObject({ date: new Date() });
	if (dateToCompare && dateNow) return dateToCompare < dateNow;
};

const DateTimeToyyyyMMdd = (date: DateTime): string => {
	return date.toFormat("yyyy-MM-dd");
};

const DateTimeToHHmmss = (date: DateTime): string => {
	return date.toFormat("HH:mm:ss");
};

const DateTimeToHHmm = (date: DateTime): string => {
	return date.toFormat("HH:mm");
};

const Differences = {
	DateDiffDays,
	DateDiffHours,
	isIsoStringBeforeNow,
};

const Convert = { DateTimeToJSObject, GetDateFromIsoString2 };

const Format = {
	ShortDate,
	ShortDateTimeInLocalTimeZone,
	MonthDayDate,
	FullDate,
	IsoStringDate,
	TimeSimple,
	DateTimeToHHmmss,
	DateTimeToyyyyMMdd,
	DateTimeToHHmm,
};

const DateService = {
	IsDate,
	GetDateFromIsoString,
	GetDateFromJSNumber,
	GetDateFromJSObject,
	GetLocaleStringFromIsoString,
	IsValidDateNullableyyyyMMdd,
	IsValidHHMM99_99_00,
	FusionDateHours,
	FusionDateHoursDateTime,
	GetDateNow,
	Format,
	Convert,
	Differences,
};

export default DateService;
