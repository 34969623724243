import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import type { TypeUseFormSendMailTemplateValue } from "@application/Types/MailTemplateType";
import { yupResolver } from "@hookform/resolvers/yup";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { ButtonOld, Locales } from "@key4-front-library/core";
import type { TypeSelectItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelect";
import { FormControlSelectIcon } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectIcon";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";

type ModalSendMailProps = {
	isOpenModal: boolean;
	handleModalClose: (reason?: string) => void;
	handleSendPreview: (form: TypeUseFormSendMailTemplateValue) => void;
	handleSend: (form: TypeUseFormSendMailTemplateValue) => void;
	templates: Array<TypeSelectItem>;
};

const ModalSendMail = (props: ModalSendMailProps & dataCypressProp) => {
	const { isOpenModal, handleModalClose, handleSendPreview, handleSend, templates } = props;

	const formLabels = {
		template: t("old.programme.facultyDetails.sendMailModal.fields.template.title"),
	};

	const schema: any = yup.object({
		template: yup.string().required(Locales.Parsers.FormFieldRule.Required(formLabels.template)),
	});

	const {
		handleSubmit: onSubmit,
		control,
		formState: { isValid },
	} = useForm<any>({
		mode: "all",
		resolver: yupResolver(schema),
	});

	return (
		<Dialog
			open={isOpenModal}
			fullWidth
			onClose={(_, reason) => {
				handleModalClose(reason);
			}}
			maxWidth={"md"}
		>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{t("old.programme.facultyDetails.sendMailModal.title")}
				<IconButton
					onClick={() => {
						handleModalClose();
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Box component="form">
					<Grid item xs={12}>
						<Controller
							name="template"
							control={control}
							render={({ field: { value, onChange } }) => (
								<FormControlSelectIcon label={formLabels.template} isNullable={false} value={value} onChange={onChange} required items={templates} />
							)}
						/>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions sx={{ px: 2, pb: 2, pt: 0 }}>
				<ButtonOld label={t("old.form.buttons.cancel")} variant="outlined" onClick={handleModalClose} sx={{ mr: 1 }} />
				<ButtonOld
					label={t("old.programme.facultyDetails.sendMailModal.sendPreview.button")}
					onClick={onSubmit(handleSendPreview)}
					disabled={!isValid}
					sx={{ mr: 1 }}
				/>
				<ButtonOld label={t("old.programme.facultyDetails.sendMailModal.send.button")} onClick={onSubmit(handleSend)} disabled={!isValid} sx={{ mr: 1 }} />
			</DialogActions>
		</Dialog>
	);
};

export default ModalSendMail;
