import type { InvitationStatusSpeaker, SessionStatusType } from "@api";
import { BulletText, type TagProps } from "@key4-front-library/core";
import type { Theme } from "@mui/material";
import { t } from "i18next";

export const getSessionStatusTag = (
	theme: Theme,
): {
	[key in SessionStatusType]: TagProps;
} => ({
	draft: {
		backgroundColor: theme.palette.grey[300],
		borderColor: theme.palette.grey[300],
		fontColor: theme.palette.grey[700],
		children: <BulletText bulletColor={theme.palette.grey[700]} text={t("programme.sessionStatus.draft")} />,
	},
	validated: {
		backgroundColor: theme.palette.info.background,
		borderColor: theme.palette.info.background,
		fontColor: theme.palette.info.main,
		children: <BulletText text={t("programme.sessionStatus.validated")} />,
	},
	readyToPublish: {
		backgroundColor: theme.palette.success.background,
		borderColor: theme.palette.success.background,
		fontColor: theme.palette.success.main,
		children: <BulletText text={t("programme.sessionStatus.readyToPublish")} />,
	},
});

export const getInvitationSpeakerStatusColor = (
	theme: Theme,
): {
	[key in InvitationStatusSpeaker]: string | undefined;
} => ({
	unsent: theme.palette.grey[700],
	declined: theme.palette.error.main,
	replaced: theme.palette.error.main,
	cancelled: theme.palette.neutral.main,
	waitingForAnswer: theme.palette.grey[500],
	accepted: theme.palette.success.main,
});
