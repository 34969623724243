import { t } from "i18next";

const getTranslationsSearchList = () => {
	return {
		placeholderSimpleSearch: t("old.searchList.placeholderSimpleSearch"),
		title: t("old.searchList.title"),
		searchReset: t("old.searchList.searchReset"),
		advancedSearch: t("old.searchList.advancedSearch"),
		searchFilter: {
			snackbar: {
				createEmpty: t("old.searchList.searchFilter.snackbar.createEmpty"),
				createSuccess: t("old.searchList.searchFilter.snackbar.createSuccess"),
				modifySuccess: t("old.searchList.searchFilter.snackbar.modifySuccess"),
				deleteSuccess: t("old.searchList.searchFilter.snackbar.deleteSuccess"),
			},
			autoComplete: {
				title: t("old.searchList.searchFilter.autoComplete.title"),
				newQueryOption: t("old.searchList.searchFilter.autoComplete.newQueryOption"),
			},
			modalNewQuery: {
				title: t("old.searchList.searchFilter.modalNewQuery.title"),
				placeholder: t("old.searchList.searchFilter.modalNewQuery.placeholder"),
				emptyField: t("old.searchList.searchFilter.modalNewQuery.emptyField"),
			},
		},
	};
};

const getTranslationsQueryBuilder = () => {
	return {
		conjunctions: {
			and: t("old.queryBuilder.conjunctions.and"),
			or: t("old.queryBuilder.conjunctions.or"),
		},
		operators: {
			is_null: t("old.queryBuilder.operators.is_null"),
			is_not_null: t("old.queryBuilder.operators.is_not_null"),
			equal: t("old.queryBuilder.operators.equal"),
			not_equal: t("old.queryBuilder.operators.not_equal"),
			like: t("old.queryBuilder.operators.like"),
			starts_with: t("old.queryBuilder.operators.starts_with"),
			is_empty: t("old.queryBuilder.operators.is_empty"),
			is_not_empty: t("old.queryBuilder.operators.is_not_empty"),
			select_any_in: t("old.queryBuilder.operators.select_any_in"),
			select_equals: t("old.queryBuilder.operators.select_equals"),
			select_not_equals: t("old.queryBuilder.operators.select_not_equals"),
			select_not_any_in: t("old.queryBuilder.operators.select_not_any_in"),
		},
		settings: {
			valueLabel: t("old.queryBuilder.valueLabel"),
			valuePlaceholder: t("old.queryBuilder.valuePlaceholder"),
			fieldLabel: t("old.queryBuilder.fieldLabel"),
			operatorLabel: t("old.queryBuilder.operatorLabel"),
			fieldPlaceholder: t("old.queryBuilder.fieldPlaceholder"),
			operatorPlaceholder: t("old.queryBuilder.operatorPlaceholder"),
			addGroupLabel: t("old.queryBuilder.addGroupLabel"),
			addRuleLabel: t("old.queryBuilder.addRuleLabel"),
			removeRuleConfirmOptions: {
				title: t("old.queryBuilder.removeRuleConfirmOptions.title"),
				okText: t("old.queryBuilder.removeRuleConfirmOptions.okText"),
				okType: t("old.queryBuilder.removeRuleConfirmOptions.okType"),
			},
			removeGroupConfirmOptions: {
				title: t("old.queryBuilder.removeGroupConfirmOptions.title"),
				okText: t("old.queryBuilder.removeGroupConfirmOptions.okText"),
				okType: t("old.queryBuilder.removeGroupConfirmOptions.okType"),
			},
		},
		type: {
			boolean: {
				label_yes: t("old.queryBuilder.type.boolean.label_yes"),
				label_no: t("old.queryBuilder.type.boolean.label_no"),
			},
		},
		widgets: {
			text: {
				valuePlaceholder: t("old.queryBuilder.widgets.text.valuePlaceholder"),
			},
			multiselect: {
				valuePlaceholder: t("old.queryBuilder.widgets.multiselect.valuePlaceholder"),
			},
		},
	};
};

const getTranslations = () => {
	return {
		...getTranslationsSearchList(),
		...getTranslationsQueryBuilder(),
	};
};

const SearchListHelper = {
	getTranslations,
	getTranslationsSearchList,
};

export default SearchListHelper;
