import { EBulkActionAction, getCellBulletColorWithLabel, getCellChipColor, getCellIcon } from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const datagridHeaders: Array<GridColDef> = [
	{ field: "id" },
	{ field: "key", minWidth: 180, maxWidth: 180, flex: 1 },
	{ field: "code", minWidth: 80, flex: 2 },
	{
		field: "primaryTag",
		renderCell: getCellChipColor,
		minWidth: 280,
		sortComparator: (a, b) => {
			const labelA: string = a?.chip?.label ?? "";
			const labelB: string = b?.chip?.label ?? "";
			return labelA.localeCompare(labelB);
		},
	},
	{ field: "title", minWidth: 633 },
	{
		field: "date",
		minWidth: 260,
	},
	{
		field: "hasAnomalies",
		renderCell: getCellIcon,
		maxWidth: 100,
	},
	{
		field: "hasClashes",
		renderCell: getCellIcon,
		maxWidth: 100,
	},
	{
		field: "status",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 200,
	},
];

const bulkActionActions: Array<EBulkActionAction> = [
	EBulkActionAction.EDIT,
	EBulkActionAction.TRANSITION,
	EBulkActionAction.PUBLISH,
	EBulkActionAction.NUMBERING,
	EBulkActionAction.DELETE,
];
const sieveKeys = ["code", "title", "key"];

const Session = {
	datagridHeaders,
	bulkActionActions,
	sieveKeys,
};

export default Session;
