import type { SpeakerRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, getPaginated } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (sessionId?: string, presentationId?: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "presentations", id: presentationId },
	{ entity: "speakers", id },
];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSpeakers = async (args: GetSessionArgs): Promise<Paginated<SpeakerRead>> => {
	const { ...others } = args;
	return await getPaginated({
		module,
		entities: rootEntities(),
		...others,
	});
};
