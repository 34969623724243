//#region 'Imports'
import { Box, CircularProgress, Stack } from "@mui/material";

//#endregion 'Imports'

const Loading = () => {
	return (
		<Stack alignItems={"center"} my={"5em"}>
			<Box sx={{ display: "flex" }}>
				<CircularProgress aria-label="loading" />
			</Box>
		</Stack>
	);
};

export default Loading;
