import ChairHelper from "./ChairHelper";
import ConvocationHelper from "./ConvocationHelper";
import PresentationHelper from "./PresentationHelper";
import ProgrammeExportHelper from "./ProgrammeExportHelper";
import SessionHelper from "./SessionHelper";
import SpeakerHelper from "./SpeakerHelper";

const HelpersApp = {
	ChairHelper,
	ConvocationHelper,
	PresentationHelper,
	SessionHelper,
	SpeakerHelper,
	ProgrammeExportHelper,
};

export default HelpersApp;
