import { matchDtoToIcon } from "@application/Configurations/Matching";
import type { FacultyTypeMatching, UserInformationWithMetadata, UserTypeMatching } from "@application/Types";
import type { ActivityMatchDto, ActivityReplacementDto, UserInformationProps } from "@key4-front-library/core";

export const activityMatchDtoToUserInformationList = (
	activity: ActivityMatchDto,
	type: "contact" | "participant",
	urlParticipant?: string,
): Array<UserInformationWithMetadata> => {
	return activity.matches.map((match) => {
		return {
			id: match.id,
			type,
			user: {
				firstname: match.firstName,
				lastname: match.lastName,
				email: match.email,
				icon: matchDtoToIcon(match),
				link: {
					children: match.key,
					to: type === "participant" ? `${urlParticipant}/${match.id}` : undefined,
				},
			},
		};
	});
};

export interface PropositionInfo {
	id: string;
	userType: UserTypeMatching;
	facultyType: FacultyTypeMatching;
	sessionId: string;
	presentationId?: string;
	currentParticipantId: string;
}

interface ActivityToProposalCardInfoArgs {
	activity: ActivityReplacementDto;
	participantProposals?: Array<ActivityMatchDto>;
	contactProposals?: Array<ActivityMatchDto>;
}

interface ActivityToProposalCardInfoReturn {
	userInformation?: UserInformationProps;
	isRowSelectable: boolean;
	propositionInfo?: PropositionInfo;
}

export const activityToProposalCardInfo = (args: ActivityToProposalCardInfoArgs): ActivityToProposalCardInfoReturn => {
	const { activity, contactProposals, participantProposals } = args;
	if (!activity.invitationReplacement) {
		return {
			userInformation: undefined,
			isRowSelectable: false,
			propositionInfo: undefined,
		};
	}
	let propositionInfo: PropositionInfo | undefined;
	const userInfoReplacement: UserInformationProps = {
		email: activity.invitationReplacement.email,
		firstname: activity.invitationReplacement.firstname,
		lastname: activity.invitationReplacement.lastname,
		isAvatarUnknown: true,
	};
	let isRowSelectable = false;
	const matchParticipant = setMatchingInfos(activity.id, userInfoReplacement, participantProposals);
	const propositionArgs = {
		facultyType: getFacultyType(activity.type),
		sessionId: activity.session.id,
		presentationId: activity.presentation?.id,
		currentParticipantId: activity.participantId,
	};

	if (matchParticipant.isMatchFound) {
		isRowSelectable = matchParticipant.isRowSelectable;
		propositionInfo = {
			userType: "participant",
			id: matchParticipant.id,
			...propositionArgs,
		};
	} else {
		const matchContact = setMatchingInfos(activity.id, userInfoReplacement, contactProposals);
		if (matchContact.isMatchFound) {
			isRowSelectable = matchContact.isRowSelectable;
		}
		propositionInfo = {
			userType: "contact",
			id: matchContact.id,
			...propositionArgs,
		};
	}

	return {
		userInformation: userInfoReplacement,
		isRowSelectable,
		propositionInfo,
	};
};

export const setMatchingInfos = (
	activityId: string,
	userInfoReplacement: UserInformationProps,
	activities?: Array<ActivityMatchDto>,
): { isRowSelectable: boolean; isMatchFound: boolean; id: string } => {
	let isRowSelectable = false;
	let isMatchFound = false;
	let id = "notSet";
	activities?.forEach((activityMatch) => {
		if (activityMatch.id === activityId) {
			if (activityMatch.matches[0].matchingType === "all") {
				isRowSelectable = true;
				userInfoReplacement.isAvatarUnknown = false;
				id = activityMatch.matches[0].id;
			}
			userInfoReplacement.icon = matchDtoToIcon(activityMatch.matches[0]);
			isMatchFound = true;
		}
	});
	return { isRowSelectable, isMatchFound, id };
};

export const getFacultyType = (type: string): FacultyTypeMatching => {
	switch (type) {
		case "Chair":
			return "chair";
		default:
		case "Speaker":
			return "speaker";
	}
};
