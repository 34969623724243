import { useState } from "react";

import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import ActionButton from "@infrastructure/components/interface/button/ActionButton";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IAction } from "@infrastructure/model/interfaces/action.interface";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	alpha,
	Box,
	Card as MuiCard,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	Divider,
	Grid,
	IconButton,
	type IconButtonProps,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	styled,
	useTheme,
} from "@mui/material";

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

type Props = {
	children: JSX.Element;
	title?: string | JSX.Element;
	action?: IAction;
	minWidth?: number;
	divider?: boolean;
	backgroundColor?: string;
	boxShadow?: boolean;
	menuItems?: IMenuItems;
	expansible?: boolean;
	pinIcon?: { prefix: IconPrefix };
	setDataPinnedElementClicked?: any;
	isHeader?: boolean;
};

// ! Don't use this component on new dev
const Card = (props: Props) => {
	const {
		children,
		title,
		action,
		divider,
		backgroundColor,
		boxShadow = true,
		menuItems,
		expansible,
		pinIcon,
		setDataPinnedElementClicked,
		isHeader = true,
	} = props;

	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [expanded, setExpanded] = useState(true);

	const open = Boolean(anchorEl);

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuItems = () => {
		setAnchorEl(null);
	};
	const handleExpandClick = () => setExpanded(!expanded);
	const handlePinClick = () => {
		const childrenPinData = props.children.props;
		setDataPinnedElementClicked(childrenPinData);
	};

	return (
		<MuiCard
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: "100%",
				backgroundColor: backgroundColor || "pageBackground.main",
				boxShadow: boxShadow ? `0 7px 26px ${alpha(theme.palette.primary.dark, 0.12)}` : "0",
				pt: 2,
				pb: 1,
				px: 3,
				borderRadius: 4,
			}}
		>
			{isHeader && (
				<CardHeader
					title={<Box sx={{ fontWeight: "light", fontSize: "26px" }}>{title}</Box>}
					sx={{ maxHeight: 50, m: 1 }}
					action={
						<>
							{pinIcon && (
								<IconButton aria-label="pin" onClick={handlePinClick}>
									<Icon prefix={pinIcon.prefix} iconName="thumbtack" size="xs" />
								</IconButton>
							)}

							{menuItems && (
								<>
									<IconButton aria-label="settings" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleSettingsClick}>
										<MoreVertIcon />
									</IconButton>
									<Menu
										id="settings-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleCloseMenuItems}
										MenuListProps={{
											"aria-labelledby": "button",
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
									>
										{menuItems.map((item, i) => {
											return (
												<MenuItem
													key={i}
													onClick={() => {
														setAnchorEl(null);
													}}
												>
													<ListItemIcon>
														<Icon prefix="far" iconName={item.icon as IconName} size="1x" color={theme.palette.secondary.main} />
													</ListItemIcon>
													<ListItemText>{item.label}</ListItemText>
												</MenuItem>
											);
										})}
									</Menu>
								</>
							)}
							{expansible && (
								<ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
									<ExpandMoreIcon />
								</ExpandMore>
							)}
						</>
					}
				/>
			)}
			<CardContent
				sx={{
					flex: "12 1 auto",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							{expanded && children}
						</Collapse>
					</Grid>
				</Grid>
			</CardContent>

			{action && (
				<CardActions
					sx={{
						mt: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: action.align && action.align === "right" ? "flex-end" : "flex-start",
					}}
				>
					{divider && (
						<Divider
							sx={{
								my: 1,
								width: "200%",
								marginLeft: "-50%",
								marginRight: "-50%",
							}}
						/>
					)}
					<ActionButton label={action.label} redirectUrl={action.link} icon={action.icon ? action.icon : "arrow-right"} color={action.color} />
				</CardActions>
			)}
		</MuiCard>
	);
};
export default Card;
