import type { PaginedResourceType } from "@infrastructure/model/@types/api/api-pagined-response.type";
import { type DtoMailMessage, Services, type TypeApiQueryString } from "@key4-front-library/core";

const getEmails = async (
	clientId: string,
	eventId: string,
	participantId: string,
	queryStrings?: TypeApiQueryString[],
): Promise<PaginedResourceType<DtoMailMessage[]>> => {
	const emails = await Services.Events.FacultiesService.getMailMessages(clientId, eventId, participantId, queryStrings);

	return Promise.resolve({
		pagination: emails.pagination,
		data: emails.data,
	});
};

const EmailController = {
	getEmails,
};

export default EmailController;
