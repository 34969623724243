import { type SyntheticEvent, useState } from "react";

import ClashesFilters from "@application/components/_common/Clashes/ClashesFilters";
import ClashesList from "@application/components/_common/Clashes/ClashesList";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { Stack } from "@mui/material";

const NotificationsClashes = () => {
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [isOnlyActive, setIsOnlyActive] = useState<boolean>(true);

	const handleTabChange = (_event: SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	const handleIsOnlyActiveChange = () => {
		setIsOnlyActive((prev) => !prev);
	};

	return (
		<AppBox>
			<Stack spacing={2}>
				<ClashesFilters tabIndex={tabIndex} isOnlyActive={isOnlyActive} onTabChange={handleTabChange} OnIsOnlyActiveChange={handleIsOnlyActiveChange} />
				<ClashesList tabIndex={tabIndex} isOnlyActive={isOnlyActive} />
			</Stack>
		</AppBox>
	);
};

export default NotificationsClashes;
