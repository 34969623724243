export enum EnumSessionFormKey {
	GENERAL = "general",
	ROOMSCHEDULE = "roomSchedule",
	WORKFLOWTAGS = "workflowTags",
}

export enum EnumSessionSectionKey {
	MAIN = "main",
	TAGS = "tags",
	WHEN = "when",
	WHERE = "where",
	WORKFLOW = "workflow",
}

export enum EnumSessionFieldKey {
	CODE = "code",
	DESCRIPTION = "description",
	DURATION = "duration",
	END_DATE = "endDate",
	END_HOUR = "endHour",
	EXPECTED_AUDIENCE = "expectedAudience",
	IS_EVERYWHERE = "isEverywhere",
	IS_FULL_DAY = "isFullDay",
	IS_PRIVATE = "isPrivate",
	IS_WHOLE_PROGRAMME = "isWholeProgramme",
	ORGANIZED_BY = "organizedBy",
	PRIMARY_TAG_ID = "primaryTagId",
	PUBLICATION_DATE = "publicationDate",
	ROOM_ID = "roomId",
	START_DATE = "startDate",
	START_HOUR = "startHour",
	STATUS = "status",
	TAG_IDS = "tagIds",
	TIMEZONE = "timeZone",
	TITLE = "title",
}
