import { t } from "i18next";
import { useEffect, useState } from "react";

import { activityToProposalCardInfo } from "@application/Mappers";
import {
	type ActivityMatchDto,
	type ActivityReplacementDto,
	BOX_SHADOW_ELEVATION_1,
	ESieveOperator,
	IconOld,
	Services,
	TextTooltip,
	type TypeApiResponsePagined,
	UserInformationCard,
	type UserInformationProps,
	queryFilters,
	useContextModule,
} from "@key4-front-library/core";
import { Button, Chip, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import { type MatchingOverviewCardInfo, activityReplacementDtoToMatchingOverviewCardInfo } from "./MatchingOverviewCard.mapper";

export interface MatchingOverviewCardProps {
	matchingId?: string;
	newProposal?: UserInformationProps;
	onSaveClick: () => void;
	isLoading?: boolean;
}

export const MatchingOverviewCard = (props: MatchingOverviewCardProps): React.ReactNode => {
	const { matchingId, newProposal, onSaveClick, isLoading } = props;
	const component = "old.common.matchingOverviewCard";
	const theme = useTheme();

	const { client, event } = useContextModule();
	const [matchingOverviewCardInfo, setMatchingOverviewCardInfo] = useState<MatchingOverviewCardInfo>();

	const { data: activities } = useQuery({
		queryKey: ["matching", "faculty", matchingId, client.id, event.id],
		queryFn: (): Promise<TypeApiResponsePagined<Array<ActivityReplacementDto>>> =>
			Services.Programme.Clients.ActivityService.getActivitiesList(client.id, event.id, queryFilters(`id${ESieveOperator.EQUALS + matchingId}`)),
		enabled: !!matchingId,
	});

	const { data: participantProposals } = useQuery({
		queryKey: ["client", client.id, "event", event.id, "participant", "matching", activities?.data[0]?.id],
		queryFn: (): Promise<Array<ActivityMatchDto>> =>
			Services.Registration.Clients.ParticipantsService.postMatching(client.id, event.id, activities?.data[0]?.id ? [activities.data[0].id] : []),
		enabled: !!activities?.data[0],
	});

	const { data: contactProposals } = useQuery({
		queryKey: ["client", client.id, "community", event.communityId, "contact", "matching", activities?.data[0]?.id],
		queryFn: (): Promise<Array<ActivityMatchDto>> =>
			Services.Registration.Clients.Contacts.postMatching(client.id, event.communityId ?? "", activities?.data[0]?.id ? [activities.data[0].id] : []),
		enabled: !!activities?.data[0],
	});
	useEffect(() => {
		const activity = activities?.data[0];
		if (activity) {
			setMatchingOverviewCardInfo(
				activityReplacementDtoToMatchingOverviewCardInfo(
					activities.data[0],
					activityToProposalCardInfo({
						activity,
						contactProposals,
						participantProposals,
					}).userInformation,
					`/${client.key}/${event.key}/faculties`,
				),
			);
		}
	}, [activities, client.key, contactProposals, event.key, participantProposals]);

	if (!matchingOverviewCardInfo) return undefined;

	return (
		<Paper sx={{ padding: 4, borderRadius: 4, boxShadow: BOX_SHADOW_ELEVATION_1 }} elevation={1}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
				<Typography variant="h6">{t(`${component}.title`)}</Typography>
				<Chip {...matchingOverviewCardInfo.chip} />
			</Stack>
			<Stack my={2} sx={{ height: "20px" }} justifyContent={"center"}>
				{matchingOverviewCardInfo.comment && (
					<Stack direction={"row"} alignItems={"center"} spacing={1}>
						<IconOld iconName="message-lines" isCenterIcon />
						<TextTooltip
							maxHeight="20px"
							line={1}
							typography={{
								color: theme.palette.text.secondary,
								children: matchingOverviewCardInfo.comment,
							}}
						/>
					</Stack>
				)}
			</Stack>
			<Grid container>
				<Grid item xs={4}>
					<Stack width={"100%"} direction="row" spacing={2} alignItems={"center"} px={1}>
						<Stack flexGrow={1}>
							<UserInformationCard label={t(`${component}.proposing.title`)} userInformation={matchingOverviewCardInfo.proposing} />
						</Stack>
						<Stack>
							<Typography fontSize={"32px"}>
								<IconOld color={theme.palette.primary.main} iconName="arrow-right" />
							</Typography>
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Stack width={"100%"} direction="row" spacing={2} alignItems={"center"} px={1}>
						<Stack flexGrow={1}>
							<UserInformationCard label={t(`${component}.proposal.title`)} userInformation={matchingOverviewCardInfo.proposal} />
						</Stack>
						<Stack>
							<Typography fontSize={"32px"}>
								<IconOld color={theme.palette.text.disabled} iconName="arrows-repeat" />
							</Typography>
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<UserInformationCard label={t(`${component}.newProposal.title`)} userInformation={newProposal} />
					<Stack pt={1} width={"100%"}>
						<Button variant="contained" onClick={onSaveClick} disabled={!newProposal || isLoading}>
							<Stack direction={"row"} spacing={1}>
								<Typography>
									<IconOld prefix="fas" iconName="save" />
								</Typography>
								<Typography>{t(`${component}.button.save`)}</Typography>
							</Stack>
						</Button>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
};
